import * as React from 'react';
import { useState } from 'react';
import { AvatarExtended } from './AvatarExtended';
import { Typography } from '@mui/material';
import { useRef } from 'react';
import { UsersAutocomplete } from './UsersAutocomplete';


const UsersAutocompleteLabel = ({ label = 'Select a User', onChange = () => { }, userId = 0, userName = '', type = "all-users", projectId = 0, serviceId = 0, readOnly = false, error = false, required = false, filter = { by: 'name', order: 'asc' } }) => {

    const [selectedUserName, setSelectedUserName] = useState(userName);
    const [editMode, setEditMode] = useState(false);

    const inputRef = useRef(null);

    const onChangeHandler = (newValue) => {
        if (newValue !== null) {
            setEditMode(false);
            onChange(newValue);
            setSelectedUserName(newValue?.name);
        }
    };

    const clickHandler = () => {
        if (!readOnly) {
            setEditMode(true);
            inputRef?.current?.focus();
        }
    };

    const blurHandler = () => {
        setEditMode(false);
    };

    return (
        <div className='w-full h-10'>
            {editMode ?
                (<UsersAutocomplete
                    label={label}
                    onChange={onChangeHandler}
                    size='small'
                    userId={userId}
                    error={error}
                    required={required}
                    type={type}
                    projectId={projectId}
                    serviceId={serviceId}
                    filter={filter}
                    onBlur={blurHandler}
                    sx={{ mt: 0, mb: 0, pt: 0, pb: 0, border: 'none' }}
                    autoFocus
                    inputRef={inputRef}
                />) : (
                    <div className={'flex items-center hover:bg-black hover:bg-opacity-10 rounded-md p-1'} onClick={clickHandler}>
                        <AvatarExtended alt={selectedUserName} sx={{ width: 32, height: 32, fontSize: '13px', fontWeight: 600 }} />
                        <div className={'ml-2'}>
                            <Typography variant='subtitle2'>{selectedUserName}</Typography>
                        </div>
                    </div>
                )}
        </div>
    );
};

export { UsersAutocompleteLabel };
