
import Avatar from '@mui/material/Avatar';

const colors = [
    '#512da8',
    '#3c8f8f',
    '#4054bf',
    '#6d4ccb',
    '#3d8565',
    '#783a5d',
    '#243838',
    '#263f73',
    '#b82896',
    '#cd1111'
];

const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
}

const configureAvatar = (alt, colorKey, src, sx) => {
    if (alt && !src) {
        const parts = alt.split(' ');
        let children = parts[0][0];

        if (parts[1]) {
            children += parts[1][0];
        }

        return {
            sx: {
                ...sx,
                bgcolor: stringToColor(colorKey || alt),
            },
            children,
        };
    }
    return { sx, src, alt };

}

const AvatarExtended = ({ alt = '', colorKey = '', src = '', sx = { fontSize: '17px', fontWeight: 700 } }) => {
    return (
        <Avatar {...configureAvatar(alt, colorKey, src, sx)} />
    );
};

export { AvatarExtended };
