import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FileTypeIcon } from '.';
import ClearIcon from '@mui/icons-material/Clear';
import { getExtensionFromFileName, toReadableFileSize } from '../../utils';

const AttachmentViewer = ({ attachments = [], readOnly = false, onRemove = () => { } }) => {

    const handleRemoveClick = (attachment) => {
        onRemove(attachment);
    };

    return (
        <div className='flex flex-wrap gap-3 justify-start'>
            {attachments.length === 0 && <div className='text-sm text-gray-400'>No attachments</div>}
            {attachments.map((at, i) => (
                <div key={at.name + "-" + i} className='flex items-center justify-between w-80 border border-solid border-black border-opacity-20 rounded-sm'>
                    <div className='flex items-center px-2 pt-2'>
                        <div className='mr-2'>
                            <FileTypeIcon width={48} type={getExtensionFromFileName(at.name)} />
                        </div>
                        <div className='group'>
                            <a href={at.url} target="_blank" rel="noopener noreferrer">
                                <div className='text-sm font-medium group-hover:underline max-w-[210px] whitespace-nowrap overflow-hidden text-ellipsis'>{at.name}</div>
                                <div className='text-gray-500 text-xs'>{toReadableFileSize(at.size)}</div>
                            </a>
                        </div>
                    </div>
                    {!readOnly && <div className='pr-2'>
                        <Tooltip title='Remove attachment' arrow>
                            <IconButton aria-label="remove" size="small" onClick={() => handleRemoveClick(at)}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                </div>
            ))}
        </div>
    );
};

export { AttachmentViewer };
