import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNotification } from "../../context";
import { TextField } from '@mui/material';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { createDesignation, statusUpdateDesignation, updateDesignation } from '../../connectors/bff-connector';
import ConfirmationDialog from "./ConfirmationDialog";


const ManageDesignationDialog = ({ isCreate = false, handleClose = () => { }, onSearch = () => { }, selectedRow }) => {

    const [name, setName] = useState(isCreate ? '' : selectedRow.name);
    const [isNameValid, setIsNameValid] = useState(!isCreate);
    const [isNameDirty, setIsNameDirty] = useState(false);

    const [description, setDescription] = useState(isCreate ? '' : selectedRow.description);
    const [isDescriptionValid, setIsDescriptionValid] = useState(!isCreate);
    const [isDescriptionDirty, setIsDescriptionDirty] = useState(false);

    const [saving, setSaving] = useState(false);
    const [getConfirmation, setGetConfirmation] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [deactivating, setDeactivating] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { showNotificationError, showNotificationSuccess } = useNotification();

    const handleSubmit = async () => {

        if (!isNameValid) {
            setErrorMessage("Please type the name.");
            setIsNameDirty(true);
            return;
        }
        if (!isDescriptionValid) {
            setErrorMessage("Please type the description.");
            setIsDescriptionDirty(true);
            return;
        }

        if (isCreate) {
            const newDesignation = {
                name: name.trim(),
                description: description.trim()
            };

            setErrorMessage('');
            setSaving(true);

            try {
                const res = await createDesignation(newDesignation);
                showNotificationSuccess("New designation created successfully!");
                handleClose({}, res);
                onSearch("");
            } catch (err) {
                showNotificationError("Failed to create the designation. Please try again.");
                setSaving(false);
            }
        } else {
            const update = {
                id: selectedRow.id,
                name: name.trim(),
                description: description.trim()
            }
            setErrorMessage('');
            setSaving(true);

            try {
                const res = await updateDesignation(update);
                showNotificationSuccess("Designation updated successfully!");
                handleClose({}, res);
                onSearch("");
            } catch (err) {
                showNotificationError("Failed to update the designation. Please try again.");
                setSaving(false);
            }
        }
    };

    const handleNameChange = event => {
        const val = event.target.value;

        if (val) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }
        setName(val);
    };

    const handleDescriptionChange = event => {
        const val = event.target.value;

        if (val) {
            setIsDescriptionValid(true);
        } else {
            setIsDescriptionValid(false);
        }
        setDescription(val);
    };

    const handleDeactivate = () => {
        setGetConfirmation(true)
    }

    const deactivate = () => {
        setGetConfirmation(false);
        setDeactivating(true);
        onDeactivate(selectedRow.id);
    }

    const handleActivate = () => {
        setGetConfirmation(false);
        setDeactivating(true);
        onActivate(selectedRow.id);
    }

    const closeConfirmation = () => {
        setGetConfirmation(false)
    }

    const onDeactivate = async (id) => {
        try {
            await statusUpdateDesignation({
                "id": id,
                "status": false
            });
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setDeactivating(false);
        onSearch("");
        handleClose();
    };

    const onActivate = async (id) => {
        try {
            await statusUpdateDesignation({
                "id": id,
                "status": true
            });
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setDeactivating(false);
        onSearch("");
        handleClose();
    };

    return (
        <div>
            {!getConfirmation && (<Dialog
                open
                onClose={handleClose}
                fullScreen={fullScreen}
                scroll='paper'
                fullWidth
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle>{isCreate ? 'Create' : 'Manage'} Designation</DialogTitle>
                <DialogContent dividers>
                    <div className='sm:px-8'>
                        <div className='relative text-center'>
                            <Typography variant='subtitle1' className='absolute text-warn-main w-full -top-3'>{errorMessage}</Typography>
                        </div>
                        <TextField
                            onChange={(e) => handleNameChange(e)}
                            onBlur={() => setIsNameDirty(true)}
                            error={isNameDirty && !isNameValid}
                            margin="normal"
                            required
                            fullWidth
                            label="Designation Name"
                            name="name"
                            value={name}
                        />
                        <TextField
                            onChange={(e) => handleDescriptionChange(e)}
                            onBlur={() => setIsDescriptionDirty(true)}
                            error={isDescriptionDirty && !isDescriptionValid}
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={4}
                            label="Description"
                            name="description"
                            value={description}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full p-2 justify-between px-10'>
                        <div>
                            {!isCreate && selectedRow.active === "Active" && <LoadingButton
                                onClick={handleDeactivate}
                                type="submit"
                                fullWidth
                                loading={deactivating}
                                size="medium"
                                color='error'
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 140 }}
                            >
                                Deactivate
                            </LoadingButton>}
                            {!isCreate && selectedRow.active === "Deactivated" && <LoadingButton
                                onClick={handleActivate}
                                type="submit"
                                fullWidth
                                loading={deactivating}
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 140 }}
                            >
                                Activate
                            </LoadingButton>}
                        </div>
                        <div>
                            <LoadingButton
                                onClick={handleClose}
                                type="submit"
                                fullWidth
                                size="medium"
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 100 }}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                onClick={handleSubmit}
                                type="submit"
                                fullWidth
                                loading={saving}
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 150 }}
                            >
                                {isCreate ? 'Add Designation' : 'Update'}
                            </LoadingButton>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>)}

            {getConfirmation && (
                <ConfirmationDialog
                    header={"Are you sure?"}
                    description={`You are going to deactivate ${selectedRow.name}`}
                    handleClose={closeConfirmation}
                    handleSubmit={deactivate}
                    cancelText={"Cancel"}
                    confirmationText={"Deactivate"}
                />)}
        </div>
    );
};

export { ManageDesignationDialog };
