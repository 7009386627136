import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AvatarExtended } from '../common';
import { getNotificationTime } from '../../utils';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CloseIcon from '@mui/icons-material/Close';

const filterNotificationDescription = (description = "") => {
    const dom = document.createElement('div');
    dom.innerHTML = description;
    return dom.textContent.trim();
};

const ServerNotificationItem = ({ notification = {}, onClose = () => { }, onClick = () => { }, onMarkAsRead = () => { }, showCloseButton = false }) => {

    const handleMarkAsRead = (e) => {
        e.preventDefault()
        e.stopPropagation();
        onMarkAsRead(notification);
    };

    const handleClose = (e) => {
        e.preventDefault()
        e.stopPropagation();
        onClose(notification);
    };

    return (
        <div className={`w-full py-4 flex justify-start cursor-pointer ${showCloseButton ? 'px-4' : 'px-6'}`} style={{ background: notification?.read ? 'unset' : '#669fff2b' }} onClick={() => onClick(notification)}>
            <div className='pr-3'>
                <AvatarExtended alt={notification?.meta?.originatorName} />
            </div>
            <div className='grow'>
                <div className='flex justify-between'>
                    <div className='mr-1 whitespace-normal'>
                        <span className='text-sm font-bold text-gray-800'>{notification.topic}</span>
                        <span className='px-2 text-xs font-semibold text-gray-500 whitespace-nowrap'>{getNotificationTime(notification.createdAt)}</span>
                        <div className='text-sm font-normal text-gray-600 py-1 overflow-hidden text-ellipsis' style={{ WebkitLineClamp: 3, display: '-webkit-box', WebkitBoxOrient: 'vertical' }}>{filterNotificationDescription(notification?.description)}</div>
                    </div>
                    <div className='min-w-[30px]'>
                        {(!notification.read && !showCloseButton) && (<Tooltip title='Mark as read' arrow className='group'>
                            <IconButton size="small" onClick={handleMarkAsRead}>
                                <MarkChatReadIcon className='group-hover:text-primary-main' fontSize="inherit" />
                            </IconButton>
                        </Tooltip>)}
                        {showCloseButton && (<Tooltip title='Close' arrow className='group'>
                            <IconButton size="small" onClick={handleClose}>
                                <CloseIcon className='group-hover:text-primary-main' fontSize="inherit" />
                            </IconButton>
                        </Tooltip>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ServerNotificationItem };
