import {
    Tooltip,
    Typography,
} from "@mui/material";
import { useAuth, useNotification } from "../context";
import { checkActiveTask, getTasks, pauseTask, startTask } from "../connectors/bff-connector";
import { AvatarExtended, MainLoader, ProgressIndicator, SearchBox, TaskStatus } from "../components/common";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, getDateStringDDMMYYYY, stableSort } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { ConfirmationDialog, CreateTaskDialog } from "../components/dialogs";
import { FormControl, InputLabel, Select } from '@mui/material';
import { UsersAutocomplete } from "../components/common";
import * as React from "react";
import { getClients, getProjects } from '../connectors/bff-connector';
import { useCallback, useEffect, useState } from 'react';
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import TASK_STATUS from "../constants/taskStatus";
import { commonService } from "../services";
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';


const headCells = [
    {
        id: 'task',
        numeric: false,
        label: 'Task',
    },
    {
        id: 'project',
        numeric: false,
        label: 'Project',
    },
    {
        id: 'client',
        numeric: false,
        label: 'Client',
    },
    {
        id: 'deadline',
        numeric: false,
        label: 'Deadline',
    },
    {
        id: 'assignee',
        numeric: false,
        label: 'Assignee',
    },
    {
        id: 'statusDes',
        numeric: false,
        label: 'Status',
    },
    {
        id: 'completion',
        numeric: true,
        label: 'Burnout',
    }
];

const cachedData = {};

const ViewTasks = () => {

    const navigate = useNavigate();
    const { user } = useAuth();

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(cachedData.tasks ? false : true);
    const [rows, setRows] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedAssigneeUserId, setSelectedAssigneeUserId] = useState(() => commonService.getSelectedUserIdInTaskPage());
    const [selectedOwnerUserId, setSelectedOwnerUserId] = useState('');
    const [clients, setClients] = useState([]);
    const [projects, setProjects] = useState([]);

    const [selectedClientId, setSelectedClientId] = useState('');
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [selectedTaskStatusId, setSelectedTaskStatusId] = useState('all');

    const [searchText, setSearchText] = useState("");
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [startingTaskId, setStartingTaskId] = useState(0);
    const [activeTasks, setActiveTasks] = useState(null);

    const handleAssigneeChange = (user) => {
        setSelectedAssigneeUserId(user?.id || 0);
        commonService.setSelectedUserIdInTaskPage(user?.id || 0);
    };

    const handleOwnerChange = (user) => {
        setSelectedOwnerUserId(user?.id || 0);
    };

    const handleClientChange = event => {
        setSelectedClientId(event.target.value);
    };

    const handleProjectChange = event => {
        setSelectedProjectId(event.target.value);
    };

    const handleTaskStatusChange = event => {
        setSelectedTaskStatusId(event.target.value);
    };

    const { showNotificationError } = useNotification();

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
    };

    const search = async (showLoader = true) => {
        setLoading(showLoader);
        try {
            const res = await getTasks({
                "status": selectedTaskStatusId === "all" ? null : selectedTaskStatusId,
                "clientId": selectedClientId === "all" ? null : selectedClientId,
                "projectId": selectedProjectId === "all" ? null : selectedProjectId,
                "ownerId": selectedOwnerUserId || null,
                "assigneeId": selectedAssigneeUserId === "0" ? null : (selectedAssigneeUserId || null),
                "sortKey": "id",
                "sortOrder": "ASC",
                "limit": 1000000,
                "offset": 0,
                "task": searchText
            });
            const tasks = res || [];
            setRows(tasks);
            cachedData.tasks = tasks;
            setStartingTaskId(0);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        cachedData.fromCache = false;
        setLoading(false);
    };

    const loadClients = useCallback(async () => {
        try {
            const res = await getClients({
                "name": "",
                "limit": 10000,
                "offset": 0,
                "active": true,
                "sortOrder": "ASC",
                "sortKey": "name"
            });

            const clients = res?.payload?.result;
            const c = (clients || []).map(client => ({
                id: client.id,
                name: client.name
            }));

            setClients(c);
            cachedData.clients = c;
            setSelectedClientId("all");
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadProjects = useCallback(async () => {
        try {
            const res = await getProjects({
                "limit": 10000,
                "offset": 0,
                "active": true,
                "sortOrder": "ASC",
                "sortKey": "name"
            });
            const projects = (res || []);
            setProjects(projects);
            cachedData.projects = projects;
            setSelectedProjectId("all");
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadInitialData = async () => {
        setLoading(true);
        await Promise.all([loadClients(), loadProjects()]);
        setInitialDataLoaded(true);
    };

    const confirmStartTask = async (taskId) => {
        setActiveTasks(null);
        try {
            await startTask({ taskId });
            search(false);
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to start the task! Please try again.");
            setStartingTaskId(0);
        }
    };

    const handleStart = async (taskId) => {
        setStartingTaskId(taskId);
        try {
            const activeTasks = await checkActiveTask();
            if (activeTasks && activeTasks.length > 0) {
                setActiveTasks(activeTasks);
                return;
            }
            await confirmStartTask(taskId);
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to start the task! Please try again.");
            setStartingTaskId(0);
        }
    };

    const handlePause = async (taskId) => {
        setStartingTaskId(taskId);
        try {
            await pauseTask({ taskId });
            search(false);
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to pause the task! Please try again.");
            setStartingTaskId(0);
        }
    };

    useEffect(() => {
        if (cachedData.tasks) {
            setClients(cachedData.clients);
            setProjects(cachedData.projects);
            setRows(cachedData.tasks);
            cachedData.fromCache = true;
            setSelectedProjectId("all");
            setSelectedClientId("all");
            setInitialDataLoaded(true);
            return;
        }
        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialDataLoaded) {
            search(!cachedData.fromCache);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDataLoaded, searchText, selectedClientId, selectedProjectId, selectedTaskStatusId, selectedAssigneeUserId, selectedOwnerUserId]);

    const onSearchKeyPress = () => {
        setLoading(true);
    };

    const handleCreateService = () => {
        setDialogOpen(true);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (row) => {
        navigate(`/tasks/${row.projectId}/${row.taskCode}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialogClose = (event, task) => {
        if (task) {
            search(false);
        }
        setDialogOpen(false);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div className="p-4 md:p-6 bg-white">
            <Typography variant="h2">
                Tasks
            </Typography>
            <div className='flex items-center justify-between my-6'>
                <div className='w-96'>
                    <SearchBox placeholder="Search Tasks" onSearch={handleSearchTextChange} onSearchKeyPress={onSearchKeyPress} />
                </div>
                <div className='w-48'>
                    <LoadingButton
                        onClick={handleCreateService}
                        type="submit"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        startIcon={<AddIcon />}
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                    >
                        Create Task
                    </LoadingButton>
                </div>
            </div>
            <div className='flex gap-3'>
                <div className='basis-1/5 max-w-[calc(20%-10px)]'>
                    <FormControl fullWidth margin='normal' size="small">
                        <InputLabel>Client</InputLabel>
                        <Select
                            labelId="select-client"
                            value={selectedClientId}
                            label="Client"
                            onChange={handleClientChange}
                        >
                            {clients.length === 0 && (
                                <MenuItem value="">
                                    <em>Loading...</em>
                                </MenuItem>
                            )}
                            <MenuItem key="all" value="all">All</MenuItem>
                            {clients.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className='basis-1/5 max-w-[calc(20%-10px)]'>
                    <FormControl fullWidth margin='normal' size="small">
                        <InputLabel>Project</InputLabel>
                        <Select
                            labelId="select-project"
                            value={selectedProjectId}
                            label="Project"
                            onChange={handleProjectChange}
                        >
                            {projects.length === 0 && (
                                <MenuItem value="">
                                    <em>Loading...</em>
                                </MenuItem>
                            )}
                            <MenuItem key="all" value="all">All</MenuItem>
                            {projects.map(d => <MenuItem key={d.projectId} value={d.projectId}>
                                <div className='max-w-lg text-ellipsis overflow-hidden whitespace-nowrap'>
                                    <span className="text-base font-semibold">{d.code}</span>
                                    <span className="text-sm font-normal"> - {d.name}</span>
                                </div>
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className='basis-1/5'>
                    <FormControl fullWidth margin='normal' size="small">
                        <InputLabel>Status</InputLabel>
                        <Select
                            labelId="select-status"
                            value={selectedTaskStatusId}
                            label="Status"
                            onChange={handleTaskStatusChange}
                        >
                            <MenuItem key="all" value="all">All</MenuItem>
                            {TASK_STATUS.map(ts => <MenuItem key={ts.status} value={ts.status}>{ts.caption}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className='basis-1/5'>
                    <UsersAutocomplete label="Owner" onChange={handleOwnerChange}
                        userId={selectedOwnerUserId} onlyActive />
                </div>
                <div className='basis-1/5'>
                    <UsersAutocomplete label="Assignee" onChange={handleAssigneeChange}
                        userId={selectedAssigneeUserId} onlyActive />
                </div>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                rows.length === 0 ? (
                    <div className='p-20 text-center text-gray-500'>
                        <Typography variant='subtitle1'>No results found!</Typography>
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const key = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={() => handleClick(row)}
                                                        role="checkbox"
                                                        aria-checked={false}
                                                        tabIndex={-1}
                                                        key={key}
                                                        selected={false}
                                                    >
                                                        <TableCell align="left" style={{ minWidth: 200 }}>
                                                            <div className="text-sm font-semibold">{row.taskCode}</div>
                                                            <div className="text-xs">{row.task}</div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div className="text-xs">{row.project}</div>
                                                        </TableCell>
                                                        <TableCell align="left">{row.client}</TableCell>
                                                        <TableCell align="left">{getDateStringDDMMYYYY(row.deadline)}</TableCell>
                                                        <TableCell align="left">
                                                            <div className={'flex items-center'}>
                                                                <AvatarExtended alt={row.assignee} sx={{ width: 32, height: 32, marginY: '4px', fontSize: '14px', fontWeight: 600 }} />
                                                                <div className={'ml-2'}>
                                                                    <Typography variant='subtitle2'>{row.assignee}</Typography>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div className="flex items-center gap-3">
                                                                <div>
                                                                    <TaskStatus readOnly status={row.status} />
                                                                </div>
                                                                <div className="flex items-center">
                                                                    {row.isActive && <div className="flex w-9 h-6 relative">
                                                                        <div className="absolute animate-ping bottom-0">
                                                                            <AccessAlarmIcon fontSize="medium" color="success" />
                                                                        </div>
                                                                        <div className="absolute bottom-0">
                                                                            <AccessAlarmIcon fontSize="medium" color="success" />
                                                                        </div>
                                                                    </div>}
                                                                    {row.asigneeId === user.userId && <div className='w-6'>
                                                                        {row.isActive ? (
                                                                            <Tooltip title="Pause task">
                                                                                <LoadingButton
                                                                                    onClick={(e) => { e.stopPropagation(); handlePause(row.id) }}
                                                                                    type="button"
                                                                                    fullWidth
                                                                                    size="medium"
                                                                                    color="error"
                                                                                    loading={startingTaskId === row.id}
                                                                                    loadingPosition="center"
                                                                                    startIcon={<AlarmOffIcon sx={{ position: 'relative', left: 6 }} />}
                                                                                    variant="outlined"
                                                                                    sx={{ textTransform: 'none', paddingX: 0, minWidth: 32 }}
                                                                                >
                                                                                </LoadingButton>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Start task">
                                                                                <LoadingButton
                                                                                    onClick={(e) => { e.stopPropagation(); handleStart(row.id) }}
                                                                                    type="button"
                                                                                    fullWidth
                                                                                    size="medium"
                                                                                    loading={startingTaskId === row.id}
                                                                                    loadingPosition="center"
                                                                                    startIcon={<AlarmOnIcon sx={{ position: 'relative', left: 6 }} />}
                                                                                    variant="contained"
                                                                                    sx={{ textTransform: 'none', paddingX: 0, minWidth: 32 }}
                                                                                >
                                                                                </LoadingButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <ProgressIndicator progress={row.completion} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )
            )}
            {dialogOpen && <CreateTaskDialog handleClose={handleDialogClose} />}
            {activeTasks && activeTasks.length > 0 && (
                <ConfirmationDialog
                    header={"You already have an active task!"}
                    description={<div>
                        <div>Following task is currently active. It will be stopped when you start this task.</div>
                        <div className="text-base py-2">
                            <span className="font-bold">{activeTasks[0].code} </span>
                            <span>{activeTasks[0].name}</span>
                        </div>
                    </div>}
                    handleClose={() => { setActiveTasks(null); setStartingTaskId(0) }}
                    handleSubmit={() => confirmStartTask(startingTaskId)}
                    cancelText={"Cancel"}
                    color={'success'}
                    confirmationText={'Start Task'}
                />)}
        </div>
    );
};

export { ViewTasks };
