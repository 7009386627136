import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { createAttachment, getPresignedUploadUrl, sendPutFileRequest } from "../../connectors/bff-connector";
import LinearProgress from "@mui/material/LinearProgress";
import { FileTypeIcon } from "./FileTypeIcon";
import { getExtensionFromFileName, toReadableFileSize } from "../../utils";
import { IconButton, Tooltip } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useNotification } from "../../context";

const UploadingFile = ({ file, onChange = () => { }, source, categoryCode }) => {

    const { showNotificationError } = useNotification();
    const extension = getExtensionFromFileName(file.file.name);

    const uploadFile = async () => {
        try {
            const getUrlPayload = {
                contentType: file.file.type,
                key: `${source}/${file.file.name}`,
            };
            const signedUrl = await getPresignedUploadUrl(getUrlPayload);
            await sendPutFileRequest(signedUrl, file.file);
            const res = await createAttachment({
                categoryCode,
                contentType: file.file.type,
                size: file.file.size,
                name: file.file.name,
                externalLink: getUrlPayload.key
            });
            file.id = res.id;
            file.extension = extension;
            file.status = true;
        } catch (err) {
            console.error(err);
            file.active = false;
            showNotificationError(`Failed to upload ${file.file.name}! Please try again.`);
        }
        file.uploading = false;
        onChange();
    };

    useEffect(() => {
        if (file.pending) {
            file.pending = false;
            uploadFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = () => {
        file.active = false;
        onChange();
    };

    return (
        <div className="w-full">
            <div key={file.file.name} className='flex items-center justify-between border border-solid border-black border-opacity-20 rounded-sm mt-4'>
                <div className='flex items-center px-2 pt-2 max-w-[90%]'>
                    <div className='mr-2'>
                        <FileTypeIcon width={48} type={extension} />
                    </div>
                    <div className="w-full">
                        <div className='text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-[80%]'>{file.file.name}</div>
                        <div className='text-gray-500 text-xs'>{toReadableFileSize(file.file.size)}</div>
                    </div>
                </div>
                <div className='pr-2'>
                    <Tooltip title='Remove attachment' arrow>
                        <IconButton aria-label="remove" size="small" onClick={handleRemove}>
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {file.uploading && <LinearProgress variant="indeterminate" color="primary" />}
            {file.status && <LinearProgress variant="determinate" value={100} color="success" />}
        </div>
    );
};

const AttachmentUploader = ({ height = 128, source = 'unclassified', categoryCode, onUploadStart = () => { }, onUploadComplete = () => { } }) => {

    const [files, setFiles] = useState([]);
    const [drag, setDrag] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles) {
            for (let i = 0; i < acceptedFiles.length; i++) {
                const file = {
                    file: acceptedFiles[i],
                    pending: true,
                    active: true,
                    uploading: true,
                    status: false
                };
                files.push(file);
            }
            if (acceptedFiles.length > 0) {
                onUploadStart();
            }
        }
    }, [files, onUploadStart]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleChange = () => {
        setFiles([...files]);
        const succeedFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.active) {
                if (file.uploading) {
                    return;
                }
                succeedFiles.push(file);
            }
        }
        onUploadComplete(succeedFiles);
    };

    const handleDragEnter = () => {
        setDrag(true);
    };

    const handleDragLeave = () => {
        setDrag(false);
    };

    return (
        <div>
            <div {...getRootProps()} onDragLeave={handleDragLeave} onDropCapture={handleDragLeave} onDragOver={handleDragEnter} style={{ height, backgroundColor: drag ? '#0080ff1c' : 'white' }} className="w-full rounded border-dashed border-2 border-gray-300 flex flex-col justify-center items-center">
                <input {...getInputProps()} />
                <svg
                    width="60"
                    height="48"
                    viewBox="0 0 60 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M29.25 15.8438C28.7812 15.375 28.125 15.375 27.6562 15.8438L18.4688 25.0312C18 25.5 18 26.25 18.4688 26.625L18.9375 27.1875C19.4062 27.6562 20.1562 27.6562 20.5312 27.1875L27 20.8125V34.875C27 35.5312 27.4688 36 28.125 36H28.875C29.4375 36 30 35.5312 30 34.875V20.8125L36.375 27.1875C36.8438 27.6562 37.5 27.6562 37.9688 27.1875L38.5312 26.625C38.9062 26.25 38.9062 25.5 38.5312 25.0312L29.25 15.8438ZM53.5312 22.4062C53.8125 21.4688 54 20.5312 54 19.5C54 13.7812 49.2188 9 43.5 9C41.9062 9 40.4062 9.375 39 10.0312C36 5.8125 31.0312 3 25.5 3C16.5938 3 9.375 10.0312 9 18.8438C3.65625 20.7188 0 25.7812 0 31.5C0 39 6 45 13.5 45H48C54.5625 45 60 39.6562 60 33C60 28.5938 57.5625 24.5625 53.5312 22.4062ZM48 42H13.5C7.6875 42 3 37.3125 3 31.5C3 26.25 6.9375 21.8438 12.0938 21.0938C12 20.625 12 20.0625 12 19.5C12 12.0938 18 6 25.5 6C31.125 6 35.9062 9.46875 37.9688 14.4375C39.375 12.9375 41.25 12 43.5 12C47.625 12 51 15.375 51 19.5C51 21.2812 50.3438 22.875 49.4062 24.1875C53.7188 24.8438 57 28.5938 57 33C57 37.9688 52.9688 42 48 42Z"
                        fill="#C4C4C4"
                    />
                </svg>
                <div className="py-2 text-base text-gray-500 font-semibold">
                    Drag your files here, or
                    <span className={"text-primary-main px-2 decoration-solid cursor-pointer hover:underline"}>
                        browse
                    </span>
                </div>
            </div>

            <div>
                {files.map((file, i) => file.active && <UploadingFile key={i} file={file} onChange={handleChange} source={source} categoryCode={categoryCode} />)}
            </div>
        </div>
    );
};

export { AttachmentUploader };
