import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNotification } from "../../context";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from '@mui/material';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
    getAllUsers, searchTaskTime, updateTaskTime} from '../../connectors/bff-connector';
import { useEffect } from 'react';
import { AvatarExtended, MainLoader } from '../common';
import { EnhancedTableHead, getComparator, stableSort } from "../../utils";
import dayjs from 'dayjs';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const headCells = [
    {
        id: 'index',
        numeric: true,
        label: '#',
    },
    {
        id: 'userName',
        numeric: false,
        label: 'Employee',
    },
    {
        id: 'startAt',
        numeric: false,
        label: 'Start At',
    },
    {
        id: 'endAt',
        numeric: false,
        label: 'End At',
    }
];

const ManageTaskTimeDialog = ({ task = {}, taskCode = '', allowModifyEntries = false, onClose = () => { } }) => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('startAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [readyToSave, setReadyToSave] = useState(false);
    const [rows, setRows] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { showNotificationError, showNotificationSuccess } = useNotification();

    const loadTaskTime = async () => {
        try {
            const users = await getAllUsers();
            const res = await searchTaskTime({ taskId: task.id, hasEnded: true });
            res.forEach((taskTime, i) => {
                const user = users.find(user => user.id === taskTime.user_id) || {};
                taskTime.index = i + 1;
                taskTime.userName = user.name;
                taskTime.startAt = dayjs(taskTime.start_at);
                taskTime.endAt = dayjs(taskTime.end_at);
                taskTime.updated = false;
            });
            setRows(res);
            setLoading(false);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
            onClose();
        }
    };

    useEffect(() => {
        loadTaskTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        const tasks = [];
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (!row.updated) {
                continue;
            }
            if (!row.startAt?.isValid()) {
                setErrorMessage(`Start time column has invalid date/time format in row # ${row.index}.`);
                return;
            }
            if (!row.endAt?.isValid()) {
                setErrorMessage(`End time column has invalid date/time format in row # ${row.index}.`);
                return;
            }
            if (row.startAt >= row.endAt) {
                setErrorMessage(`End time should be greater than start time in row # ${row.index}.`);
                return;
            }
            tasks.push({
                id: row.id,
                startAt: row.startAt.toISOString(),
                endAt: row.endAt.toISOString()
            });
        };
        try {
            setSaving(true);
            await updateTaskTime(tasks);
            showNotificationSuccess("Record(s) updated successfully!");
            onClose(true);
        } catch (err) {
            showNotificationError("Failed to update record(s). Please try again.");
            setSaving(false);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    return (
        <div>
            <Dialog
                open
                fullScreen={fullScreen}
                scroll='paper'
                fullWidth
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                PaperProps={{
                    sx: {
                        minWidth: 840,
                    },
                }}
            >
                <DialogTitle>Time Log Entries of {taskCode}</DialogTitle>
                <DialogContent dividers>
                    <div className='min-h-[40vh]'>
                        <div className='relative text-center'>
                            <Typography variant='subtitle1' className='absolute text-warn-main w-full -top-8'>{errorMessage}</Typography>
                        </div>
                        {loading ? (<div className='pt-[15vh]'>
                            <MainLoader />
                        </div>
                        ) : (
                            <div>
                                {rows.length === 0 ? (
                                    <div className='text-sm font-medium text-gray-500 text-center pt-[15vh]'>No time entries for this task yet!</div>
                                ) : (
                                    <Box sx={{ width: '100%', mt: 4 }}>
                                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                                            <TableContainer>
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    size='medium'
                                                >
                                                    <EnhancedTableHead
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={rows.length}
                                                        headCells={headCells}
                                                    />
                                                    <TableBody>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            {stableSort(rows, getComparator(order, orderBy))
                                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map((row, index) => {
                                                                    const key = `table-row-${index}`;

                                                                    return (
                                                                        <TableRow
                                                                            hover
                                                                            role="checkbox"
                                                                            aria-checked={false}
                                                                            tabIndex={-1}
                                                                            key={key}
                                                                            selected={false}
                                                                        >
                                                                            <TableCell align="center">
                                                                                <Typography variant='subtitle2'>{row.index}</Typography>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                component="th"
                                                                                id={key}
                                                                                scope="row"
                                                                            >
                                                                                <div className={'flex items-center'}>
                                                                                    <AvatarExtended alt={row.userName} sx={{ width: 32, height: 32, marginY: '4px', fontSize: '14px', fontWeight: 600 }} />
                                                                                    <div className={'ml-2'}>
                                                                                        <Typography variant='subtitle2'>{row.userName}</Typography>
                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                <DesktopDateTimePicker
                                                                                    value={row.startAt}
                                                                                    disabled={row.invoiced || !allowModifyEntries}
                                                                                    inputFormat="DD/MM/YYYY hh:mm A"
                                                                                    onChange={(newValue) => {
                                                                                        row.startAt = newValue;
                                                                                        row.updated = true;
                                                                                        setRows([...rows]);
                                                                                        setReadyToSave(true);
                                                                                    }}
                                                                                    renderInput={(params) => <TextField {...params} size="small" />}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                <DesktopDateTimePicker
                                                                                    value={row.endAt}
                                                                                    disabled={row.invoiced || !allowModifyEntries}
                                                                                    inputFormat="DD/MM/YYYY hh:mm A"
                                                                                    minDateTime={row.startAt}
                                                                                    onChange={(newValue) => {
                                                                                        row.endAt = newValue;
                                                                                        row.updated = true;
                                                                                        setRows([...rows]);
                                                                                        setReadyToSave(true);
                                                                                    }}
                                                                                    renderInput={(params) => <TextField {...params} size="small" />}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            {emptyRows > 0 && (
                                                                <TableRow
                                                                    style={{
                                                                        height: 53 * emptyRows,
                                                                    }}
                                                                >
                                                                    <TableCell colSpan={6} />
                                                                </TableRow>
                                                            )}
                                                        </LocalizationProvider>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>

                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 20]}
                                            component="div"
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Box>
                                )}
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full justify-end p-2'>
                        <div>
                            <LoadingButton
                                onClick={onClose}
                                fullWidth
                                size="medium"
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 100 }}
                            >
                                {allowModifyEntries ? 'Cancel' : 'Close'}
                            </LoadingButton>
                            {allowModifyEntries && <LoadingButton
                                onClick={handleSubmit}
                                disabled={!readyToSave}
                                fullWidth
                                loading={saving}
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 120 }}
                            >
                                Save
                            </LoadingButton>}
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export { ManageTaskTimeDialog };
