import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { ClickToCopy, DatePickerLabel, EditableNumberLabel, MainLoader, TabPanel } from "../components/common";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import 'react-quill/dist/quill.snow.css';
import { generateInvoice, getClients, getCurrentDetailedInvoice, getCurrentInvoice } from "../connectors/bff-connector";
import { useNotification } from "../context";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";


const DEFAULT_GST = 10;
const DEFAULT_MARGIN = 0;
const DEFAULT_DATE_OFFSET = 7;  // in days

const DetailPanel = ({ children, header = "", maxWidth = 320 }) => {
    return (
        <div style={{ maxWidth }}>
            <div className="bg-gray-900 text-sm text-white font-bold p-2 w-full">{header}</div>
            <div>{children}</div>
        </div>
    );
};

const Row = ({ item, isDetailed = false }) => {
    return (
        <div className={`flex text-gray-900 text-sm bg-white p-1 border-solid border-b border-b-black border-opacity-20 ${item.bold ? 'font-semibold' : 'font-medium'}`}>
            <div className="p-1 pr-3 w-28 text-right">{item.projectId}</div>
            <div className="p-1 grow text-left">{item.description}</div>
            <div className="p-1 w-14 text-right font-courier">{item.hours}</div>
            {isDetailed && <div className="p-1 w-16 text-right font-courier">{item.rate}</div>}
            <div className="p-1 w-28 text-right font-courier">{item.taskTotal}</div>
            <div className="p-1 w-28 text-right font-courier">{item.total}</div>
        </div>
    );
};

const ProjectView = ({ project, isDetailed = false }) => {

    const rows = [];

    rows.push({
        projectId: project.projectId,
        description: project.projectName,
        hours: project.hours,
        rate: "",
        taskTotal: "",
        total: project.charge,
        bold: true
    });

    (project.tasks || []).forEach(task => {
        rows.push({
            projectId: "",
            description: task.taskName,
            hours: task.hours,
            rate: "",
            taskTotal: task.charge,
            total: "",
            bold: false
        });
    });

    Object.entries(project.designations || {}).forEach(([designation, data]) => {
        rows.push({
            projectId: "",
            description: designation,
            hours: data.hours,
            rate: "",
            taskTotal: data.charge,
            total: "",
            bold: true
        });

        (data.list || []).forEach(item => {
            rows.push({
                projectId: "",
                description: `- ${item.userName}`,
                hours: item.hours,
                rate: item.rate,
                taskTotal: item.charge,
                total: "",
                bold: false
            });
        });
    });

    return (
        <div>
            {rows.map((item, i) => <Row key={`item-${project.projectId}-${i}`} item={item} isDetailed={isDetailed} />)}
        </div>
    );
};

const InvoiceTemplate = ({ invoice = {}, loading, isDetailed = false }) => {
    return (
        <div>
            {loading ? (<div className="pt-52"><MainLoader /></div>) : (
                <div className="py-4">
                    <div className="py-2 mb-4 flex justify-between">
                        <div className="basis-60">
                            <div className="h-28">
                                <img alt='dara' width="240" src='/org-logo.png' />
                            </div>
                            <DetailPanel header="Supplied by">
                                <div className="p-1 pb-2 font-medium text-sm">
                                    <div>{invoice.organization.name}</div>
                                    <div>{invoice.organization.address}</div>
                                    <div>{invoice.organization.email}</div>
                                    <div>{invoice.organization.contactNumber}</div>
                                </div>
                            </DetailPanel>
                        </div>
                        <div className="basis-60">
                            <div className="flex justify-between font-medium text-sm h-28">
                                <div>
                                    <div className="font-bold text-xl p-1">Invoice No</div>
                                    <div className="p-1">Date of Issue</div>
                                    <div className="p-1">Due Date</div>
                                </div>
                                <div>
                                    <div className="font-bold text-xl p-1">{invoice.invoiceNo}</div>
                                    <div className="p-1">{invoice.dateOfIssue}</div>
                                    <div className="p-1">{invoice.dueDate}</div>
                                </div>
                            </div>
                            <DetailPanel header="Invoiced to">
                                <div className="p-1 pb-2 font-medium text-sm">
                                    <div>{invoice.clientName}</div>
                                    <div>{invoice.clientAddress}</div>
                                    <div>{invoice.clientContactPerson}</div>
                                    <div>{invoice.clientContact}</div>
                                </div>
                            </DetailPanel>
                        </div>
                    </div>
                    <div className="text-base font-bold py-2">Below is the summary of Dara Lanka's invoice for the week/month</div>
                    <div className="flex bg-gray-900 text-base text-white font-bold p-1">
                        <div className="p-1 w-28">PROJECT #</div>
                        <div className="p-1 grow">DESCRIPTION</div>
                        <div className="p-1 w-14 text-center">HRS</div>
                        {isDetailed && <div className="p-1 w-16 text-center">RATE/H</div>}
                        <div className="p-1 w-28 text-center">{isDetailed ? 'CHARGE' : 'TASK TOTAL'}</div>
                        <div className="p-1 w-28 text-center">TOTAL</div>
                    </div>
                    {invoice.projects.map(project => <ProjectView key={project.projectId} project={project} isDetailed={isDetailed} />)}
                    <div className="flex text-gray-900 text-base font-semibold bg-white p-1 py-2 border-solid border-b border-b-black border-opacity-20">
                        <div className="p-1 grow text-right">Sub Total</div>
                        <div className="p-1 w-28 text-right font-courier">{invoice.subtotal}</div>
                    </div>
                    <div className="flex text-gray-900 text-base bg-white p-1 py-2 border-solid border-b border-b-black border-opacity-20">
                        <div className="p-1 grow text-right">{invoice.gstString}</div>
                        <div className="p-1 w-28 text-right font-courier">{invoice.gstValue}</div>
                    </div>
                    <div className="flex text-gray-900 text-lg font-bold bg-white p-1 py-2 border-solid border-b border-b-black border-opacity-20">
                        <div className="p-1 grow text-right">TOTAL</div>
                        <div className="p-1 w-28 text-right font-courier">{invoice.totalCharge}</div>
                    </div>
                    <div className="mt-20 mb-8">
                        <DetailPanel header="Other Comments or Special Instructions">
                            {invoice.banks.map(bank => (
                                <div key={bank.account_number} className="p-1 pb-2 font-medium text-sm">
                                    <div>Pay to : {bank.account_name}</div>
                                    <div>Bank Name : {bank.bank_name}</div>
                                    <div>BSB : {bank.bsb}</div>
                                    <div>Account No : {bank.account_number}</div>
                                    <div>Contact Person : {bank.contact_person}</div>
                                    <div>Email : {bank.email}</div>
                                </div>
                            ))}
                        </DetailPanel>
                    </div>
                    <div className="flex justify-around gap-10 font-medium text-base text-center">
                        <div>
                            <div>{invoice.autherizedBy}</div>
                            <div className="text-xs">- - - - - - - - - - - - - - - - - - - - - - </div>
                            <div>Authorized By</div>
                        </div>
                        <div>
                            <div>{invoice.dateOfIssue}</div>
                            <div className="text-xs">- - - - - - - - - - - - - - - - - - - - - - </div>
                            <div>Date</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


const ViewCreateInvoicePage = () => {
    const navigate = useNavigate();
    const { showNotificationError, showNotificationSuccess } = useNotification();

    const [loading, setLoading] = useState(true);
    const [partialLoading, setPartialLoading] = useState(false);
    const [generating, setGenerating] = useState(false);

    const [selectedClient, setSelectedClient] = useState(null);
    const [clients, setClients] = useState([]);

    const [gst, setGst] = useState(DEFAULT_GST);
    const [margin, setMargin] = useState(DEFAULT_MARGIN);
    const [dueDate, setDueDate] = useState(dayjs(new Date(new Date().setDate(new Date().getDate() + DEFAULT_DATE_OFFSET))));
    const [minDueDate] = useState(dayjs(new Date()));
    const [invoice, setInvoice] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);

    const goBack = () => {
        navigate(-1);
    };

    const loadClients = async () => {
        try {
            const res = await getClients({
                sortKey: "id",
                sortOrder: "ASC",
                limit: 1000000,
                offset: 0,
                name: "",
            });
            const clients = (res?.payload?.result || []).filter(c => c.active);
            setClients(clients);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setLoading(false);
    };

    const loadInvoiceData = async (isDetailed = false) => {
        setPartialLoading(true);
        try {
            const payload = {
                clientId: selectedClient.id,
                gst,
                margin,
                dueDate: dueDate.toISOString()
            };

            let res = null;

            if (isDetailed) {
                res = await getCurrentDetailedInvoice(payload);
            } else {
                res = await getCurrentInvoice(payload);
            }

            if (res && res.clientId) {
                setInvoice(res);
            } else {
                showNotificationError(`No data available for generating an invoice for ${selectedClient.name}`);
                setSelectedClient(null);
            }
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
            setSelectedClient(null);
            console.error(err);
        }
        setLoading(false);
        setPartialLoading(false);
    };

    useEffect(() => {
        loadClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedClient) {
            loadInvoiceData(tabIndex === 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClient, margin, gst, dueDate, tabIndex]);

    const handleSelectClient = (client) => {
        setLoading(true);
        setSelectedClient(client);
    };

    const handleDueDateChange = (dateString) => {
        setDueDate(dayjs(dateString));
    };

    const handleGSTChange = (newGst) => {
        setGst(newGst);
    };

    const handleMarginChange = (newMargin) => {
        setMargin(newMargin);
    };

    const handleGenerate = async () => {
        setGenerating(true);
        try {
            const res = await generateInvoice({
                clientId: selectedClient.id,
                gst,
                margin,
                dueDate: dueDate.toISOString(),
                submit: false
            });
            showNotificationSuccess("New invoice generated successfully!");
            navigate(`/invoices/view/${res.id}`);

        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
            console.error(err);
        }
        setGenerating(false);
    };

    const handleChangeTab = (event, newIndex) => {
        setPartialLoading(true);
        setTabIndex(newIndex);
    };

    return (
        <div>
            <div className="p-2 bg-white">
                <Box className="flex items-center">
                    <IconButton aria-label="back-button" onClick={goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 1 }}>
                        <Link to="/invoices">
                            <Typography variant="body1" className="hover:underline">Invoices</Typography>
                        </Link>
                        <Box className="flex items-center group">
                            <Link>
                                <Typography variant="subtitle1" className="group-hover:underline">Create Invoice</Typography>
                            </Link>
                            <div className="invisible ml-1 group-hover:visible">
                                <ClickToCopy caption="Copy link to create invoice page" value={window.location.href}>
                                    <IconButton aria-label="copy-content">
                                        <LinkIcon fontSize="small" />
                                    </IconButton>
                                </ClickToCopy>
                            </div>
                        </Box>
                    </Breadcrumbs>
                </Box>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (selectedClient ? (
                <div className="flex bg-white border-solid border-t border-t-black border-opacity-20 min-h-screen">
                    <div className="p-6 2xl:px-10 grow border-solid border-r border-r-black border-opacity-20">
                        <div className="pb-3">
                            <div className="text-gray-600 font-semibold text-lg">Invoice Preview</div>
                        </div>
                        <div className="w-full">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <div className="flex justify-between">
                                    <Tabs value={tabIndex} onChange={handleChangeTab}>
                                        <Tab label="Summarized" />
                                        <Tab label="Detailed" />
                                    </Tabs>
                                </div>
                            </Box>
                            <TabPanel value={tabIndex} index={0}>
                                <InvoiceTemplate invoice={invoice} loading={partialLoading} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <InvoiceTemplate invoice={invoice} loading={partialLoading} isDetailed />
                            </TabPanel>
                        </div>
                    </div>
                    <div className="min-w-[384px] max-w-sm 2xl:min-w-[480px] p-6 2xl:px-10 bg-[#F9FDFF]">
                        <div className="pb-3">
                            <div className="text-gray-600 font-semibold text-lg">Invoice Configurations</div>
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Due Date</Typography>
                            </div>
                            <DatePickerLabel label="Select deadline" onChange={handleDueDateChange} value={dueDate} minDate={minDueDate} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">GST</Typography>
                            </div>
                            <EditableNumberLabel onChange={handleGSTChange} value={gst} min={0} max={100} label="GST Percentage" suffix=" %" />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Margin</Typography>
                            </div>
                            <EditableNumberLabel onChange={handleMarginChange} value={margin} min={0} max={100} label="Margin Percentage" suffix=" %" />
                        </div>

                        <div className="py-10 flex justify-end">
                            <div>
                                <LoadingButton
                                    onClick={() => setSelectedClient(null)}
                                    type="submit"
                                    disabled={generating}
                                    fullWidth
                                    size="medium"
                                    variant="outlined"
                                    sx={{ textTransform: 'none', m: 1, width: 100 }}
                                >
                                    Cancel
                                </LoadingButton>
                                <LoadingButton
                                    onClick={handleGenerate}
                                    type="submit"
                                    fullWidth
                                    loading={generating}
                                    size="medium"
                                    variant="contained"
                                    sx={{ textTransform: 'none', m: 1, width: 120 }}
                                >
                                    Generate
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </div>) : (

                <div className="p-4 md:p-6 bg-gray-50 border-solid border-t border-t-black border-opacity-20 min-h-screen">
                    <Typography variant="h2">
                        Creating a New Invoice...
                    </Typography>
                    <div className="flex items-center flex-col">
                        <div className="p-2 font-semibold text-lg text-center text-gray-600">Please select a client.</div>
                        {clients.length === 0 && <div className="p-6 font-medium text-lg text-center text-gray-600">No active clients found in the system</div>}
                        <div className="max-w-xl">
                            {clients.map(c => (
                                <Card key={c.id} sx={{ margin: 1 }} onClick={() => handleSelectClient(c)}>
                                    <CardActionArea>
                                        <CardContent>
                                            <div className="flex justify-between items-center min-w-[480px]">
                                                <div>
                                                    <div className="text-base font-semibold py-1">{c.name}</div>
                                                    <div className="text-sm font-medium text-gray-600">{c.email}</div>
                                                    <div className="text-sm font-medium text-gray-500">{c.address}</div>
                                                </div>
                                                <div className="pl-4">
                                                    <ArrowForwardIcon />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>

            ))}
        </div>
    );
};

export { ViewCreateInvoicePage };
