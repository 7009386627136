import { Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotification } from "../context";
import { getResources } from "../connectors/bff-connector";
import { AvatarExtended, MainLoader, ProgressIndicator, SearchBox } from "../components/common";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, stableSort, getDateStringDDMMYYYY, getMinutesInHoursAndMins } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { FormControl } from '@mui/material';
import { UsersAutocomplete } from "../components/common";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const ViewResources = () => {
    const headCells = [
        {
            id: 'name',
            numeric: false,
            label: 'Name',
        },
        {
            id: 'numberOfTask',
            numeric: true,
            label: 'No. Tasks',
            align: 'left'
        },
        {
            id: 'remainingHours',
            numeric: true,
            label: 'Remaining',
            align: 'left'
        },
        {
            id: 'completion',
            numeric: true,
            label: ('Completion'),
            align: 'left'
        },
        {
            id: 'deadline',
            numeric: false,
            label: 'Deadlines',
            align: 'left'
        }
    ];

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedAssigneeUserId, setSelectedAssigneeUserId] = useState(null);

    const { showNotificationError } = useNotification();
    const navigate = useNavigate();

    const handleAssigneeChange = (user) => {
        setSelectedAssigneeUserId(user?.id || 0);
    };

    const onSearch = async (searchText) => {
        try {
            const res = await getResources({
                "sortKey": "id",
                "sortOrder": "ASC",
                "limit": 1000000,
                "offset": 0,
                "name": searchText,
                "assigneeUserId": selectedAssigneeUserId,
            });
            const resources = res || [];
            setRows(resources);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setLoading(false);
    };
    useEffect(() => {
        onSearch("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssigneeUserId]);

    const onSearchKeyPress = () => {
        setLoading(true);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (row) => {
        navigate(`/resources/${row.id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    return (
        <div className="p-4 md:p-6 bg-white">
            <Typography variant="h2">
                Resources
            </Typography>
            <div className='flex items-center justify-between my-6'>
                <div className='w-96'>
                    <SearchBox placeholder="Search Resource" onSearch={onSearch} onSearchKeyPress={onSearchKeyPress} />
                </div>
                <div className='w-60 '>
                    <FormControl fullWidth margin='normal'>
                        <UsersAutocomplete label="Assignee" onChange={handleAssigneeChange}
                            userId={selectedAssigneeUserId} />
                    </FormControl>
                </div>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                rows.length === 0 ? (
                    <div className='p-20 text-center text-gray-500'>
                        <Typography variant='subtitle1'>No results found!</Typography>
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const key = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={() => handleClick(row)}
                                                        role="checkbox"
                                                        aria-checked={false}
                                                        tabIndex={-1}
                                                        key={key}
                                                        selected={false}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={key}
                                                            scope="row"
                                                        >
                                                            <div className={'flex items-center'}>
                                                                <AvatarExtended alt={row.name} />
                                                                <div className={'ml-2'}>
                                                                    {row.name}
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">{row.numberOfTask}</TableCell>
                                                        <TableCell align="left">{getMinutesInHoursAndMins(row.remainingHours).readableValue}</TableCell>
                                                        <TableCell align="left">
                                                            <ProgressIndicator progress={row.completion} caption={`${getMinutesInHoursAndMins(row.completedHours).readableValue} / ${getMinutesInHoursAndMins(row.totalHours).readableValue}`} />
                                                        </TableCell>
                                                        <TableCell align="left">{getDateStringDDMMYYYY(row.deadline)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )
            )}
        </div>
    );
};

export { ViewResources };
