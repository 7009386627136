
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TASK_PRIORITIES = [
    {
        priority: 'crt',
        caption: 'Critical',
        color: 'status-red',
        icon: <KeyboardDoubleArrowUpIcon />
    },
    {
        priority: 'hg',
        caption: 'High',
        color: 'status-yellow',
        icon: <KeyboardArrowUpIcon />
    },
    {
        priority: 'md',
        caption: 'Medium',
        color: 'status-blue',
        icon: <DragHandleIcon />
    },
    {
        priority: 'lw',
        caption: 'Low',
        color: 'status-green',
        icon: <KeyboardArrowDownIcon />
    }
];

export default TASK_PRIORITIES;