import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { AttachmentUploader } from '../common';

const AttachmentUploaderDialog = ({ header = 'Add Attachments', onSubmit = () => { }, onClose = () => { }, source, categoryCode }) => {

    const [uploading, setUploading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const handleClose = () => {
        if (uploading || saving) {
            return;
        }
        onClose();
    };

    const handleUploadComplete = (attachments) => {
        setAttachments(attachments);
        setUploading(false);
    };

    const handleUploadStart = () => {
        setUploading(true);
    };

    const handleSubmit = async () => {
        setSaving(true);
        const res = await onSubmit(attachments);
        if (res) {
            onClose();
        } else {
            setSaving(false);
        }
    };

    return (
        <div>
            <Dialog
                open
                scroll='paper'
                fullWidth
            >
                <DialogTitle>
                    {header}
                </DialogTitle>
                <DialogContent dividers>
                    <div className='min-h-[360px]'>
                        <AttachmentUploader height={200} onUploadStart={handleUploadStart} onUploadComplete={handleUploadComplete} source={source} categoryCode={categoryCode} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={handleClose}
                        type="submit"
                        fullWidth
                        size="medium"
                        disabled={saving || uploading}
                        variant="outlined"
                        sx={{ textTransform: 'none', m: 1, width: 100 }}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleSubmit}
                        fullWidth
                        disabled={uploading || attachments.length === 0}
                        loading={saving}
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: 'none', m: 1, width: 120 }}
                    >
                        Attach
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export { AttachmentUploaderDialog };