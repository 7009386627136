import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EastIcon from '@mui/icons-material/East';
import TASK_STATUS from "../../constants/taskStatus";

const getStatusColor = (status) => {
    // DO NOT remove below colors - it's used in compile time
    // eslint-disable-next-line no-unused-vars
    const colors = "bg-status-green text-status-green bg-status-red text-status-red bg-status-yellow text-status-yellow bg-black text-black bg-status-blue text-status-blue bg-status-ash text-status-ash";
    return TASK_STATUS.find(s => s.status === status)?.color || 'status-ash';
};

const getBgColor = (status) => {
    return `bg-${getStatusColor(status)}`;
};

const getTextColor = (status) => {
    return `text-${getStatusColor(status)}`;
};

const getStatusCaption = (status) => {
    return TASK_STATUS.find(s => s.status === status)?.caption || 'Unknown';
};

const TaskStatus = ({ status = '', readOnly = false, onChange = () => { } }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if (readOnly) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (newStatus) => {
        setAnchorEl(null);
        if (newStatus) {
            onChange(newStatus);
        }
    };

    const editableClasses = "hover:bg-opacity-100 hover:text-white cursor-pointer justify-between w-32";
    return (
        <div>
            <div onClick={handleClick} className={`border-solid text-sm ${getBgColor(status)} ${getTextColor(status)} bg-opacity-20 h-7 rounded-lg py-1 font-bold flex ${readOnly ? 'justify-center w-24' : editableClasses} items-center`}>
                <div className={readOnly ? `ml-0` : 'ml-3'}>
                    {getStatusCaption(status)}
                </div>
                {!readOnly && <div className='mr-2'>
                    <KeyboardArrowDownIcon />
                </div>}
            </div>
            {!readOnly && <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
            >
                {TASK_STATUS.map(s => {
                    if (s.status === status) {
                        return '';
                    }
                    return (
                        <MenuItem key={s.status} onClick={() => handleClose(s.status)} className="group">
                            <div className='flex items-center justify-between py-1'>
                                <div className='text-base font-medium text-gray-600 group-hover:text-black'>Transition to</div>
                                <div className='mx-4'><EastIcon /></div>
                                <div className={`border-solid text-sm ${getBgColor(s.status)} ${getTextColor(s.status)} bg-opacity-20 w-24 h-7 rounded-lg py-1 font-semibold flex justify-center items-center group-hover:bg-opacity-100 group-hover:text-white`}>
                                    <div>
                                        {getStatusCaption(s.status)}
                                    </div>
                                </div>
                            </div>
                        </MenuItem>
                    );
                })}
            </Menu>}
        </div>
    );
};

export { TaskStatus };
