const SESSION_STORAGE_KEY_LOCALE = 'locale';
const SELECTED_USER_ID_IN_TASK_PAGE = 'selectedUserIdInTaskPage';

const localStorageData = {
  locale: null,
  selectedUserIdInTaskPage: null
};

const setLocale = (locale) => {
  window.localStorage.setItem(SESSION_STORAGE_KEY_LOCALE, locale);
  localStorageData.locale = locale;
};

const getLocale = () => {
  if (localStorageData.locale) {
    return localStorageData.locale;
  }
  localStorageData.locale = window.localStorage.getItem(SESSION_STORAGE_KEY_LOCALE) || 'en-us';
  return localStorageData.locale || 'en-us';
};

const setSelectedUserIdInTaskPage = (userId) => {
  window.localStorage.setItem(SELECTED_USER_ID_IN_TASK_PAGE, userId);
  localStorageData.selectedUserIdInTaskPage = userId;
};

const getSelectedUserIdInTaskPage = () => {
  if (localStorageData.selectedUserIdInTaskPage) {
    return localStorageData.selectedUserIdInTaskPage;
  }
  localStorageData.selectedUserIdInTaskPage = window.localStorage.getItem(SELECTED_USER_ID_IN_TASK_PAGE) || "0";
  return Number(localStorageData.selectedUserIdInTaskPage);
};

export {
  setLocale,
  getLocale,
  setSelectedUserIdInTaskPage,
  getSelectedUserIdInTaskPage
};
