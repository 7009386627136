import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useEffect } from 'react';

const DEBOUNCE_TIMEOUT = 1000;

const SearchBox = ({ placeholder = 'Search', onSearch = () => { }, onSearchKeyPress = () => { } }) => {

    const [value, setValue] = useState('');
    const handleChange = (event) => {
        const val = event.target.value;
        setValue(val);
        onSearchKeyPress(val);
    };

    useEffect(() => {
        const timer = setTimeout(() => onSearch(value), DEBOUNCE_TIMEOUT);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [value]);

    return (
        <Paper variant='outlined'
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                onChange={(e) => handleChange(e)}
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={{ 'aria-label': placeholder }}
            />
        </Paper>
    );
};

export { SearchBox };
