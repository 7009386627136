import { sendPrivateRequest, sendPublicRequest, sendPutFileRequest } from "../adaptors/bff-adaptor";

/**************** Public  ****************/

const test = (payload) => {
  return sendPublicRequest("test", payload);
};

/**************** Private  ****************/

const signIn = (payload) => {
  return sendPrivateRequest("signIn", payload);
};

const signOut = (payload) => {
  return sendPrivateRequest("signOut", payload);
};


const getUsers = (...params) => {
  return sendPrivateRequest("getUsers", ...params);
};

const getPresignedUploadUrl = (payload) => {
  return sendPrivateRequest("getPresignedUrl", payload);
};

const getServices = (payload) => {
  return sendPrivateRequest("searchService", payload);
};

const addUser = (payload) => {
  return sendPrivateRequest("addUser", payload);
};

const updateUser = (payload) => {
  return sendPrivateRequest("updateUser", payload);
};

const searchDesignation = (payload) => {
  return sendPrivateRequest("searchDesignation", payload);
};

const searchTeam = (payload) => {
  return sendPrivateRequest("searchTeam", payload);
};

const addService = (payload) => {
  return sendPrivateRequest("createService", payload);
};

const updateService = (payload) => {
  return sendPrivateRequest("updateService", payload);
};

const deactivateService = (payload) => {
  return sendPrivateRequest("deactivateService", payload);
};

const activateService = (payload) => {
  return sendPrivateRequest("activateService", payload);
};

const statusUpdateDesignation = (payload) => {
  return sendPrivateRequest("statusUpdateDesignation", payload);
};

const getDesignations = (payload) => {
  return sendPrivateRequest("searchDesignation", payload);
};

const createDesignation = (payload) => {
  return sendPrivateRequest("createDesignation", payload);
};

const updateDesignation = (payload) => {
  return sendPrivateRequest("updateDesignation", payload);
};

const createTeam = (payload) => {
  return sendPrivateRequest("createTeam", payload);
};

const updateTeam = (payload) => {
  return sendPrivateRequest("updateTeam", payload);
};

const getTeams = (payload) => {
  return sendPrivateRequest("searchTeam", payload);
};

const updateStatusTeam = (payload) => {
  return sendPrivateRequest("updateStatusTeam", payload);
};

const createClient = (payload) => {
  return sendPrivateRequest("createClient", payload);
};

const getNotifications = (payload) => {
  return sendPrivateRequest("searchNotification", payload);
}

const getClients = (payload) => {
  return sendPrivateRequest("getClient", payload);
}

const getClientBasic = (payload) => {
  return sendPrivateRequest("getClientBasic", payload);
}

const getClientServices = (payload) => {
  return sendPrivateRequest("getClientServices", payload);
}

const createProject = (payload) => {
  return sendPrivateRequest("createProject", payload);
}

const getProjects = (payload) => {
  return sendPrivateRequest("searchProject", payload);
}

const getResources = (payload) => {
  return sendPrivateRequest("getResources", payload);
}

const getProject = (payload) => {
  return sendPrivateRequest("getProject", payload);
};

const updateProject = (payload) => {
  return sendPrivateRequest("updateProject", payload);
};

const getTasks = (payload) => {
  return sendPrivateRequest("searchTask", payload);
};

const getTask = (payload) => {
  return sendPrivateRequest("getTask", payload);
};

const updateTask = (payload) => {
  return sendPrivateRequest("updateTask", payload);
};

const startTask = (payload) => {
  return sendPrivateRequest("startTask", payload);
};

const pauseTask = (payload) => {
  return sendPrivateRequest("pauseTask", payload);
};

const updateNotifications = (payload) => {
  return sendPrivateRequest("updateListOfNotifications", payload);
}

const createTask = (payload) => {
  return sendPrivateRequest("createTask", payload);
}

const createAttachment = (payload) => {
  return sendPrivateRequest("createAttachment", payload);
}

const deleteAttachment = (payload) => {
  return sendPrivateRequest("deleteAttachment", payload);
}

const createComment = (payload) => {
  return sendPrivateRequest("createComment", payload);
};

const editComment = (payload) => {
  return sendPrivateRequest("editComment", payload);
};

const deleteComment = (payload) => {
  return sendPrivateRequest("deleteComment", payload);
};

const getUserTasks = (payload) => {
  return sendPrivateRequest("getUserTasks", payload);
};

const getUserById = (payload) => {
  return sendPrivateRequest("getUserById", payload);
};

const getTaskServices = (payload) => {
  return sendPrivateRequest("getTaskServices", payload);
};

const getTaskUsers = (payload) => {
  return sendPrivateRequest("getTaskUsers", payload);
};

const getInvoices = (payload) => {
  return sendPrivateRequest("searchInvoices", payload);
};

const getCurrentInvoice = (payload) => {
  return sendPrivateRequest("viewCurrentInvoice", payload);
};

const getCurrentDetailedInvoice = (payload) => {
  return sendPrivateRequest("viewCurrentDetailedInvoice", payload);
};

const generateInvoice = (payload) => {
  return sendPrivateRequest("generateInvoice", payload);
};

const getInvoice = (payload) => {
  return sendPrivateRequest("getInvoice", payload);
};

const deleteInvoice = (payload) => {
  return sendPrivateRequest("deleteInvoice", payload);
};

const submitInvoice = (payload) => {
  return sendPrivateRequest("submitInvoice", payload);
};

const updateClient = (payload) => {
  return sendPrivateRequest("updateClient", payload);
};

const getDefaultTasks = (payload) => {
  return sendPrivateRequest("getDefaultTasks", payload);
};

const updateInvoice = (payload) => {
  return sendPrivateRequest("updateInvoice", payload);
};

const searchTaskTime = (payload) => {
  return sendPrivateRequest("searchTaskTime", payload);
};

const getSummary = (payload) => {
  return sendPrivateRequest("getSummary", payload);
};

const getUserPerformance = (payload) => {
  return sendPrivateRequest("getUserPerformance", payload);
};

const updateTaskTime = (payload) => {
  return sendPrivateRequest("updateTaskTime", payload);
};

const getUserLoad = (payload) => {
  return sendPrivateRequest("getUserLoad", payload);
};

const getAverageWorkingHors = (payload) => {
  return sendPrivateRequest("getAverageWorkingHors", payload);
};


const getUserNonBillable = (payload) => {
  return sendPrivateRequest("getUserNonBillable", payload);
};


const getUserTotal = (payload) => {
  return sendPrivateRequest("getUserTotal", payload);
};

const checkActiveTask = (payload) => {
  return sendPrivateRequest("checkActiveTask", payload);
};

const getAllUsers = async () => {
  const res = await getUsers({
    "filter": { by: 'name', order: 'asc' },
    "size": 1000000,
    "page": 1,
    "search": ""
  }, 'all-users');
  return res.users || [];
};


export {
  test,
  signIn,
  signOut,
  getUsers,
  getPresignedUploadUrl,
  addUser,
  updateUser,
  searchDesignation,
  searchTeam,
  getServices,
  addService,
  updateService,
  deactivateService,
  activateService,
  getDesignations,
  statusUpdateDesignation,
  createDesignation,
  updateDesignation,
  createTeam,
  getTeams,
  updateTeam,
  updateStatusTeam,
  createClient,
  getNotifications,
  getClients,
  getClientBasic,
  getClientServices,
  createProject,
  getProject,
  getProjects,
  updateProject,
  getResources,
  getTasks,
  getTask,
  updateTask,
  startTask,
  pauseTask,
  updateNotifications,
  createTask,
  createAttachment,
  deleteAttachment,
  sendPutFileRequest,
  createComment,
  editComment,
  deleteComment,
  getUserTasks,
  getUserById,
  getTaskServices,
  getTaskUsers,
  getInvoices,
  getCurrentInvoice,
  getCurrentDetailedInvoice,
  generateInvoice,
  getInvoice,
  deleteInvoice,
  submitInvoice,
  updateClient,
  getDefaultTasks,
  updateInvoice,
  searchTaskTime,
  checkActiveTask,
  getAllUsers,
  updateTaskTime,
  getSummary,
  getUserPerformance,
  getUserLoad,
  getAverageWorkingHors,
  getUserNonBillable,
  getUserTotal
};

