import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog({ header, description, handleSubmit = () => { }, handleClose = () => { }, cancelText, confirmationText, color = "error" }) {
    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {header}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        variant="outlined"
                        sx={{ textTransform: 'none', m: 1, width: 140 }}
                    >
                        {cancelText}</Button>
                    <Button onClick={handleSubmit}
                        color={color}
                        variant="contained"
                        sx={{ textTransform: 'none', m: 1, width: 140 }}
                    >
                        {confirmationText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export { ConfirmationDialog };