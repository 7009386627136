import { useRef, useState } from "react";
import { getDateStringDDMMYYYY } from "../../utils";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect } from "react";

const DatePickerLabel = ({ value = '', label = 'Select date', className = '', minDate = '', maxDate = '', readOnly = false, required = false, onChange = () => { } }) => {

    const [editMode, setEditMode] = useState(false);
    const [currentValue, setCurrentValue] = useState(value ? dayjs(value).toISOString() : new Date().toISOString());
    const [hasError, setHasError] = useState(false);
    const [selectedDate, setSelectedDate] = useState(value ? dayjs(value) : dayjs((new Date().toString())));
    const [open, setOpen] = useState(false);
    const [toggleValue, setToggleValue] = useState(false);

    const inputRef = useRef(null);

    const handleChange = (value) => {
        setSelectedDate(value);
        if (value?.isValid()) {
            setHasError(false);
        }
    };

    useEffect(() => {
        if (open) {
            return;
        }

        if (selectedDate?.isValid()) {
            const newDateString = selectedDate.toISOString();
            if (newDateString !== currentValue) {
                onChange(newDateString);
            }
            setCurrentValue(newDateString);
            setEditMode(false);
        } else {
            setHasError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleValue]);

    const toggle = () => {
        setToggleValue(toggle => !toggle);
    };

    const handleClick = () => {
        if (!readOnly) {
            setEditMode(true);
            setHasError(false);
        }
    };

    const handleBlur = () => {
        setTimeout(toggle, 200);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => inputRef?.current?.focus(), 200);
    };

    return (
        <div className='relative w-full'>
            <div className={(!readOnly && !editMode ? 'hover:bg-black hover:bg-opacity-5' : '') + ' rounded-md relative -left-2'}>
                {!editMode && <div className={`${className} p-2 text-base font-medium`} onClick={handleClick}>{getDateStringDDMMYYYY(currentValue)}</div>}
                {editMode && <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        onClose={handleClose}
                        onOpen={handleOpen}
                        minDate={minDate ? dayjs(minDate) : undefined}
                        maxDate={maxDate ? dayjs(maxDate) : undefined}
                        label={label}
                        inputRef={inputRef}
                        inputFormat="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={handleChange}
                        renderInput={(params) => <TextField size="small" {...params} fullWidth error={hasError} autoFocus onBlur={handleBlur} />}
                    />
                </LocalizationProvider>}
            </div>
        </div>
    );
};

export { DatePickerLabel };