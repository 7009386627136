import { API } from 'aws-amplify';

const subscription = `subscription MySubscription($userId: Int){
  postedNotification(userId: $userId) {
    userId
    notifications {
      description
      createdAt
      modifiedAt
      id
      resourceId
      resourceTypeCode
      topic
      typeCode
      userId
      callToAction
      meta {
        originatorId  
        originatorName
      }
    }
  }
}`;

const subscribeToUserNotification = (userId, callback) => {
  API.graphql({
    query: subscription,
    variables: {
      userId: userId,
    }
  }).subscribe({
    next: (data) => {
      if (!callback) {
        return;
      }
      if (data?.value?.data?.postedNotification?.notifications) {
        callback(data.value.data.postedNotification?.notifications);
      } else {
        callback(null);
      }
    }
  });
};

export { subscribeToUserNotification };
