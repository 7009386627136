import { authService, commonService } from '../services';

import { LOGGER } from '../utils';
import { v4 as uuidv4 } from 'uuid';

const BFF_ENDPOINT = process.env.REACT_APP_BFF_HOST;
const API_KEY = process.env.REACT_APP_BFF_API_KEY;

const CORRELATION_ID = 'correlation-id';
const SESSION_ID = 'session-id';

const cachedData = {};

const getPrivateRequestTrackingHeaders = async () => {
  const headers = getPublicRequestTrackingHeaders();
  headers[SESSION_ID] = await authService.getSessionId();

  return headers;
};

const getPublicRequestTrackingHeaders = () => {
  const headers = {
    locale: commonService.getLocale()
  };
  headers[CORRELATION_ID] = uuidv4();
  return headers;
};

const handleSessionTimeout = () => {
  LOGGER.warn("SESSION TIMEOUT - NOT HANDLED");
};

const sendPublicRequest = async (path = '', payload = {}) => {
  const requestTrackingHeaders = getPublicRequestTrackingHeaders();
  try {
    const res = await fetch(BFF_ENDPOINT + '/public?' + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        path: path,
        'x-api-key': API_KEY,
        ...requestTrackingHeaders
      },
      body: JSON.stringify(payload),
    }).then((r) => r.json());
    if (res.status === 'error') {
      throw new Error(res.error);
    }
    return res.data;
  } catch (err) {
    LOGGER.warn('Error occurred getting response', {
      path,
      payload,
      private: false,
      error: err.message,
      ...requestTrackingHeaders,
    });
    throw new Error(err);
  }
};

const sendPrivateRequest = async (path = '', payload = {}, cacheKey, clearCache) => {

  if (cacheKey) {
    const cache = cachedData[`path-${cacheKey}`];

    if (clearCache) {
      cachedData[`path-${cacheKey}`] = undefined;
      return true;
    }
    if (cache && cache.payload === JSON.stringify(payload)) {
      return cache.data;
    }
  }

  let requestTrackingHeaders;
  let statusCode;
  try {
    requestTrackingHeaders = await getPrivateRequestTrackingHeaders();
  } catch (err) {
    window.location.reload();
  }
  try {
    const response = await fetch(BFF_ENDPOINT + '/private?' + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        path: path,
        Authorization: await authService.getAccessToken(),
        ...requestTrackingHeaders,
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    statusCode = response.status;
    // explicit check for session expiration
    if (response.status === 403) {
      LOGGER.warn('Error occurred getting response', {
        path,
        payload,
        private: true,
        status: response.status,
        ...requestTrackingHeaders,
      });
      handleSessionTimeout();
    }
    if (res.status === 'error') {
      throw new Error(res.error);
    }

    if (cacheKey) {
      cachedData[`path-${cacheKey}`] = {
        data: res.data,
        payload: JSON.stringify(payload)
      }
    }

    return res.data;
  } catch (err) {
    LOGGER.warn('Error occurred getting response', {
      path,
      payload,
      status: statusCode,
      private: true,
      error: err.message,
      ...requestTrackingHeaders,
    });
    throw new Error(err);
  }
};

const sendPutFileRequest = async (url, file) => {
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
    },
    body: file
  });
  if (res.status === 'error') {
    throw new Error(res.error);
  }
  return res;
};

export { sendPublicRequest, sendPrivateRequest, sendPutFileRequest };
