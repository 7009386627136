const INVOICE_STATUS = [
    {
        status: false,
        caption: 'Pending',
        color: 'status-yellow'
    }, {
        status: true,
        caption: 'Paid',
        color: 'status-blue'
    }
];

export default INVOICE_STATUS;