const palette = {
  white: "#FFF",
  black: "#000",
  primary: {
    main: "#472183",
    light: "#47218326",
  },
  secondary: {
    main: "#4B56D2",
  },
  warn: {
    main: "#C90A0A"
  },
  status: {
    green: "#299648",
    red: "#c44239",
    yellow: "#ed6c02",
    ash: "#a6a49f",
    blue: "#2a48d1"
  }
};

module.exports = palette;
