import {
    Box,
    Typography,
} from "@mui/material";
import { useParams } from 'react-router';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { AttachmentViewer, AvatarExtended, ClickToCopy, CommentSection, DatePickerLabel, EditableLabel, MainLoader, PriorityLevel, ProgressIndicator, ProjectStatus, RichTextEditor, TaskStatus, UsersAutocompleteLabel } from "../components/common";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, getMentionsFromHtml, stableSort } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import 'react-quill/dist/quill.snow.css';
import { createComment, deleteComment, editComment, getProject, updateProject } from "../connectors/bff-connector";
import { useAuth, useNotification } from "../context";
import ROLES from "../constants/roles";
import { AttachmentUploaderDialog } from "../components/dialogs";

const headCells = [
    {
        id: 'taskCode',
        numeric: false,
        label: 'Task',
    },
    {
        id: 'serviceName',
        numeric: false,
        label: 'Service',
    },
    {
        id: 'assignee',
        numeric: false,
        label: 'Assignee',
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
    },
    {
        id: 'completion',
        numeric: false,
        label: 'Burnout',
    }
];

const ViewProjectPage = () => {
    const { projectId, projectCode } = useParams();
    const id = Number(projectId);

    const navigate = useNavigate();
    const { user } = useAuth();
    const readOnly = user.roleId > ROLES.MANAGER;
    const { showNotificationError } = useNotification();

    const [loading, setLoading] = useState(true);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);

    const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);

    const [project, setProject] = useState({});

    const goBack = () => {
        navigate(-1);
    };

    const loadProject = async () => {
        try {
            const res = await getProject({ id });
            setProject(res);
            if (!res) {
                showNotificationError("This project is no longer available");
                navigate('/projects');
                return;
            }
            setRows(res.tasks.map(t => ({
                ...t,
                taskCode: `${projectCode}-${t.id}`
            })));
            setLoading(false);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
    };

    useEffect(() => {
        loadProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const update = async (payload) => {
        try {
            const res = await updateProject({ id, ...payload });
            return res;
        } catch (err) {
            console.error(err);
            showNotificationError("Something happened when updating the project. Please try again.");
        }
    };

    const handleAddAttachment = async (newAttachments) => {
        const attachments = newAttachments.map(a => ({
            id: a.id,
            name: a.file.name,
            type: a.extension,
            size: a.file.size,
            url: URL.createObjectURL(a.file)
        }));
        const addedAttachments = attachments.map(a => a.id);
        const allAttachments = [...project.attachments, ...attachments];
        await update({ addedAttachments });
        setProject({ ...project, attachments: allAttachments });
        return true;
    };

    const handleRemoveAttachment = (attachment) => {
        const attachments = project.attachments.filter(a => a.id !== attachment.id);
        const removedAttachments = [attachment.id];
        update({ removedAttachments });
        setProject({ ...project, attachments });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClickTask = (row) => {
        navigate(`/tasks/${projectId}/${row.taskCode}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDescriptionChange = async (description) => {
        const mentions = getMentionsFromHtml(description);
        await update({ description, mentions });
        setProject({ ...project, description });
        return true;
    };

    const handleStatusChange = (status) => {
        update({ statusCode: status });
        setProject({ ...project, status });
    };

    const handlePriorityChange = (priority) => {
        update({ priorityCode: priority });
        setProject({ ...project, priority });
    };

    const handleNameChange = (name) => {
        update({ name });
        setProject({ ...project, name });
    };

    const handleAssigneeChange = (user) => {
        update({ assignedTo: user.id });
        setProject({ ...project, assigneeName: user.name, assigneeId: user.id });
    };

    const handleOwnerChange = (user) => {
        update({ owner: user.id });
        setProject({ ...project, ownerName: user.name, ownerId: user.id });
    };

    const handleStartDateChange = (dateString) => {
        update({ startDt: dateString });
        setProject({ ...project, startDate: dateString });
    };

    const handleEndDateChange = (dateString) => {
        update({ endDt: dateString });
        setProject({ ...project, endDate: dateString });
    };

    const onCreateNewComment = async (comment) => {
        try {
            const mentions = getMentionsFromHtml(comment.comment);
            const payload = {
                categoryCode: "pr",
                typeCode: "dir",
                description: comment.comment,
                parentId: id,
                mentions
            };
            const res = await createComment(payload);
            const comments = [...project.comments];
            comment.id = res.id;
            comments.unshift(comment);
            setProject({ ...project, comments });
            return true;
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to add the comment. Please try again.");
        }
    };

    const onEditComment = async (content, comment) => {
        try {
            const mentions = getMentionsFromHtml(content);
            await editComment({
                id: comment.id,
                comment: content,
                mentions
            });

            const comments = [];
            for (let i = 0; i < project.comments.length; i++) {
                if (project.comments[i].id === comment.id) {
                    project.comments[i].dateTime = new Date().toISOString();
                }
                comments.push(project.comments[i]);
            }
            setProject({ ...project, comments });
            return true;
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to edit the comment. Please try again.");
        }
    };

    const onDeleteComment = async (comment) => {
        try {
            await deleteComment({ id: comment.id });
            const comments = project.comments.filter(c => c.id !== comment.id);
            setProject({ ...project, comments });
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to delete the comment. Please try again.");
        }
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div>
            <div className="p-2 bg-white">
                <Box className="flex items-center">
                    <IconButton aria-label="back-button" onClick={goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 1 }}>
                        <Link to="/projects">
                            <Typography variant="body1" className="hover:underline">Projects</Typography>
                        </Link>
                        <Box className="flex items-center group">
                            <Link>
                                <Typography variant="subtitle1" className="group-hover:underline">{projectCode}</Typography>
                            </Link>
                            <div className="invisible ml-1 group-hover:visible">
                                <ClickToCopy caption="Copy link to project" value={window.location.href}>
                                    <IconButton aria-label="copy-content">
                                        <LinkIcon fontSize="small" />
                                    </IconButton>
                                </ClickToCopy>
                            </div>
                        </Box>
                    </Breadcrumbs>
                </Box>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                <div className="flex bg-white border-solid border-t border-t-black border-opacity-20 min-h-screen">
                    <div className="p-6 2xl:px-10 grow border-solid border-r border-r-black border-opacity-20">
                        <div className="pb-4">
                            <EditableLabel className="text-2xl font-bold text-gray-900" value={project.name} readOnly={readOnly} required onChange={handleNameChange} />
                        </div>
                        <div className="flex py-3">
                            <div className="basis-28 text-base font-medium text-gray-500">Client</div>
                            <div className="text-base font-semibold text-gray-900">{project.clientName}</div>
                        </div>
                        <div className="flex py-3">
                            <div className="basis-28 text-base font-medium text-gray-500">Service</div>
                            <div className="text-base font-semibold text-gray-900">{project.serviceName}</div>
                        </div>
                        <div className="py-3">
                            <div className="basis-28 text-base font-medium text-gray-500">Project Description</div>
                            <RichTextEditor className="my-4" placeholder="Type your project description here..." showButtons readOnly={readOnly} value={project.description} onSubmit={handleDescriptionChange} mentionKey="all-users" />
                        </div>
                        <div className='flex items-center justify-between my-3'>
                            <div className='w-52'>
                                <div className="basis-28 text-base font-medium text-gray-500">Attachments</div>
                            </div>
                            {!readOnly && <div className='w-44'>
                                <LoadingButton
                                    onClick={() => setAttachmentDialogOpen(true)}
                                    type="button"
                                    fullWidth
                                    size="medium"
                                    loadingPosition="start"
                                    startIcon={<AddIcon />}
                                    variant="outlined"
                                    sx={{ textTransform: 'none' }}
                                >
                                    Add Attachment
                                </LoadingButton>
                            </div>}
                        </div>
                        <div className="pb-3 pt-0">
                            <AttachmentViewer attachments={project.attachments} readOnly={readOnly} onRemove={handleRemoveAttachment} />
                        </div>

                        <div className="py-3">
                            <div className="basis-28 text-base font-medium text-gray-500">Tasks</div>
                            <div className="mt-4">
                                {rows.length === 0 ? (
                                    <div className='py-2 text-gray-400'>
                                        <Typography variant='body2'>No tasks added yet!</Typography>
                                    </div>
                                ) : (
                                    <Box sx={{ width: '100%' }}>
                                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                                            <TableContainer>
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    size='medium'
                                                >
                                                    <EnhancedTableHead
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={rows.length}
                                                        headCells={headCells}
                                                    />
                                                    <TableBody>
                                                        {stableSort(rows, getComparator(order, orderBy))
                                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row, index) => {
                                                                const key = `table-row-${index}`;

                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        onClick={() => handleClickTask(row)}
                                                                        role="checkbox"
                                                                        aria-checked={false}
                                                                        tabIndex={-1}
                                                                        key={key}
                                                                        selected={false}
                                                                    >
                                                                        <TableCell align="left" style={{ minWidth: 200 }}>
                                                                            <div className="text-sm font-semibold">{row.taskCode}</div>
                                                                            <div className="text-xs">{row.task}</div>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <Typography variant="body2">{row.serviceName}</Typography>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <div className={'flex items-center'}>
                                                                                <AvatarExtended alt={row.assignee} sx={{ width: 32, height: 32, marginY: '4px', fontSize: '14px', fontWeight: 600 }} />
                                                                                <div className={'ml-2'}>
                                                                                    <Typography variant='subtitle2'>{row.assignee}</Typography>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <TaskStatus readOnly status={row.status} />
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <ProgressIndicator progress={row.completion} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        {emptyRows > 0 && (
                                                            <TableRow
                                                                style={{
                                                                    height: 53 * emptyRows,
                                                                }}
                                                            >
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>

                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 20]}
                                            component="div"
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Box>
                                )}
                            </div>
                        </div>
                        <div className="py-3 mb-12">
                            <div className="basis-28 text-base font-medium text-gray-500">Discussions</div>
                            <CommentSection comments={project.comments} onCreateNew={onCreateNewComment} onDelete={onDeleteComment} onEdit={onEditComment} />
                        </div>
                    </div>
                    <div className="min-w-[384px] 2xl:min-w-[480px] p-6 2xl:px-10 bg-[#F9FDFF]">
                        <div className="pb-3">
                            <div className="text-gray-600 font-semibold text-lg">Project Overview</div>
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px]">
                                <Typography className="text-gray-500" variant="subtitle1">Owner</Typography>
                            </div>
                            <UsersAutocompleteLabel userName={project.ownerName} userId={project.ownerId} readOnly={readOnly} onChange={handleOwnerChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px]">
                                <Typography className="text-gray-500" variant="subtitle1">Status</Typography>
                            </div>
                            <ProjectStatus status={project.status} readOnly={readOnly} onChange={handleStatusChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px]">
                                <Typography className="text-gray-500" variant="subtitle1">Priority</Typography>
                            </div>
                            <PriorityLevel priority={project.priority} readOnly={readOnly} onChange={handlePriorityChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px]">
                                <Typography className="text-gray-500" variant="subtitle1">Assignee</Typography>
                            </div>
                            <UsersAutocompleteLabel userName={project.assigneeName} readOnly={readOnly} userId={project.assigneeId} onChange={handleAssigneeChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Start Date</Typography>
                            </div>
                            <DatePickerLabel label="Select start date" readOnly={readOnly} onChange={handleStartDateChange} value={project.startDate} maxDate={project.endDate} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Burnout</Typography>
                            </div>
                            <div className="grow">
                                <ProgressIndicator progress={project.completion} />
                            </div>
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[100px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Deadline</Typography>
                            </div>
                            <DatePickerLabel label="Select deadline" readOnly={readOnly} onChange={handleEndDateChange} value={project.endDate} minDate={project.startDate} />
                        </div>
                    </div>
                </div>)}

            {attachmentDialogOpen && <AttachmentUploaderDialog onClose={() => setAttachmentDialogOpen(false)} onSubmit={handleAddAttachment} header="Add Project Attachments" source="projects" categoryCode="pr" />}
        </div>
    );
};

export { ViewProjectPage };
