import { useState } from "react";

import { MinutesPickerInput } from "./MinutesPickerInput";
import { getMinutesInHoursAndMins } from "../../utils";

const MinutesPickerLabel = ({ value = 60, label = 'Time', className = '', readOnly = false, required = false, onChange = () => { } }) => {

    const [editMode, setEditMode] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const handleChange = (timeData) => {
        setCurrentValue(timeData.totalMinutes);
        onChange(timeData.totalMinutes);
    };

    const handleClick = () => {
        if (!readOnly) {
            setCurrentValue(value);
            setEditMode(true);
        }
    };

    const handleBlur = () => {
        setEditMode(false);
    };

    return (
        <div className='relative w-full'>
            <div className={(!readOnly && !editMode ? 'hover:bg-black hover:bg-opacity-5' : '') + ' rounded-md relative -left-2'}>
                {!editMode && <div className={`${className} p-2 text-base font-medium`} onClick={handleClick}>{getMinutesInHoursAndMins(currentValue).readableValue}</div>}
                {editMode && <MinutesPickerInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    openInitial
                    size='small'
                    margin="normal"
                    sx={{ mt: 0, mb: 0 }}
                    required
                    fullWidth
                    label={label}
                    value={currentValue}
                />}
            </div>
        </div>
    );
};

export { MinutesPickerLabel };