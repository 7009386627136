import {
    Typography,
    FormControl, InputLabel, Select, TextField
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAuth, useNotification } from "../context";
import { getClientBasic, getProjects } from "../connectors/bff-connector";
import { AvatarExtended, MainLoader, ProgressIndicator, ProjectStatus, SearchBox } from "../components/common";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, stableSort, getDateStringDDMMYYYY } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { CreateProjectDialog } from "../components/dialogs";
import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import PROJECT_STATUS from "../constants/projectStatus";
import ROLES from "../constants/roles";

const headCells = [
    {
        id: 'project',
        numeric: false,
        label: 'Project',
    },
    {
        id: 'client',
        numeric: false,
        label: 'Client',
    },
    {
        id: 'startDate',
        numeric: false,
        label: 'Start Date',
    },
    {
        id: 'deadline',
        numeric: false,
        label: 'Deadline',
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
    },
    {
        id: 'assignee',
        numeric: false,
        label: 'Assignee',
    },
    {
        id: 'completion',
        numeric: true,
        label: 'Completion',
    }
];

const MAX_DATE_DIFF = 3650;
const cachedData = {};

const ViewProjects = () => {
    const { user } = useAuth();
    const readOnly = user.roleId > ROLES.MANAGER;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(cachedData.projects ? false : true);
    const [rows, setRows] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState("");
    const [selectedStatusCode, setSelectedStatusCode] = useState("all");
    const [searchText, setSearchText] = useState("");
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const navigate = useNavigate();

    const [dateFilter, setDateFilter] = React.useState({
        endDate: dayjs(new Date(new Date().setDate(new Date().getDate() + MAX_DATE_DIFF / 2))),
        startDate: dayjs(new Date(new Date().setDate(new Date().getDate() - MAX_DATE_DIFF / 2)))
    });

    const { showNotificationError } = useNotification();

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
    };

    const search = async (showLoader = true) => {
        setLoading(showLoader);
        try {
            const res = await getProjects({
                "sortKey": "id",
                "sortOrder": "ASC",
                "limit": 1000000,
                "offset": 0,
                "name": searchText,
                "statusCode": selectedStatusCode === 'all' ? null : selectedStatusCode,
                "startDate": dateFilter.startDate,
                "endDate": dateFilter.endDate,
                "clientId": selectedClientId === 'all' ? null : selectedClientId,
            });
            const projects = (res || []).map(project => ({
                id: project.projectId,
                project: project.name,
                client: project.clientName,
                startDate: project.startDate || '',
                deadline: project.endDate || '',
                status: project.status,
                assignee: project.assignee,
                completion: project.completed,
                code: project.code || '',
            }));

            setRows(projects);
            if (cachedData.isInitial) {
                cachedData.projects = projects;
                cachedData.isInitial = false;
            }
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        cachedData.fromCache = false;
        setLoading(false);
    };

    const loadInitialData = async () => {
        setLoading(true);
        cachedData.isInitial = true;
        await Promise.all([loadClients()]);
        setInitialDataLoaded(true);
    };

    useEffect(() => {
        if (cachedData.projects) {
            setClients(cachedData.clients);
            setRows(cachedData.projects);
            cachedData.fromCache = true;
            setSelectedClientId("all");
            setInitialDataLoaded(true);
            return;
        }
        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialDataLoaded) {
            search(!cachedData.fromCache);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDataLoaded, searchText, selectedClientId, selectedStatusCode, dateFilter.startDate, dateFilter.endDate]);

    const onSearchKeyPress = () => {
        setLoading(true);
    };

    const handleCreateService = () => {
        setDialogOpen(true);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (row) => {
        navigate(`/projects/${row.id}/${row.code}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialogClose = (event, project) => {
        if (project) {
            search(false);
        }
        setDialogOpen(false);
    };

    const handleStartDateChange = (newValue) => {
        let endDate = dateFilter.endDate;
        if (newValue.isValid()) {
            const newEndDate = newValue.set('date', (MAX_DATE_DIFF));
            if (endDate > newEndDate) {
                endDate = newEndDate;
            }
        }
        setDateFilter({
            startDate: newValue,
            endDate
        });
    };

    const handleEndDateChange = (newValue) => {
        let startDate = dateFilter.startDate;
        if (newValue.isValid()) {
            const newStartDate = newValue.set('date', (-1 * MAX_DATE_DIFF));
            if (startDate < newStartDate) {
                startDate = newStartDate;
            }
        }
        setDateFilter({
            startDate,
            endDate: newValue
        });
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const loadClients = useCallback(async () => {
        try {
            const res = await getClientBasic({
                "name": "",
                "limit": 10000,
                "offset": 0,
                "active": true,
                "sortOrder": "ASC",
                "sortKey": "name"
            });

            const clients = res?.payload?.result;
            let c = (clients || []).map(client => ({
                id: client.id,
                name: client.name
            }));

            c = [{
                id: "all",
                name: "All"
            }, ...c]
            setClients(c);
            cachedData.clients = c;
            setSelectedClientId("all");
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClientChange = event => {
        setSelectedClientId(event.target.value);
    };

    const handleStatusChange = event => {
        setSelectedStatusCode(event.target.value);
    };

    return (
        <div className="p-4 md:p-6 bg-white">
            <Typography variant="h2">
                Projects
            </Typography>
            <div className='flex items-center justify-between my-6'>
                <div className='w-96'>
                    <SearchBox placeholder="Search Projects" onSearch={handleSearchTextChange} onSearchKeyPress={onSearchKeyPress} />
                </div>
                {!readOnly && <div className='w-48'>
                    <LoadingButton
                        onClick={handleCreateService}
                        type="submit"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        startIcon={<AddIcon />}
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                    >
                        Create Project
                    </LoadingButton>
                </div>}
            </div>
            <div className='flex items-center justify-between my-6'>
            </div>
            <div className='flex'>
                <div className='basis-1/2'>
                    <FormControl fullWidth margin='normal' size="small">
                        <InputLabel>Client</InputLabel>
                        <Select
                            labelId="select-client"
                            value={selectedClientId}
                            label="Client"
                            onChange={handleClientChange}
                        >
                            {clients.length === 0 && (
                                <MenuItem value="">
                                    <em>Loading...</em>
                                </MenuItem>
                            )}
                            {clients.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className='w-6'></div>
                <div className='basis-1/2'>
                    <FormControl fullWidth margin='normal' size="small">
                        <InputLabel>Status</InputLabel>
                        <Select
                            labelId="select-client"
                            value={selectedStatusCode}
                            label="Client"
                            onChange={handleStatusChange}
                        >
                            <MenuItem key="all" value="all">All</MenuItem>
                            {PROJECT_STATUS.map(d => <MenuItem key={d.status} value={d.status}>{d.caption}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className='w-6'></div>
                <div className='basis-1/2'>
                    <FormControl fullWidth margin='normal' size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="From"
                                InputProps={{ size: "small" }}
                                inputFormat="DD/MM/YYYY"
                                value={dateFilter.startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                <div className='w-6'></div>
                <div className='basis-1/2'>
                    <FormControl fullWidth margin='normal' size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="To"
                                InputProps={{ size: "small" }}
                                inputFormat="DD/MM/YYYY"
                                value={dateFilter.endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                rows.length === 0 ? (
                    <div className='p-20 text-center text-gray-500'>
                        <Typography variant='subtitle1'>No results found!</Typography>
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const key = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={() => handleClick(row)}
                                                        role="checkbox"
                                                        aria-checked={false}
                                                        tabIndex={-1}
                                                        key={key}
                                                        selected={false}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={key}
                                                            scope="row"
                                                        >
                                                            <Typography variant='subtitle1'>{row.project}</Typography>
                                                            <Typography className='text-gray-500' variant='body2'>{row.code}</Typography>
                                                        </TableCell>
                                                        <TableCell align="left">{row.client}</TableCell>
                                                        <TableCell align="left">{getDateStringDDMMYYYY(row.startDate)}</TableCell>
                                                        <TableCell align="left">{getDateStringDDMMYYYY(row.deadline)}</TableCell>
                                                        <TableCell align="left">
                                                            <ProjectStatus status={row.status} readOnly />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div className={'flex items-center'}>
                                                                <AvatarExtended alt={row.assignee} />
                                                                <p className={'ml-2'}>{row.assignee}</p>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <ProgressIndicator progress={row.completion} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )
            )}
            {dialogOpen && <CreateProjectDialog handleClose={handleDialogClose} />}
        </div>
    );
};

export { ViewProjects };
