import { BrowserRouter, Routes as Switch, Route, Navigate } from "react-router-dom";
import { MainWrapper } from "./components/main-wrapper";
import { useAuth } from "./context";
import { ViewAuth, ViewClients, ViewDesignations, ViewInvoices, ViewProjects, ViewResources, ViewServices, ViewSummary, ViewTasks, ViewTeams, ViewUsers } from "./views";
import { ViewCreateInvoicePage } from "./views/ViewCreateInvoicePage";
import { ViewInvoicePage } from "./views/ViewInvoicePage";
import { ViewProjectPage } from "./views/ViewProjectPage";
import { ViewResourceIndividual } from "./views/ViewResourceIndividual";
import { ViewTaskPage } from "./views/ViewTaskPage";

const Routes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        // private routes
        <Switch>
          <Route path="/" element={<MainWrapper />}>
            <Route path="/summary" element={<ViewSummary />} />
            <Route path="/clients" element={<ViewClients />} />
            <Route path="/projects" element={<ViewProjects />} />
            <Route path="/projects/:projectId/:projectCode" element={<ViewProjectPage />} />
            <Route path="/tasks" element={<ViewTasks />} />
            <Route path="/tasks/:projectId/:taskCode" element={<ViewTaskPage />} />
            <Route path="/resources" element={<ViewResources />} />
            <Route path="/resources/:userId" element={<ViewResourceIndividual />} />
            <Route path="/invoices" element={<ViewInvoices />} />
            <Route path="/invoices/create" element={<ViewCreateInvoicePage />} />
            <Route path="/invoices/view/:invoiceCode" element={<ViewInvoicePage />} />
            <Route path="/services" element={<ViewServices />} />
            <Route path="/designations" element={<ViewDesignations />} />
            <Route path="/teams" element={<ViewTeams />} />
            <Route path="/users" element={<ViewUsers />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Switch>) : (
        // public routes
        <Switch>
          <Route path="/" element={<ViewAuth />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default Routes;
