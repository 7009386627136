import { VerticalBarChart } from "../common/VerticalBarChart";
import * as React from "react";


const HighestBillableEmployees = ({ employees = [] }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Top 10 Billable Employees',
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        const i = (context[0] || {}).dataIndex;
                        return `${employees[i].firstName} ${employees[i].lastName}`;
                    }
                }
            }

        },
        scales: {
            x: {
                grid: {
                    display: false, // Remove x-axis grid lines
                },
            },
            y: {
                grid: {
                    display: false, // Remove y-axis grid lines
                },
            },
        },
    };

    const data = {
        labels: employees.map(em => `${em.firstName}`),
        datasets: [
            {
                label: 'Billable',
                data: employees.map(em => em.billableHours),
                backgroundColor: '#00bfa0',
            },
            {
                label: 'Non Billable',
                data: employees.map(em => em.nonBillableHours),
                backgroundColor: '#e60049',
            },
        ]
    };


    return (
        <div className="flex flex-col items-center justify-center block border border-gray-200 rounded-lg shadow p-2">
            <VerticalBarChart options={options} data={data} />
        </div>
    );
};

export { HighestBillableEmployees };
