import { useState } from "react";

import { TextField } from "@mui/material";

const EditableNumberLabel = ({ value = '', label = 'Enter a Number', min = null, max = null, className = '', suffix = '', readOnly = false, required = false, onChange = () => { }, sx = {} }) => {

    const [editMode, setEditMode] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    const [hasError, setHasError] = useState(false);

    const handleChange = (event) => {
        const val = event.target.value;
        const number = Number(val || "0");
        if (number >= min && number <= max) {
            setHasError(false);
        } else {
            setHasError(true);
        }
        setCurrentValue(val);
    };

    const handleClick = () => {
        if (!readOnly) {
            setCurrentValue(value);
            setEditMode(true);
            setHasError(false);
        }
    };

    const handleBlur = () => {
        if (!hasError) {
            onChange(Number(currentValue));
            setEditMode(false);
        }
    };

    return (
        <div className='relative w-full'>
            <div className={(!readOnly && !editMode ? 'hover:bg-black hover:bg-opacity-5' : '') + ' rounded-md relative -left-2'}>
                {!editMode && <div className={`${className} p-2 text-base font-medium`} onClick={handleClick}>{value}{suffix}</div>}
                {editMode && <TextField
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    autoFocus
                    size='small'
                    error={hasError}
                    margin="normal"
                    required
                    fullWidth
                    InputProps={{ inputProps: { min, max } }}
                    sx={sx}
                    type="number"
                    label={label}
                    name="editable_number_"
                    value={currentValue}
                />}
            </div>
        </div>
    );
};

export { EditableNumberLabel };