import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";

const ClickToCopy = ({ caption = 'Click to copy', successMessage = 'Copied!', value = '', children }) => {

    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <Tooltip title={copied ? successMessage : caption} arrow>
            <div onClick={handleClick}>
                {children || (<IconButton aria-label="copy-content">
                    <ContentCopyIcon fontSize="small" />
                </IconButton>)}
            </div>
        </Tooltip>
    );
};

export { ClickToCopy };
