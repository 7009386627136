import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth, useNotification } from "../../context";
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { createProject, getClients, getDefaultTasks } from '../../connectors/bff-connector';
import MenuItem from "@mui/material/MenuItem";
import { AttachmentUploader, RichTextEditor, UsersAutocomplete } from "../common";

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { getMentionsFromHtml } from '../../utils';
import { useRef } from 'react';
import { DefaultTaskPickerDialog } from './DefaultTaskPickerDialog';


const CreateProjectDialog = ({ handleClose = () => { } }) => {
    const { user } = useAuth();
    const [name, setName] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isNameDirty, setIsNameDirty] = useState(false);

    const [description, setDescription] = useState('');
    const [mentions, setMentions] = useState([]);

    const [selectedClientId, setSelectedClientId] = useState('');
    const [isSelectedClientIdDirty, setIsSelectedClientIdDirty] = useState(false);

    const [selectedAssigneeUserId, setSelectedAssigneeUserId] = useState(user.userId);

    const [saving, setSaving] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [startDate, setStartDate] = React.useState(dayjs(new Date().toString()));
    const [endDate, setEndDate] = React.useState(dayjs(new Date().toString()));

    const [isStartDateValid, setIsStartDateValid] = useState(true);
    const [isEndDateValid, setIsEndDateValid] = useState(true);

    const [clients, setClients] = useState([]);

    const [attachments, setAttachments] = useState([]);
    const [uploading, setUploading] = useState(false);

    const [defaultTasks, setDefaultTasks] = useState([]);
    const [selectedDefaultTasks, setSelectedDefaultTasks] = useState(null);
    const [showDefaultTasksDialog, setShowDefaultTasksDialog] = useState(false);

    const refScroll = useRef(null);

    const handleStartDateChange = (newValue) => {
        if (newValue?.isValid()) {
            setIsStartDateValid(true);
            if (newValue > endDate) {
                setEndDate(newValue);
                setIsEndDateValid(true);
            }
        } else {
            setIsStartDateValid(false);
        }
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        if (newValue?.isValid()) {
            setIsEndDateValid(true);
        } else {
            setIsEndDateValid(false);
        }
        setEndDate(newValue);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { showNotificationError, showNotificationSuccess } = useNotification();


    const loadClients = useCallback(async () => {
        try {
            const res = await getClients({
                "name": "",
                "limit": 10000,
                "offset": 0,
                "active": true,
                "sortOrder": "ASC",
                "sortKey": "name"
            });

            const clients = res?.payload?.result;
            const c = (clients || []).map(client => ({
                id: client.id,
                name: client.name
            }));

            setClients(c);
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadClients();
    }, [loadClients]);

    const loadDefaultTasks = async (clientId) => {
        try {
            const res = await getDefaultTasks({ clientId });
            setDefaultTasks(res || []);
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
    };

    useEffect(() => {
        if (selectedClientId) {
            loadDefaultTasks(selectedClientId);
        }
    }, [selectedClientId]);

    useEffect(() => {
        if (selectedDefaultTasks !== null) {
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDefaultTasks]);

    const handleSubmit = async () => {

        if (!isNameValid) {
            setErrorMessage("Please type the name.");
            setIsNameDirty(true);
            return;
        }

        if (!selectedClientId) {
            setErrorMessage("Please select a client.");
            setIsSelectedClientIdDirty(true);
            return;
        }

        if (!selectedAssigneeUserId) {
            setErrorMessage("Please select an assignee.");
            return;
        }

        if (!isStartDateValid) {
            setErrorMessage("Please select a start date.");
            return;
        }

        if (!isEndDateValid) {
            setErrorMessage("Please select an end date.");
            return;
        }

        setErrorMessage('');
        setSaving(true);

        if (defaultTasks.length > 0 && selectedDefaultTasks === null) {
            setShowDefaultTasksDialog(true);
            return;
        }

        const newProject = {
            name: name.trim(),
            description: description,
            selectedAssigneeUserId,
            selectedClientId,
            startDate: startDate.toDate().toISOString(),
            endDate: endDate.toDate().toISOString(),
            mentions,
            attachmentIds: attachments,
            defaultTasks: selectedDefaultTasks || []
        };

        try {
            const res = await createProject(newProject);
            showNotificationSuccess("New project created successfully!");
            handleClose({}, res);
        } catch (err) {
            showNotificationError("Failed to create the project. Please try again.");
            setSaving(false);
        }
    };

    const handleUploadComplete = async (newAttachments) => {
        setAttachments(newAttachments.map(a => a.id));
        setUploading(false);
    };

    const handleUploadStart = () => {
        setUploading(true);
        refScroll?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleNameChange = event => {
        const val = event.target.value;
        if (val?.trim()) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }
        setName(val);
    };

    const handleDescriptionChange = (description) => {
        const mentions = getMentionsFromHtml(description);
        setMentions(mentions);
        setDescription(description);
    };

    const handleClientChange = event => {
        setIsSelectedClientIdDirty(true);
        setSelectedClientId(event.target.value);
    };

    const handleAssigneeChange = (user) => {
        setSelectedAssigneeUserId(user?.id || 0);
    };

    const handleDefaultTaskDialogClose = (selectedTasks) => {
        setSelectedDefaultTasks(selectedTasks);
        setShowDefaultTasksDialog(false);
    };

    return (
        <div>
            <Dialog
                open
                fullScreen={fullScreen}
                scroll='paper'
                fullWidth
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle>Create Project</DialogTitle>
                <DialogContent dividers>
                    <div className='sm:px-8'>
                        <div className='relative text-center'>
                            <Typography variant='subtitle1'
                                className='absolute text-warn-main w-full -top-3'>{errorMessage}</Typography>
                        </div>
                        <TextField
                            onChange={(e) => handleNameChange(e)}
                            onBlur={() => setIsNameDirty(true)}
                            error={isNameDirty && !isNameValid}
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            label="Project Name"
                            name="project_name"
                            value={name}
                        />
                        <FormControl fullWidth margin='normal' size='small' required error={isSelectedClientIdDirty && !selectedClientId}>
                            <InputLabel>Client</InputLabel>
                            <Select
                                labelId="select-client"
                                value={selectedClientId}
                                label="Client"
                                onChange={handleClientChange}
                            >
                                {clients.length === 0 && (
                                    <MenuItem value="">
                                        <em>Loading...</em>
                                    </MenuItem>
                                )}
                                {clients.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <UsersAutocomplete label="Assignee" onChange={handleAssigneeChange}
                            userId={selectedAssigneeUserId} required error={!selectedAssigneeUserId} />
                        <div className='flex mt-6 mb-2'>
                            <div className='basis-1/2'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => <TextField {...params} error={!isStartDateValid} required size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='w-6'></div>
                            <div className='basis-1/2'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        disablePast={true}
                                        minDate={startDate}
                                        label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        InputProps={{ size: 'small' }}
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} error={!isEndDateValid} required size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='mt-6 mb-2'>
                            <RichTextEditor placeholder="Type your project description here..." minHeight={100} openInEditMode showButtons={false} onChange={handleDescriptionChange} mentionKey="all-users" />
                        </div>
                        <div className='mt-6 mb-14'>
                            <AttachmentUploader onUploadStart={handleUploadStart} onUploadComplete={handleUploadComplete} source="projects" categoryCode="pr" />
                        </div>
                    </div>
                    <div ref={refScroll}></div>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full p-2 justify-end'>
                        <div>
                            <LoadingButton
                                onClick={handleClose}
                                type="submit"
                                fullWidth
                                disabled={saving}
                                size="medium"
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 100 }}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                onClick={handleSubmit}
                                type="submit"
                                fullWidth
                                loading={saving}
                                disabled={uploading}
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 120 }}
                            >
                                Create
                            </LoadingButton>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            {showDefaultTasksDialog && <DefaultTaskPickerDialog defaultTasks={defaultTasks} onClose={handleDefaultTaskDialogClose} />}
        </div>
    );
}
    ;

export { CreateProjectDialog };
