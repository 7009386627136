const TabPanel = ({ children, value, index, softHide = false, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {(softHide || value === index) && children}
        </div>
    );
}

export { TabPanel };
