import {
    FormControl, TextField,
    Typography,
} from "@mui/material";
import { MainLoader } from "../components/common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import dayjs from "dayjs";
import { useState } from "react";
import { Overview } from "../components/summary/overview";
import { HighestBillableEmployees } from "../components/summary/highestBillableEmployees";
import { LowestBillableEmployees } from "../components/summary/LowestBillableEmployees";
import { HighestMissEmployees } from "../components/summary/highestMissEmployees";
import { LeastMissEmployees } from "../components/summary/LeastMissEmployees";
import { HighestLoadedEmployees } from "../components/summary/highestLoadedEmployees";
import { LeastLoadedEmployees } from "../components/summary/LeastLoadedEmployees";
import { useNotification } from "../context";
import {getAverageWorkingHors, getSummary, getUserLoad, getUserPerformance, getUserNonBillable, getUserTotal} from "../connectors/bff-connector";
import { useEffect } from "react";
import {WorkingEmployees} from "../components/summary/WorkingEmployees";

const MAX_DATE_DIFF = 30;

const ViewSummary = () => {

    const { showNotificationError } = useNotification();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const [dateFilter, setDateFilter] = useState({
        endDate: dayjs(new Date()),
        startDate: dayjs(new Date(new Date().setDate(new Date().getDate() - MAX_DATE_DIFF)))
    });

    useEffect(() => {
        loadSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter]);

    const handleStartDateChange = (newValue) => {
        let endDate = dateFilter.endDate;
        setDateFilter({
            startDate: newValue,
            endDate
        });
    };

    const handleEndDateChange = (newValue) => {
        let startDate = dateFilter.startDate;
        setDateFilter({
            startDate,
            endDate: newValue
        });
    };

    const loadSummary = async () => {
        try {
            if (!dateFilter.startDate.isValid() || !dateFilter.endDate.isValid()) {
                return;
            }
            setLoading(true);
            const requestBody = {
                startDate: dateFilter.startDate.toISOString(),
                endDate: dateFilter.endDate.toISOString()
            };
            const [summary, userPerformance, userLoad, workingHours, nonBillable, totalHorus] = await Promise.all([
                getSummary(requestBody),
                getUserPerformance(requestBody),
                getUserLoad(requestBody),
                getAverageWorkingHors(requestBody),
                getUserNonBillable(requestBody),
                getUserTotal(requestBody)
            ]);
            setData({
                summary,
                userPerformance,
                userLoad,
                workingHours,
                nonBillable,
                totalHorus
            });
            setLoading(false);
        } catch (err) {
            console.error(err);
            showNotificationError("Something went wrong! Please try again.");
        }
    };


    return (
        <div className="p-4 md:p-6">
            <Typography variant="h2">
                Summary
            </Typography>
            <div className='flex items-center justify-between my-6'>
            </div>
            <div className='flex '>
                <div className='basis-1/4'>
                    <FormControl fullWidth margin='normal' size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="From"
                                InputProps={{ size: "small" }}
                                inputFormat="DD/MM/YYYY"
                                value={dateFilter.startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                <div className='w-6'></div>
                <div className='basis-1/4'>
                    <FormControl fullWidth margin='normal' size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="To"
                                disableFuture={true}
                                InputProps={{ size: "small" }}
                                inputFormat="DD/MM/YYYY"
                                value={dateFilter.endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                <Box sx={{ width: '100%' }}>
                    <Paper variant="" sx={{ width: '100%', my: 2 }}>
                        <div className="flex flex-row flex-wrap">
                            <div className="basis-full">
                                <Overview summary={data.summary} />
                            </div>
                            <div className="basis-full grid  grid-cols-3 gap-8">
                                <HighestBillableEmployees employees={data?.userPerformance?.top} />
                                <LowestBillableEmployees employees={data?.userPerformance?.least} />
                                <LowestBillableEmployees employees={data?.nonBillable?.top} text={"Top 10 Non billing employees"}/>
                                <LowestBillableEmployees employees={data?.nonBillable?.least } text={"Least 10 Non billing employees"}/>
                                <WorkingEmployees employees={data?.totalHorus?.top} text={"Top 10 Hard working employees"} satck/>
                                <WorkingEmployees employees={data?.totalHorus?.least} text={"Least 10 Hard working employees"} stack/>
                                <HighestLoadedEmployees employees={data?.userLoad?.top} />
                                <LeastLoadedEmployees employees={data?.userLoad?.least} />
                                <HighestMissEmployees employees={data?.workingHours}/>
                                {/*<LeastMissEmployees employees={data?.summary?.activeUsers?.activeUsers}/>*/}
                            </div>
                        </div>
                    </Paper>
                </Box>
            )}

        </div>
    );
};

export { ViewSummary };
