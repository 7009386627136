import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useState } from 'react';

const DefaultTaskPickerDialog = ({ defaultTasks = [], onClose = () => { } }) => {

    const [tasks, setTasks] = useState(defaultTasks);

    const handleSubmit = () => {
        onClose(tasks.filter(t => t.selected).map(t => t.id));
    };

    const handleChange = (event) => {
        tasks[event.target.name].selected = event.target.checked;
        setTasks([...tasks]);
    };

    return (
        <Dialog
            open
            scroll='paper'
        >
            <DialogTitle>
                Create default tasks?
            </DialogTitle>
            <DialogContent dividers>
                <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Please select the default tasks you want to create for this project.</FormLabel>
                    <FormGroup>
                        {defaultTasks.map((task, i) => (
                            <FormControlLabel key={task.id}
                                control={
                                    <Checkbox checked={task.selected || false} onChange={handleChange} name={`${i}`} />
                                }
                                label={task.name}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose([])}
                    variant="outlined"
                    sx={{ textTransform: 'none', m: 1, width: 100 }}
                >{'Skip >>'}</Button>
                <Button onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'none', m: 1, width: 100 }}
                >Create</Button>
            </DialogActions>
        </Dialog>
    );
}

export { DefaultTaskPickerDialog };