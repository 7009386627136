import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { NavigationBar } from './NavigationBar';
import { DrawerContent } from './DrawerContent';
import { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { NotificationSubscriber } from '../notifications';

const drawerWidth = 240;

const MainWrapper = ({ children, className = '' }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = useCallback(() => {
        setMobileOpen(!mobileOpen);
    }, [mobileOpen]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <NotificationSubscriber />
            <NavigationBar onDrawerToggle={handleDrawerToggle} />
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <DrawerContent onChange={handleDrawerToggle} />
                </Drawer>
                {!mobileOpen && <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <DrawerContent />
                </Drawer>}
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Box className={className}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export { MainWrapper };