import { useState } from "react";
import Typography from "@mui/material/Typography";

import { useNotification } from "../context";
import { getClients } from "../connectors/bff-connector";
import { MainLoader, SearchBox } from "../components/common";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, stableSort } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { Chip } from "@mui/material";
import { ManageClientDialog } from "../components/dialogs";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';

const ViewClients = () => {
  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Client Name",
    },
    {
      id: "joinedDate",
      numeric: false,
      label: "Joined Date",
    },
    {
      id: "email",
      numeric: false,
      label: "Contact Details",
    },
    {
      id: "status",
      numeric: false,
      label: "status",
    },
  ];

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [showAddUpdateClientModal, setShowAddUpdateClientModal] =
    useState(false);

  const { showNotificationError } = useNotification();

  const mapClientToRow = (client) => {
    return {
      name: client.name,
      joinedDate: client.joinedDate
        ? new Intl.DateTimeFormat("en-AU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(client.joinedDate))
        : "",
      contactDetails: { email: client.email, phone: client.phone },
      email: client.email,
      status: client.active,
      meta: client,
    };
  };

  const onSearch = async (searchText) => {
    try {
      const res = await getClients({
        sortKey: "id",
        sortOrder: "ASC",
        limit: 1000000,
        offset: 0,
        name: searchText,
      });
      const clients = res?.payload?.result || [];
      setRows(clients.map((client) => mapClientToRow(client)));
    } catch (err) {
      showNotificationError("Something went wrong! Please try again.");
    }
    setLoading(false);
  };

  const onSearchKeyPress = () => {
    setLoading(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (row) => {
    const meta = row.meta;
    // here we transform the data
    const editClientPayload = {
      clientId: meta.id,
    };
    setSelectedRow(editClientPayload);
    setShowAddUpdateClientModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddUpdateClientClose = (payload) => {

    setShowAddUpdateClientModal(false);
    setSelectedRow(null);
    // if payload is found that means there is a new client, so add that to the client list without any extra data loading
    if (payload) {
      setRows((rows) => {
        rows.push(mapClientToRow(payload));
        return [...rows];
      });
    } else if (payload !== null) {
      onSearch("");
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <div className="p-4 md:p-6 bg-white">
      <Typography variant="h2">Clients</Typography>
      <div className="flex items-center justify-between my-6">
        <div className="w-96">
          <SearchBox
            placeholder="Search Client"
            onSearch={onSearch}
            onSearchKeyPress={onSearchKeyPress}
          />
        </div>

        <div className='w-48'>
          <LoadingButton
            onClick={() => setShowAddUpdateClientModal(true)}
            type="submit"
            fullWidth
            size="large"
            loadingPosition="start"
            startIcon={<AddIcon />}
            variant="contained"
            sx={{ textTransform: 'none' }}
          >
            Create Client
          </LoadingButton>
        </div>
      </div>
      {loading ? (
        <div className="h-96 flex items-center">
          <MainLoader />
        </div>
      ) : rows.length === 0 ? (
        <div className="p-20 text-center text-gray-500">
          <Typography variant="subtitle1">No results found!</Typography>
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper variant="outlined" sx={{ width: "100%", my: 2 }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const key = `table-row-${index}`;

                      return (
                        <TableRow
                          hover
                          onMouseDown={() => handleClick(row)}
                          role="checkbox"
                          aria-checked={false}
                          tabIndex={-1}
                          key={key}
                          selected={false}
                        >
                          <TableCell component="th" id={key} scope="row">
                            <span className="font-bold">{row.name}</span>
                          </TableCell>
                          <TableCell align="left">{row.joinedDate}</TableCell>
                          <TableCell align="left">
                            <div className="flex flex-col justify-center justify-items-center">
                              <span className="mb-2 font-bold">
                                {row.contactDetails.phone}
                              </span>
                              <span> {row.contactDetails.email}</span>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {row.status ? <Chip label="Active" color="success" variant="outlined" /> : <Chip label="Deactivated" color="error" variant="outlined" />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}

      {showAddUpdateClientModal && <ManageClientDialog
        onClose={handleAddUpdateClientClose}
        updatePayload={selectedRow}
        isUpdate={!!selectedRow}
      />}
    </div>
  );
};

export { ViewClients };
