import * as React from "react";
import {LineChart} from "../common/LineChart";


const HighestMissEmployees = ({ employees = [] }) => {

    const bhrs = [];
    const nbhrs = [];
    const thrs = [];
    const labels = [];

    employees.forEach(emp => {
        bhrs.push(emp.bhours);
        nbhrs.push(emp.nbhours);
        thrs.push(emp.total);
        labels.push(emp.created_at.slice(5, 10));
    })
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Working Hours',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Remove x-axis grid lines
                },
            },
            y: {
                grid: {
                    display: false, // Remove y-axis grid lines
                },
            },
        }
    };


    const data = {
        labels,
        datasets: [
            {
                label: 'Billable Hours',
                data: bhrs,
                backgroundColor: '#3da4ab',
                borderColor: '#3da4ab',
            },
            {
                label: 'Non Billable Hours',
                data: nbhrs,
                backgroundColor: '#fe8a71',
                borderColor: '#f6cd61',
            },
            {
                label: 'Total Hours',
                data: thrs,
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
            },
        ],
    };


    return (
        <div className="flex flex-col items-center justify-center block border border-gray-200 rounded-lg shadow p-2">
            <LineChart options = {options} data = {data}/>
        </div>
    );
};

export { HighestMissEmployees };
