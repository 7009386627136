const getColor = (extension) => {

    switch (extension) {
        case "7Z": return "#6791fb";
        case "AAC": return "#416184";
        case "AI": return "#712c7c";
        case "AMR": return "#4f3513";
        case "APE": return "#430845";
        case "APK": return "#b76853";
        case "AVI": return "#3c4799";
        case "BMP": return "#37bb72";
        case "CSS": return "#a05a94";
        case "CSV": return "#b1ca59";
        case "DAT": return "#ff6d33";
        case "DOC": return "#7584cd";
        case "DOCX": return "#73cbed";
        case "EPS": return "#36949e";
        case "EXE": return "#1c5e39";
        case "FLV": return "#5ea9b1";
        case "GIF": return "#eba851";
        case "HTML": return "#f18375";
        case "ICO": return "#ff9166";
        case "JPEG": return "#a5ae61";
        case "JPG": return "#430845";
        case "JS": return "#88317a";
        case "KEY": return "#37bb72";
        case "M4R": return "#3b2a14";
        case "MOV": return "#5681b0";
        case "MP3": return "#eba851";
        case "MP4": return "#8379e3";
        case "MPG": return "#c9741f";
        case "ODP": return "#ffbc00";
        case "ODS": return "#ffb699";
        case "PDF": return "#f70000";
        case "PHP": return "#ad6e1c";
        case "PNG": return "#5e94c9";
        case "PPT": return "#e59328";
        case "PPTX": return "#ff4800";
        case "PSD": return "#3679bb";
        case "PY": return "#55acee";
        case "RAR": return "#298c56";
        case "RAW": return "#7c4207";
        case "SQL": return "#61aad5";
        case "SVG": return "#7584cd";
        case "TIF": return "#c66a62";
        case "TXT": return "#a3a3a3";
        case "WAV": return "#2b4175";
        case "XLA": return "#a4bdfd";
        case "XLAM": return "#1a243f";
        case "XLS": return "#37bb72";
        case "XLSM": return "#5d74f2";
        case "XLT": return "#283db3";
        case "XLTM": return "#3658ae";
        case "XLTX": return "#5fc98e";
        case "XLXS": return "#8fca79";
        case "XML": return "#04cc47";
        case "ZIP": return "#37bb72";
        default: return "#808080";
    }
};

const FileTypeIcon = ({ width = 100, type = 'txt' }) => {

    const extension = type.toUpperCase();
    const color = getColor(extension);

    return (
        <div style={{ width: width, height: width }} className="relative inline-block bg-opacity-0">

            <div className="absolute right-0 bottom-0 z-10" style={{ clipPath: 'polygon(64% 0, 100% 28%, 100% 100%, 0 100%, 0 0)', width: width * 0.78, height: width, borderRadius: width * 0.085, background: color }}>
                <div className="bg-white absolute" style={{ clipPath: 'polygon(58% 0, 100% 29%, 100% 100%, 0 100%, 0 0)', width: width * 0.64, height: width * 0.86, borderRadius: width * 0.04, bottom: width * 0.07, right: width * 0.07 }}>
                </div>
                <div className="bg-opacity-0 absolute border-solid" style={{ width: width * 0.23, height: width * 0.23, borderWidth: width * 0.07, borderRadius: width * 0.06, borderColor: color, right: width * 0.07, top: width * 0.07 }}>
                </div >
            </div >

            <div className="absolute z-20" style={{ width: width * 0.76, height: width * 0.275, bottom: width * 0.175, padding: width * 0.04, borderRadius: width * 0.05 }}>
                <div className="absolute z-30 text-white font-semibold text-center" style={{ fontFamily: 'sans-serif', maxWidth: width * 0.84, minWidth: width * 0.7, height: width * 0.275, borderRadius: width * 0.04, fontSize: width * 0.275, right: width * 0.04, letterSpacing: width * 0.025, lineHeight: (width * 0.275) + 'px', paddingLeft: width * 0.02, paddingRight: width * 0.02, background: color }} >
                    {extension}</div >
            </div >
        </div >
    );
};

export { FileTypeIcon };
