import { LinearProgress } from '@mui/material';

const ProgressIndicator = ({ progress = 0, caption = "" }) => {

    return (
        <div>
            <div className="text-gray-700 text-xs py-1 whitespace-nowrap">
                {!caption && <span>{progress}% - {progress > 100 ? 'Time exceeded!' : 'Complete'}</span>}
                {caption && <span>{caption}</span>}
            </div>
            <LinearProgress color={progress > 100 ? 'error' : 'success'} variant={"determinate"} value={progress > 100 ? 100 : progress} />
        </div>
    );
};

export { ProgressIndicator };
