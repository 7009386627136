import { useEffect, useRef, useState } from "react";
import { usePdf } from '@mikecousins/react-pdf';
import { MainLoader } from './MainLoader';
import { LoadingButton } from "@mui/lab";
import DownloadIcon from '@mui/icons-material/Download';


const PdfPage = ({ canvasRef = null, totalPages = 1, currentPage = 1 }) => {

    return (
        <div>
            <div className="border border-solid border-gray-400" >
                <canvas ref={canvasRef} />
            </div>
            <div className="text-sm font-bold text-gray-500 text-center pt-2 pb-12">Page {currentPage} of {totalPages}</div>
        </div>
    );
};

const PdfPageViewer = ({ file = '', scale = 1, page = 1 }) => {

    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        file,
        canvasRef,
        page,
        scale
    });

    return (
        <div>
            {pdfDocument ? (
                <PdfPage canvasRef={canvasRef} currentPage={page} totalPages={pdfDocument.numPages} />
            ) : (
                <div className="mt-20"><MainLoader /></div>
            )}
        </div>
    );
};

const PdfFileViewer = ({ file = '', scale = 1, onLoad = () => { } }) => {

    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        file,
        scale,
        canvasRef
    });

    useEffect(() => {
        if (pdfDocument) {
            onLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfDocument]);

    return (
        <div>
            {pdfDocument ? (
                <div>
                    <PdfPage canvasRef={canvasRef} currentPage={1} totalPages={pdfDocument.numPages} />
                    {pdfDocument.numPages > 1 && Array.from(Array(pdfDocument.numPages - 1)).map((x, i) => (<PdfPageViewer key={i} file={file} scale={scale} page={i + 2} />))}
                </div>
            ) : (
                <div className="mt-20"><MainLoader /></div>
            )}
        </div>
    );
};

const PdfViewer = ({ url = '', scale = 1, fileName = 'document.pdf' }) => {

    const [loading, setLoading] = useState(true);
    const [loadingPages, setLoadingPages] = useState(true);
    const [file, setFile] = useState(null);

    const loadPdf = async () => {
        try {
            let fileURL = null;
            const response = await fetch(url);
            const blob = await response.blob();
            fileURL = window.URL.createObjectURL(blob);
            setFile(fileURL);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        loadPdf();

        return () => {
            if (file) {
                window.URL.revokeObjectURL(file);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownload = () => {
        let link = document.createElement('a');
        link.href = file;
        link.download = fileName;
        link.click();
    };

    return (
        <div>
            {loading ? (
                <div className="mt-20"><MainLoader /></div>
            ) : (
                <div className="relative">
                    <PdfFileViewer file={file} scale={scale} onLoad={() => setLoadingPages(false)} />
                    {!loadingPages && <div className="absolute top-0 right-0 p-2">
                        <LoadingButton
                            onClick={handleDownload}
                            fullWidth
                            size="medium"
                            variant="outlined"
                            sx={{ textTransform: 'none', m: 1, width: 120 }}
                            startIcon={<DownloadIcon />}
                        >
                            Download
                        </LoadingButton>
                    </div>}
                </div>
            )}
        </div>
    );
};

export { PdfViewer };
