const TASK_STATUS = [
    {
        status: 'BKL',
        caption: 'Back Log',
        color: 'status-ash'
    }, {
        status: 'IP',
        caption: 'In Progress',
        color: 'status-blue'
    },
    {
        status: 'BLK',
        caption: 'Blocked',
        color: 'status-red'
    },
    {
        status: 'CMP',
        caption: 'Completed',
        color: 'status-green'
    },
    {
        status: 'CNS',
        caption: 'Cancelled',
        color: 'black'
    }
];

export default TASK_STATUS;