import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import LockResetIcon from '@mui/icons-material/LockReset';

import { useAuth } from "../context";
import { useRef, useState } from 'react';


const isValidPassword = (password) => {
  if (password?.length >= 6 && /\d/.test(password) && /[a-zA-Z]/.test(password)) {
    return true;
  }
  return false;
};

const NewPasswordInput = ({
  showError = () => { },
  submit = () => { },
  isReset = false,
  email = ''
}) => {


  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordConfirmValid, setIsPasswordConfirmValid] = useState(false);
  const [isPasswordConfirmDirty, setIsPasswordConfirmDirty] = useState(false);
  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isCodeDirty, setIsCodeDirty] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const refPassword = useRef(null);
  const refPasswordConfirm = useRef(null);

  const handlePasswordChange = event => {
    const val = event.target.value;

    if (isValidPassword(val)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
    setPassword(val.trim());
  }

  const handlePasswordConfirmChange = event => {
    const val = event.target.value;

    if (isValidPassword(val)) {
      setIsPasswordConfirmValid(true);
    } else {
      setIsPasswordConfirmValid(false);
    }
    setPasswordConfirm(val.trim());
  }

  const handleCodeChange = event => {
    const val = event.target.value;

    if (val?.length === 6 && isNumeric(val)) {
      setIsCodeValid(true);
    } else {
      setIsCodeValid(false);
    }
    setCode(val.trim());
  }

  const handleSubmit = async () => {
    showError('');
    if (isPasswordValid && isPasswordConfirmValid && password === passwordConfirm && (!isReset || isCodeValid)) {
      try {
        setIsLoginLoading(true);
        if (isReset) {
          await submit(email, code, password);
        } else {
          await submit(password);
        }
      } catch (e) {
        setIsLoginLoading(false);
        showError(e.message);
        if (e?.code === "CodeMismatchException" && isReset) {
          setIsCodeValid(false);
        }
      }
    } else {
      setIsPasswordDirty(true);
      setIsPasswordConfirmDirty(true);
      if (isReset) {
        setIsCodeDirty(true);
      }
      if (isReset && !isCodeValid) {
        showError("Reset code is invalid. Please check your emails for the code");
      } else {
        showError("Passwords do not match!");
      }
    }
  };

  const onPasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      refPasswordConfirm.current.focus();
    }
  }

  const onPasswordConfirmKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  }

  const onCodeKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      refPassword.current.focus();
    }
  }

  return (
    <div>
      <Typography variant='body2' className='text-secondary-main w-full py-2'>Password should contain both numbers and letters. Minimum length is 6.</Typography>
      {isReset && <TextField
        onChange={(e) => handleCodeChange(e)}
        onBlur={() => setIsCodeDirty(true)}
        error={isCodeDirty && !isCodeValid}
        onKeyDown={onCodeKeyPress}
        margin="normal"
        required
        fullWidth
        name="code"
        label="Password Reset Code"
        type="text"
        id="code"
      />}
      <TextField
        onChange={(e) => handlePasswordChange(e)}
        onBlur={() => setIsPasswordDirty(true)}
        error={isPasswordDirty && !isPasswordValid}
        onKeyDown={onPasswordKeyPress}
        inputRef={refPassword}
        margin="normal"
        required
        fullWidth
        name="password2"
        label="New Password"
        type="password"
        id="password1"
        autoComplete="current-password"
      />
      <TextField
        onChange={(e) => handlePasswordConfirmChange(e)}
        onBlur={() => setIsPasswordConfirmDirty(true)}
        error={isPasswordConfirmDirty && !isPasswordConfirmValid}
        onKeyDown={onPasswordConfirmKeyPress}
        inputRef={refPasswordConfirm}
        margin="normal"
        required
        fullWidth
        name="password2"
        label="Confirm New Password"
        type="password"
        id="password2"
      />
      <div
        className='w-2/3 mx-auto'>
        <LoadingButton
          onClick={handleSubmit}
          type="submit"
          fullWidth
          loading={isLoginLoading}
          size="large"
          loadingPosition="start"
          startIcon={<LoginIcon />}
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: 'none' }}
        >
          Change Password
        </LoadingButton>
      </div>
    </div>
  );
};

const ViewAuth = () => {

  const { signIn, completeNewPassword, forgotPasswordSubmit, forgotPassword, authStatus, AUTH_STATUS } = useAuth();
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [errorMessageLogin, setErrorMessageLogin] = useState('');

  const [isResetPassword, setIsResetPassword] = useState(false);

  const refPassword = useRef(null);


  const handleEmailChange = event => {
    const val = event.target.value;

    if (isEmail(val)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
    setEmail(val.trim());
  }

  const handlePasswordChange = event => {
    const val = event.target.value;

    if (isValidPassword(val)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
    setPassword(val.trim());
  }

  const handleLoginSubmit = async () => {
    setErrorMessageLogin('');

    if (isResetPassword) {
      if (isEmailValid) {
        try {
          setIsLoginLoading(true);
          await forgotPassword(email);
        } catch (e) {
          setIsLoginLoading(false);
          setErrorMessageLogin(e.message);
        }
      } else {
        setIsEmailDirty(true);
        setErrorMessageLogin("Incorrect email address.");
      }
      return;
    }

    if (isEmailValid && isPasswordValid) {
      try {
        setIsLoginLoading(true);
        await signIn(email, password);
      } catch (e) {
        setIsLoginLoading(false);
        setErrorMessageLogin(e.message);
      }
    } else {
      setIsEmailDirty(true);
      setIsPasswordDirty(true);
      setErrorMessageLogin("Incorrect username or password.");
    }
  };

  const onEmailKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isResetPassword) {
        handleLoginSubmit();
      } else {
        refPassword.current.focus();
      }
    }
  };

  const onPasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLoginSubmit();
    }
  };

  const handleResetPassword = () => {
    setIsResetPassword(true);
    setErrorMessageLogin('');
  };

  const handleBackToLogin = () => {
    setIsResetPassword(false);
    setErrorMessageLogin('');
  };

  const getHeaderMessage = () => {
    if (authStatus === AUTH_STATUS.NEW_PASSWORD_REQUIRED || authStatus === AUTH_STATUS.PASSWORD_RESET_REQUIRED) {
      return 'Please create a new password.';
    } else if (isResetPassword) {
      return 'Enter your registered email to reset the password.';
    }
    return (<span>Please login to <span className='font-semibold'>ODIN</span> to start your day.</span>);
  };

  return (
    <div className='bg-white'>
      <div className='max-w-4xl mx-auto text-center'>
        <div className='bg-white pt-[16vh] m-2 p-4'>
          <div className='mt-2 mb-4'>
            <div className='mx-auto p-3 w-48 h-24'>
              <img alt='Dara Switchboards' src='/org-logo.png' />
            </div>
          </div>
          <Typography variant='subtitle1' className='text-gray-500'>
            {getHeaderMessage()}
          </Typography>
          <div className='relative'>
            <Typography variant='subtitle1' className='absolute text-warn-main w-full top-3'>{errorMessageLogin}</Typography>
          </div>
          <Box
            className='mt-6'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box className='max-w-lg w-full' sx={{ mt: 1 }}>
              {authStatus === AUTH_STATUS.NEW_PASSWORD_REQUIRED ?
                (<NewPasswordInput showError={setErrorMessageLogin} submit={completeNewPassword} />) :
                authStatus === AUTH_STATUS.PASSWORD_RESET_REQUIRED ?
                  (<NewPasswordInput showError={setErrorMessageLogin} submit={forgotPasswordSubmit} isReset email={email} />) :
                  (<div>
                    <TextField
                      onChange={(e) => handleEmailChange(e)}
                      onBlur={() => setIsEmailDirty(true)}
                      error={isEmailDirty && !isEmailValid}
                      onKeyDown={onEmailKeyPress}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                    {!isResetPassword && <TextField
                      onChange={(e) => handlePasswordChange(e)}
                      onBlur={() => setIsPasswordDirty(true)}
                      error={isPasswordDirty && !isPasswordValid}
                      onKeyDown={onPasswordKeyPress}
                      inputRef={refPassword}
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />}
                    {isResetPassword &&
                      <Typography variant='body2' className='text-secondary-main w-full py-2'>A password reset code will be sent to this email address.</Typography>
                    }
                    <LoadingButton
                      onClick={handleLoginSubmit}
                      type="submit"
                      fullWidth
                      loading={isLoginLoading}
                      size="large"
                      loadingPosition="start"
                      startIcon={isResetPassword ? <LockResetIcon /> : <LoginIcon />}
                      variant="contained"
                      sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                    >
                      {isResetPassword ? 'Reset Password' : 'Login'}
                    </LoadingButton>
                  </div>)}
              <Grid container>
                <Grid item xs my={2}>
                  {authStatus !== AUTH_STATUS.PASSWORD_RESET_REQUIRED && !isResetPassword && <div onClick={handleResetPassword} className='text-primary-main font-bold text-sm cursor-pointer'>
                    Reset Password
                  </div>}
                  {authStatus !== AUTH_STATUS.PASSWORD_RESET_REQUIRED && isResetPassword && <div onClick={handleBackToLogin} className='text-primary-main font-bold text-sm cursor-pointer'>
                    Back to Login
                  </div>}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
        <div className='p-3 pb-10'>
          <div className='text-gray-500 font-medium text-base'>
            Powered by
            <div className='inline-block w-32 pl-2'>
              <img alt='Lextricon' src='/lextricon-logo.svg' />
            </div></div>
        </div>
      </div>
    </div>
  );
};

export { ViewAuth };