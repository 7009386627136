import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton, Switch } from "@mui/material";
import { useNotification } from "../../context";


import {
  getNotifications,
  updateNotifications,
} from "../../connectors/bff-connector";
import { useNavigate } from "react-router-dom";
import { ServerNotificationItem } from "./ServerNotificationItem";

const NOTIFICATION_PANEL_LISTENER = "notification-panel";

const allNotifications = {
  notifications: []
};

const NotificationMenu = () => {
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const [notifications, setNotifications] = useState([]);
  const { registerListener } = useNotification();

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  const navigate = useNavigate();


  const updateView = useCallback(() => {
    if (showOnlyUnread) {
      setNotifications(allNotifications.notifications.filter(n => !n.read));
    } else {
      setNotifications([...allNotifications.notifications]);
    }
  }, [showOnlyUnread]);


  const handleMarkAllAsRead = () => {
    const filtered = allNotifications.notifications.filter(n => !n.read);
    const notifications = [];
    filtered.forEach(n => {
      notifications.push({
        id: n.id,
        read: true,
      });
      n.read = true;
    });
    updateNotifications({ notifications });

    setUnreadCount(0);
    updateView();
  };

  const markNotificationAsRead = useCallback((notification) => {
    const found = allNotifications.notifications.find(n => n.id === notification.id && !n.read);
    if (found) {
      const updatePayload = [{
        id: notification.id,
        read: true,
      }];
      updateNotifications({ notifications: updatePayload });
      found.read = true;
      setUnreadCount((count) => count - 1);
      updateView();
    }
  }, [updateView]);

  const handleNotificationClick = useCallback((notification) => {
    markNotificationAsRead(notification);
    navigate(`/${notification.callToAction}`);
    handleNotificationMenuClose();
  }, [markNotificationAsRead, navigate]);


  useEffect(() => {
    const handleNotificationReceived = (notificationsReceived, eventName) => {

      if (eventName === "MARK_AS_READ") {
        handleNotificationClick(notificationsReceived)
      } else {
        allNotifications.notifications.unshift(notificationsReceived);
        updateView();
        setUnreadCount((count) => count + 1);
      }
    };
    registerListener(NOTIFICATION_PANEL_LISTENER, handleNotificationReceived);
    return () => registerListener(NOTIFICATION_PANEL_LISTENER, null);
  }, [handleNotificationClick, registerListener, updateView]);

  const loadNotifications = useCallback(async () => {
    try {
      const notificationsAvailable = await getNotifications({
        limit: 100,
        sortKey: "created_at",
        sortOrder: "desc",
      });
      const unreadNotifications = notificationsAvailable.filter(
        (notification) => !notification.read
      ).length;

      allNotifications.notifications = notificationsAvailable;
      setNotifications(notificationsAvailable);
      setUnreadCount(unreadNotifications);
    } catch (err) { }
  }, []);

  useEffect(() => {
    updateView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlyUnread]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const handleNotificationMenuClose = () => {
    setAnchorElNotification(null);
  };

  const handleChangeReadOnly = (event) => {
    setShowOnlyUnread(event.target.checked);
  };

  return (
    <Box>
      <IconButton size="large"
        onClick={(e) => {
          setAnchorElNotification(e.currentTarget);
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: "40px" }}
        id="menu-appbar"
        anchorEl={anchorElNotification}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorElNotification}
        onClose={handleNotificationMenuClose}
      >
        <div className="w-[540px] max-w-[540px] h-[670px] max-h-[670px]">
          <div className="py-4 mx-5 flex items-center justify-between border-b border-b-gray-300 border-solid">
            <div className="text-2xl font-semibold text-gray-800">Notifications</div>
            <div className="flex items-center">
              <div className="text-sm font-medium text-gray-600 mr-1">Show only unread</div>
              <div>
                <Switch checked={showOnlyUnread} onChange={handleChangeReadOnly} size="small" />
              </div>
            </div>
          </div>
          <div className="flex justify-between px-6 py-3">
            <div className="text-sm font-semibold text-gray-600">Unread ({unreadCount})</div>
            <div className="text-xs font-semibold text-gray-500 hover:underline hover:cursor-pointer hover:text-gray-400" onClick={handleMarkAllAsRead}>Mark all as read</div>
          </div>
          {(notifications.length === 0 || (showOnlyUnread && unreadCount === 0)) && (
            <div className="text-center text-sm font-medium text-gray-500 p-5 mt-40">
              {notifications.length === 0 && <div>You don't have any notification yet!<br /> Once received, everything will be displayed here.</div>}
              {(notifications.length > 0 && showOnlyUnread && unreadCount === 0) && <div>Congratulations!<br /> You have read all of your notifications</div>}
            </div>
          )}
          {notifications.map((notification) => (
            <MenuItem onClick={(e) => e.stopPropagation()} key={notification.id} sx={{ padding: 0 }}>
              <ServerNotificationItem onClick={handleNotificationClick} onMarkAsRead={markNotificationAsRead} notification={notification} />
            </MenuItem>
          ))}
        </div>
      </Menu>
    </Box>
  );
};

export { NotificationMenu };
