const Overview = ({ summary = {} }) => {
    return (
        <div className='flex flex-wrap gap-3 justify-start '>
            <div className="py-6 bg-white rounded-lg dark:bg-gray-800 w-full" id="stats" role="tabpanel"
                aria-labelledby="stats-tab">
                <dl className="grid  grid-cols-6 gap-8 py-4 mx-auto text-gray-900 bg-[#03396c] rounded-lg text-white">
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-white">{summary?.activeUsers?.totalUsers}</dt>
                        <dd className="text-gray-500 dark:text-gray-400 text-white">Employees</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-white">{summary?.workedHours?.find(wh => wh.billable)?.workedHours || 0} <span className="font-light text-lg">hrs</span></dt>
                        <dd className="text-gray-500 dark:text-gray-400 text-white">Billable Hours</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-white">{summary?.workedHours?.find(wh => !wh.billable)?.workedHours || 0}  <span className="font-light text-lg">hrs</span></dt>
                        <dd className="text-gray-500 dark:text-gray-400 text-white">Non Billable Hours</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-white">{summary?.invoices?.totalAmount || 0}  <span className="font-light text-lg">AUD</span></dt>
                        <dd className="text-gray-500 dark:text-gray-400 text-white">Total Invoices</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-white">{summary?.invoices?.paidAmount || 0} <span className="font-light text-lg">AUD</span></dt>
                        <dd className="text-gray-500 dark:text-gray-400 text-white">Paid Invoices</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-white">{summary?.invoices?.pendingAmount || 0} <span className="font-light text-lg">AUD</span></dt>
                        <dd className="text-gray-500 dark:text-gray-400 text-white">Pending Invoices</dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

export { Overview };
