import {
    Box,
    Tooltip,
    Typography,
} from "@mui/material";
import { useParams } from 'react-router';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { AttachmentViewer, ClickToCopy, CommentSection, DatePickerLabel, EditableLabel, MainLoader, MinutesPickerLabel, PriorityLevel, ProgressIndicator, RichTextEditor, TaskStatus, UsersAutocompleteLabel } from "../components/common";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import 'react-quill/dist/quill.snow.css';
import { checkActiveTask, createComment, deleteComment, editComment, getTask, pauseTask, startTask, updateTask } from "../connectors/bff-connector";
import { useAuth, useNotification } from "../context";
import ROLES from "../constants/roles";
import { getMentionsFromHtml, getMinutesInHoursAndMins } from "../utils";
import { AttachmentUploaderDialog, ConfirmationDialog, ManageTaskTimeDialog } from "../components/dialogs";
import EditRoadIcon from '@mui/icons-material/EditRoad';

const ViewTaskPage = () => {
    const { projectId, taskCode } = useParams();

    const navigate = useNavigate();
    const { user } = useAuth();
    const readOnly = user.roleId > ROLES.EMPLOYEE;  // anyone can edi for now
    const allowModifyEntries = user.roleId <= ROLES.ADMIN; // admin and super admin

    const { showNotificationError } = useNotification();

    const [loading, setLoading] = useState(true);

    const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
    const [taskTimeDialogOpen, setTaskTimeDialogOpen] = useState(false);
    const [task, setTask] = useState({});
    const [starting, setStarting] = useState(false);
    const [activeTasks, setActiveTasks] = useState(null);

    const goBack = () => {
        navigate(-1);
    };

    const loadTask = async () => {
        try {
            const res = await getTask({ projectId, taskCode });
            if (res) {
                setTask(res);
                setLoading(false);
            } else {
                showNotificationError("This task is no longer available");
                navigate('/tasks');
            }
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
    };

    useEffect(() => {
        loadTask();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const update = async (payload) => {
        try {
            const res = await updateTask({ id: task.id, ...payload });
            return res;
        } catch (err) {
            console.error(err);
            showNotificationError("Something happened when updating the project. Please try again.");
        }
    };

    const handleAddAttachment = async (newAttachments) => {
        const attachments = newAttachments.map(a => ({
            id: a.id,
            name: a.file.name,
            type: a.extension,
            size: a.file.size,
            url: URL.createObjectURL(a.file)
        }));
        const addedAttachments = attachments.map(a => a.id);
        const allAttachments = [...task.attachments, ...attachments];
        await update({ addedAttachments });
        setTask({ ...task, attachments: allAttachments });
        return true;
    };

    const handleRemoveAttachment = (attachment) => {
        const attachments = task.attachments.filter(a => a.id !== attachment.id);
        const removedAttachments = [attachment.id];
        update({ removedAttachments });
        setTask({ ...task, attachments });
    };

    const confirmStartTask = async () => {
        setActiveTasks(null);
        try {
            await startTask({ taskId: task.id });
            setTask({ ...task, isActive: true });
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to start the task! Please try again.");
        }
        setStarting(false);
    };

    const handleStart = async () => {
        setStarting(true);
        try {
            const activeTasks = await checkActiveTask();
            if (activeTasks && activeTasks.length > 0) {
                setActiveTasks(activeTasks);
                return;
            }
            await confirmStartTask();
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to start the task! Please try again.");
        }
        setStarting(false);
    };

    const handlePause = async () => {
        setStarting(true);
        try {
            await pauseTask({ taskId: task.id });
            setTask({ ...task, isActive: false });
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to pause the task! Please try again.");
        }
        setStarting(false);
    };

    const handleDescriptionChange = async (description) => {
        const mentions = getMentionsFromHtml(description);
        await update({ description, mentions });
        setTask({ ...task, description });
        return true;
    };

    const handleStatusChange = (status) => {
        update({ statusCode: status });
        setTask({ ...task, status });
    };

    const handlePriorityChange = (priority) => {
        update({ priorityCode: priority });
        setTask({ ...task, priority });
    };

    const handleNameChange = (name) => {
        update({ name });
        setTask({ ...task, name });
    };

    const handleAssigneeChange = (user) => {
        update({ assignedTo: user.id });
        setTask({ ...task, assigneeName: user.name, assigneeId: user.id });
    };

    const handleOwnerChange = (user) => {
        update({ owner: user.id });
        setTask({ ...task, ownerName: user.name, ownerId: user.id });
    };

    const handleEndDateChange = (dateString) => {
        update({ endDt: dateString });
        setTask({ ...task, endDate: dateString });
    };

    const handleAllocatedTimeChange = (hoursAllocated) => {
        update({ hoursAllocated });
        setTask({ ...task, allocatedTime: hoursAllocated });
    };

    const onCreateNewComment = async (comment) => {
        try {
            const mentions = getMentionsFromHtml(comment.comment);
            const payload = {
                categoryCode: "tsk",
                typeCode: "dir",
                description: comment.comment,
                parentId: task.id,
                mentions
            };
            const res = await createComment(payload);
            const comments = [...task.comments];
            comment.id = res.id;
            comments.unshift(comment);
            setTask({ ...task, comments });
            return true;
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to add the comment. Please try again.");
        }
    };

    const onEditComment = async (content, comment) => {
        try {
            const mentions = getMentionsFromHtml(content);
            await editComment({
                id: comment.id,
                comment: content,
                mentions
            });

            const comments = [];
            for (let i = 0; i < task.comments.length; i++) {
                if (task.comments[i].id === comment.id) {
                    task.comments[i].dateTime = new Date().toISOString();
                }
                comments.push(task.comments[i]);
            }
            setTask({ ...task, comments });
            return true;
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to edit the comment. Please try again.");
        }
    };

    const onDeleteComment = async (comment) => {
        try {
            await deleteComment({ id: comment.id });
            const comments = task.comments.filter(c => c.id !== comment.id);
            setTask({ ...task, comments });
        } catch (err) {
            console.error(err);
            showNotificationError("Failed to delete the comment. Please try again.");
        }
    };

    const handleChangeRecordClick = () => {
        setTaskTimeDialogOpen(true);
    };

    const handleTaskTimeDialogClose = (refresh) => {
        setTaskTimeDialogOpen(false);
        if (refresh) {
            loadTask();
        }
    };

    return (
        <div>
            <div className="p-2 bg-white">
                <Box className="flex items-center">
                    <IconButton aria-label="back-button" onClick={goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 1 }}>
                        <Link to="/tasks">
                            <Typography variant="body1" className="hover:underline">Tasks</Typography>
                        </Link>
                        <Box className="flex items-center group">
                            <Link>
                                <Typography variant="subtitle1" className="group-hover:underline">{taskCode}</Typography>
                            </Link>
                            <div className="invisible ml-1 group-hover:visible">
                                <ClickToCopy caption="Copy link to task" value={window.location.href}>
                                    <IconButton aria-label="copy-content">
                                        <LinkIcon fontSize="small" />
                                    </IconButton>
                                </ClickToCopy>
                            </div>
                        </Box>
                    </Breadcrumbs>
                </Box>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                <div className="flex bg-white border-solid border-t border-t-black border-opacity-20 min-h-screen">
                    <div className="p-6 2xl:px-10 grow border-solid border-r border-r-black border-opacity-20">
                        <div className='flex items-center justify-between my-0'>
                            <div className='w-full mr-6'>
                                <EditableLabel className="text-2xl font-bold text-gray-900" value={task.name} readOnly={readOnly} required onChange={handleNameChange} />
                            </div>
                            <div className="flex items-center">
                                {task.isActive && <div className="flex w-9 h-6 relative">
                                    <div className="absolute animate-ping bottom-0">
                                        <AccessAlarmIcon fontSize="medium" color="success" />
                                    </div>
                                    <div className="absolute bottom-0">
                                        <AccessAlarmIcon fontSize="medium" color="success" />
                                    </div>
                                </div>}
                                {task.assigneeId === user.userId && <div className='w-24'>
                                    {task.isActive ? (
                                        <LoadingButton
                                            onClick={handlePause}
                                            type="button"
                                            fullWidth
                                            size="medium"
                                            color="error"
                                            loading={starting}
                                            loadingPosition="start"
                                            startIcon={<AlarmOffIcon />}
                                            variant="outlined"
                                            sx={{ textTransform: 'none' }}
                                        >
                                            Pause
                                        </LoadingButton>
                                    ) : (
                                        <LoadingButton
                                            onClick={handleStart}
                                            type="button"
                                            fullWidth
                                            size="medium"
                                            loading={starting}
                                            loadingPosition="start"
                                            startIcon={<AlarmOnIcon />}
                                            variant="contained"
                                            sx={{ textTransform: 'none' }}
                                        >
                                            Start
                                        </LoadingButton>
                                    )}
                                </div>}
                            </div>

                        </div>
                        <div className="flex py-3">
                            <div className="basis-28 text-base font-medium text-gray-500">Service</div>
                            <div className="text-base font-semibold text-gray-900">{task.serviceName}</div>
                        </div>
                        <div className="py-3">
                            <div className="basis-28 text-base font-medium text-gray-500">Task Description</div>
                            <RichTextEditor className="my-4" placeholder="Type your project description here..." showButtons readOnly={readOnly} value={task.description} onSubmit={handleDescriptionChange} mentionKey="all-users" />
                        </div>
                        <div className='flex items-center justify-between my-3'>
                            <div className='w-52'>
                                <div className="basis-28 text-base font-medium text-gray-500">Attachments</div>
                            </div>
                            {!readOnly && <div className='w-44'>
                                <LoadingButton
                                    onClick={() => setAttachmentDialogOpen(true)}
                                    type="button"
                                    fullWidth
                                    size="medium"
                                    loadingPosition="start"
                                    startIcon={<AddIcon />}
                                    variant="outlined"
                                    sx={{ textTransform: 'none' }}
                                >
                                    Add Attachment
                                </LoadingButton>
                            </div>}
                        </div>
                        <div className="py-3">
                            <AttachmentViewer attachments={task.attachments} readOnly={readOnly} onRemove={handleRemoveAttachment} />
                        </div>
                        <div className="py-3 mb-12">
                            <div className="basis-28 text-base font-medium text-gray-500">Discussions</div>
                            <CommentSection comments={task.comments} onCreateNew={onCreateNewComment} onDelete={onDeleteComment} onEdit={onEditComment} />
                        </div>
                    </div>
                    <div className="min-w-[384px] 2xl:min-w-[480px] p-6 2xl:px-10 bg-[#F9FDFF]">
                        <div className="pb-3">
                            <div className="text-gray-600 font-semibold text-lg">Task Overview</div>
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px]">
                                <Typography className="text-gray-500" variant="subtitle1">Owner</Typography>
                            </div>
                            <UsersAutocompleteLabel userName={task.ownerName} userId={task.ownerId} readOnly={readOnly} onChange={handleOwnerChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px]">
                                <Typography className="text-gray-500" variant="subtitle1">Status</Typography>
                            </div>
                            <TaskStatus status={task.status} readOnly={readOnly} onChange={handleStatusChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px]">
                                <Typography className="text-gray-500" variant="subtitle1">Priority</Typography>
                            </div>
                            <PriorityLevel priority={task.priority} readOnly={readOnly} onChange={handlePriorityChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px]">
                                <Typography className="text-gray-500" variant="subtitle1">Assignee</Typography>
                            </div>
                            <UsersAutocompleteLabel userName={task.assigneeName} type="task-users" projectId={projectId} serviceId={task.serviceId} userId={task.assigneeId} readOnly={readOnly} onChange={handleAssigneeChange} />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Time Allocated</Typography>
                            </div>
                            <MinutesPickerLabel onChange={handleAllocatedTimeChange} value={task.allocatedTime} readOnly={readOnly} label="Time Allocated" />
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Time Taken</Typography>
                            </div>
                            <div className="flex items-center gap-3">
                                <div>
                                    <Typography variant='subtitle1'>{getMinutesInHoursAndMins(task.timeTaken).readableValue}</Typography>
                                </div>
                                {task.timeTaken > 0 && <div>
                                    <Tooltip title='View entires' arrow className='group'>
                                        <IconButton size="medium" onClick={handleChangeRecordClick}>
                                            <EditRoadIcon className='group-hover:text-primary-main' fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                            </div>
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Burnout</Typography>
                            </div>
                            <div className="grow">
                                <ProgressIndicator progress={task.progress} />
                            </div>
                        </div>
                        <div className="flex h-16 items-center">
                            <div className="min-w-[120px] mr-1">
                                <Typography className="text-gray-500" variant="subtitle1">Deadline</Typography>
                            </div>
                            <DatePickerLabel label="Select deadline" readOnly={readOnly} onChange={handleEndDateChange} value={task.endDate} minDate={new Date().toString()} />
                        </div>
                    </div>
                </div>)}
            {attachmentDialogOpen && <AttachmentUploaderDialog onClose={() => setAttachmentDialogOpen(false)} onSubmit={handleAddAttachment} header="Add Task Attachments" source="tasks" categoryCode="tsk" />}
            {taskTimeDialogOpen && <ManageTaskTimeDialog onClose={handleTaskTimeDialogClose} task={task} allowModifyEntries={allowModifyEntries} taskCode={taskCode} />}
            {activeTasks && activeTasks.length > 0 && (
                <ConfirmationDialog
                    header={"You already have an active task!"}
                    description={<div>
                        <div>Following task is currently active. It will be stopped when you start this task.</div>
                        <div className="text-base py-2">
                            <span className="font-bold">{activeTasks[0].code} </span>
                            <span>{activeTasks[0].name}</span>
                        </div>
                    </div>}
                    handleClose={() => { setActiveTasks(null); setStarting(false) }}
                    handleSubmit={() => confirmStartTask()}
                    cancelText={"Cancel"}
                    color={'success'}
                    confirmationText={'Start Task'}
                />)}
        </div>
    );
};

export { ViewTaskPage };
