import { useRef, useState } from "react";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'
import { useEffect } from 'react';
import "quill-mention";
import { renderToString } from 'react-dom/server';
import { getUsers } from "../../connectors/bff-connector";
import { LoadingButton } from "@mui/lab";


const cachedData = {
    users: null
};

const suggestPeople = async (searchTerm) => {

    if (cachedData.users === null) {
        const res = await getUsers({
            "filter": { by: 'name', order: 'asc' },
            "size": 1000000,
            "page": 1,
            "search": ""
        }, 'all-users');
        const users = (res.users || []);
        cachedData.users = users.map(u => ({
            id: u.id,
            value: u.name,
            searchKey: u.name.toLowerCase()
        }));
    }
    const lowerCaseSearch = searchTerm?.toLowerCase() || '';

    return cachedData.users.filter(person => person.searchKey.includes(lowerCaseSearch));
}

const allUsersMentionModule = {
    mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: async (searchTerm, renderList) => {
            const matchedPeople = await suggestPeople(searchTerm);
            renderList(matchedPeople);
        },
        renderItem: (item, searchTerm) => {
            return renderToString(<div className="w-full p-2 text-base font-medium bg-white hover:bg-gray-100 cursor-pointer relative z-50">
                {item.value}
            </div>);
        }
    }
};

const getModules = (mentionKey) => {
    const modules = {};
    if (mentionKey === 'all-users') {
        modules.mention = allUsersMentionModule.mention;
    }
    return modules;
};

const RichTextEditor = ({ className = '', placeholder = "Enter your text here..", value = "", onChange = () => { }, onBlur = () => { }, hideToolbarOnBlur = false, readOnly = false, noBorderInReadMode = false, openInEditMode = false, minHeight = 100, maxHeight = 0, mentionKey = '', showButtons = false, onSubmit = () => { }, onCancel = () => { } }) => {

    const [editing, setEditing] = useState(openInEditMode);
    const [saving, setSaving] = useState(false);
    const [content, setContent] = useState(value);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const ref = useRef(null);

    const modules = getModules(mentionKey);

    useEffect(() => {
        const element = ref?.current?.getElementsByClassName('ql-editor');
        let newMinHeight = editing ? minHeight - 43.37 : minHeight - 2;
        let newMaxHeight = editing ? maxHeight - 43.37 : maxHeight - 2;
        const quill = element[0];
        if (quill) {
            const container = ref?.current?.getElementsByClassName('ql-container')[0];
            const toolbar = ref?.current?.getElementsByClassName('ql-toolbar')[0];

            if (newMinHeight > 0) {
                quill.style.minHeight = `${newMinHeight}px`;
            }
            if (newMaxHeight > 0) {
                quill.style.maxHeight = `${newMaxHeight}px`;
            }

            if (editing) {
                container.style.borderTop = '10px';
                toolbar.style.display = 'block';
                toolbar.style.padding = '8px';
                container.style.borderLeft = '1px solid #ccc';
                container.style.borderRight = '1px solid #ccc';
                container.style.borderBottom = '1px solid #ccc';
                quill.style.padding = '12px 15px';
            } else {
                if (noBorderInReadMode) {
                    container.style.borderLeft = 'none';
                    container.style.borderRight = 'none';
                    container.style.borderBottom = 'none';
                    quill.style.padding = '8px 0px';
                } else {
                    container.style.borderTop = '1px solid #ccc';
                }
                toolbar.style.display = 'none';
                toolbar.style.padding = '0px';
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing, maxHeight, minHeight, ref]);

    useEffect(() => {
        if (openInEditMode) {
            setEditing(true);
        }
    }, [openInEditMode]);

    const handleFocus = () => {
        setEditing(!readOnly);
    };

    const handleBlur = () => {
        if (hideToolbarOnBlur) {
            setEditing(false);
        }
        onBlur();
    };

    const handleChange = (value) => {
        const dom = document.createElement('div');
        dom.innerHTML = value;
        setSaveDisabled(dom.textContent.trim().length === 0);
        setContent(value);
        onChange(value);
    };

    const handleSave = async () => {
        setSaving(true);
        const status = await onSubmit(content);
        if (status) {
            setEditing(false);
        }
        setSaving(false);
    };

    const handleCancel = () => {
        setEditing(false);
        setContent(value);
        onCancel();
    };

    return (
        <div className={className} ref={ref} >
            <div onClick={handleFocus}>
                <ReactQuill key='rich-text-editor' readOnly={!editing} theme="snow" modules={modules} placeholder={readOnly ? "" : placeholder} value={content} onChange={handleChange} onBlur={handleBlur} />
            </div>
            {(editing && showButtons) && <div className="w-full flex">
                <div className="w-16 pt-3 pb-1">
                    <LoadingButton
                        onClick={handleSave}
                        fullWidth
                        loading={saving}
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        disabled={saveDisabled}
                    >
                        Save
                    </LoadingButton>
                </div>
                <div className="w-20 pt-3 pb-1 ml-2">
                    <LoadingButton
                        onClick={handleCancel}
                        fullWidth
                        loading={false}
                        size="medium"
                        variant="text"
                        sx={{ textTransform: 'none' }}
                    >
                        Cancel
                    </LoadingButton>
                </div>
            </div>}
        </div >
    );
};

export { RichTextEditor };
