import * as React from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { UsersAutocomplete } from '../common';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth, useNotification } from "../../context";
import { useEffect } from 'react';
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import isEmail from 'validator/lib/isEmail';
import ROLES from '../../constants/roles';
import { addUser, searchDesignation, searchTeam, updateUser } from '../../connectors/bff-connector';
import { useCallback } from 'react';
import ConfirmationDialog from './ConfirmationDialog';


const getFilteredRoles = (maxRoleId) => {
    const roles = [];
    Object.keys(ROLES).forEach(key => {
        const roleName = key.toLowerCase().split("_").map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(" ");
        const roleId = ROLES[key];
        if (roleId >= maxRoleId) {
            roles.push({
                id: roleId,
                name: roleName
            });
        }
    });
    return roles;
};

const ManageUserDialog = ({ isCreate = false, handleClose = () => { }, selectedUser = null }) => {

    const [firstName, setFirstName] = useState(isCreate ? '' : selectedUser.firstName);
    const [isFirstNameValid, setIsFirstNameValid] = useState(!isCreate);
    const [isFirstNameDirty, setIsFirstNameDirty] = useState(false);

    const [lastName, setLastName] = useState(isCreate ? '' : selectedUser.lastName);
    const [isLastNameValid, setIsLastNameValid] = useState(!isCreate);
    const [isLastNameDirty, setIsLastNameDirty] = useState(false);

    const [email, setEmail] = useState(isCreate ? '' : selectedUser.email);
    const [isEmailValid, setIsEmailValid] = useState(!isCreate);
    const [isEmailDirty, setIsEmailDirty] = useState(false);

    const [designations, setDesignations] = useState([]);
    const [selectedDesignationId, setSelectedDesignationId] = useState('');
    const [isDesignationDirty, setIsDesignationDirty] = useState(false);

    const [teams, setTeams] = useState([]);
    const [selectedTeamId, setSelectedTeamId] = useState('');

    const [selectedReportToUserId, setSelectedReportToUserId] = useState(isCreate ? 0 : selectedUser.managerId);

    const [selectedRoleId, setSelectedRoleId] = useState(isCreate ? '' : selectedUser.accessLevelId);
    const [isAccessLevelDirty, setIsAccessLevelDirty] = useState(false);

    const [saving, setSaving] = useState(false);
    const [getConfirmation, setGetConfirmation] = useState(false);
    const [deactivating, setDeactivating] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { showNotificationError, showNotificationSuccess } = useNotification();

    const { user } = useAuth();

    const loadDesignations = useCallback(async () => {
        try {
            const res = await searchDesignation({
                "active": true,
                "limit": 10000,
                "offset": 0,
            });
            setDesignations(res);
            if (!isCreate && res.find(d => d.id === selectedUser.designationId)) {
                setSelectedDesignationId(selectedUser.designationId);
            }
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
    }, [isCreate, selectedUser?.designationId]);

    const loadTeams = useCallback(async () => {
        try {
            const res = await searchTeam({
                "limit": 10000,
                "offset": 0,
            });
            setTeams(res);
            if (!isCreate && res.find(t => t.id === selectedUser.team?.id)) {
                setSelectedTeamId(selectedUser.team?.id);
            }
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
    }, [isCreate, selectedUser?.team?.id]);

    useEffect(() => {
        loadDesignations();
        loadTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {

        if (!isFirstNameValid) {
            setErrorMessage("Please type the fist name.");
            setIsFirstNameDirty(true);
            return;
        }
        if (!isLastNameValid) {
            setErrorMessage("Please type the last name.");
            setIsLastNameDirty(true);
            return;
        }
        if (!(selectedDesignationId > 0)) {
            setErrorMessage("Please select the designation.");
            setIsDesignationDirty(true);
            return;
        }
        if (!(selectedRoleId > 0)) {
            setErrorMessage("Please select the access level.");
            setIsAccessLevelDirty(true);
            return;
        }
        if (!isEmailValid) {
            setErrorMessage("Please type a valid email");
            setIsEmailDirty(true);
            return;
        }

        const newUser = {
            email: process.env.REACT_APP_STAGE === 'prod' ? email : 'lextricon@gmail.com',
            username: email,
            roleId: selectedRoleId,
            firstName: firstName,
            lastName: lastName,
            designationId: selectedDesignationId,
            managerId: selectedReportToUserId || null,
            teamId: selectedTeamId || null
        };

        setErrorMessage('');
        setSaving(true);

        try {
            let res = {};
            if (isCreate) {
                res = await addUser(newUser);
                showNotificationSuccess("New user created successfully!");
            } else {
                res = await updateUser({ id: selectedUser.id, updated: newUser });
                showNotificationSuccess("User updated successfully!");
            }
            handleClose({}, res);
        } catch (err) {
            showNotificationError("Failed to create the user. Please try again.");
            setSaving(false);
        }
    };

    const handleFirstNameChange = event => {
        const val = event.target.value?.trim();

        if (val) {
            setIsFirstNameValid(true);
        } else {
            setIsFirstNameValid(false);
        }
        setFirstName(val);
    };

    const handleLastNameChange = event => {
        const val = event.target.value?.trim();

        if (val) {
            setIsLastNameValid(true);
        } else {
            setIsLastNameValid(false);
        }
        setLastName(val);
    };

    const handleEmailChange = event => {
        const val = event.target.value;

        if (isEmail(val)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
        setEmail(val.trim());
    };

    const handleReportToChange = (user) => {
        setSelectedReportToUserId(user?.id || 0);
    };

    const handleTeamChange = event => {
        setSelectedTeamId(event.target.value);
    };

    const handleDesignationChange = event => {
        setSelectedDesignationId(event.target.value);
    };

    const handleAccessLevelChange = event => {
        setSelectedRoleId(event.target.value);
    };

    const handleDeactivate = () => {
        setGetConfirmation(true)
    };

    const deactivate = () => {
        setGetConfirmation(false);
        setDeactivating(true);
        onDeactivate(selectedUser.id);
    };

    const closeConfirmation = () => {
        setGetConfirmation(false)
    };

    const onDeactivate = async (id) => {
        try {
            await updateUser({
                id: id,
                updated: {
                    isActive: !selectedUser.isActive
                }
            });
            showNotificationSuccess(`User ${selectedUser?.isActive ? `de` : ''}activated successfully!`);
        } catch (err) {
            showNotificationError(`Failed to ${selectedUser?.isActive ? `de` : ''}activate the user! Please try again.`);
        }
        setDeactivating(false);
        handleClose(null, { id });
    };

    return (
        <div>
            <Dialog
                open
                fullScreen={fullScreen}
                scroll='paper'
                fullWidth
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle>{isCreate ? 'Create' : 'Manage'} User</DialogTitle>
                <DialogContent dividers>
                    <div className='sm:px-8'>
                        <div className='relative text-center'>
                            <Typography variant='subtitle1' className='absolute text-warn-main w-full -top-3'>{errorMessage}</Typography>
                        </div>
                        <TextField
                            onChange={(e) => handleFirstNameChange(e)}
                            onBlur={() => setIsFirstNameDirty(true)}
                            error={isFirstNameDirty && !isFirstNameValid}
                            value={firstName}
                            margin="normal"
                            required
                            fullWidth
                            label="First Name"
                            name="firstName"
                        />
                        <TextField
                            onChange={(e) => handleLastNameChange(e)}
                            onBlur={() => setIsLastNameDirty(true)}
                            error={isLastNameDirty && !isLastNameValid}
                            value={lastName}
                            margin="normal"
                            required
                            fullWidth
                            label="Last Name"
                            name="lastName"
                        />
                        <div className='flex'>
                            <div className='basis-1/2'>
                                <FormControl fullWidth margin='normal' required>
                                    <InputLabel>Designation</InputLabel>
                                    <Select
                                        labelId="select-designation"
                                        value={selectedDesignationId}
                                        label="Designation"
                                        onBlur={() => setIsDesignationDirty(true)}
                                        onChange={handleDesignationChange}
                                        error={isDesignationDirty && !selectedDesignationId > 0}
                                    >
                                        {designations.length === 0 && (
                                            <MenuItem value="">
                                                <em>Loading...</em>
                                            </MenuItem>
                                        )}
                                        {designations.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='w-6'></div>
                            <div className='basis-1/2'>
                                <FormControl fullWidth margin='normal' required>
                                    <InputLabel>Access Level</InputLabel>
                                    <Select
                                        labelId="select-access-level"
                                        value={selectedRoleId}
                                        label="Access Level"
                                        onBlur={() => setIsAccessLevelDirty(true)}
                                        onChange={handleAccessLevelChange}
                                        error={isAccessLevelDirty && !selectedRoleId > 0}
                                    >
                                        {getFilteredRoles(user.roleId).map(role => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <FormControl fullWidth margin='normal'>
                            <InputLabel>Team</InputLabel>
                            <Select
                                labelId="select-team"
                                value={selectedTeamId}
                                label="Team"
                                onChange={handleTeamChange}
                            >
                                {teams.length === 0 && (
                                    <MenuItem value="">
                                        <em>Loading...</em>
                                    </MenuItem>
                                )}
                                {teams.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <UsersAutocomplete label="Report To" onChange={handleReportToChange} userId={selectedReportToUserId} />
                        <TextField
                            onChange={(e) => handleEmailChange(e)}
                            onBlur={() => setIsEmailDirty(true)}
                            error={isEmailDirty && !isEmailValid}
                            value={email}
                            disabled={!isCreate}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full p-2 justify-between'>
                        <div>
                            {!isCreate && <LoadingButton
                                onClick={handleDeactivate}
                                type="submit"
                                fullWidth
                                loading={deactivating}
                                disabled={saving}
                                size="medium"
                                color={selectedUser?.isActive ? 'error' : 'success'}
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 140 }}
                            >
                                {selectedUser?.isActive ? `Deactivate` : 'Activate'} User
                            </LoadingButton>}
                        </div>
                        <div>
                            <LoadingButton
                                onClick={handleClose}
                                type="submit"
                                fullWidth
                                size="medium"
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 100 }}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                onClick={handleSubmit}
                                type="submit"
                                fullWidth
                                loading={saving}
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 120 }}
                            >
                                {isCreate ? 'Create' : 'Update'} User
                            </LoadingButton>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            {getConfirmation && (
                <ConfirmationDialog
                    header={"Are you sure?"}
                    description={`You are going to ${selectedUser?.isActive ? `deactivate` : 'activate'} ${selectedUser.name}`}
                    handleClose={closeConfirmation}
                    handleSubmit={deactivate}
                    cancelText={"Cancel"}
                    color={selectedUser?.isActive ? 'error' : 'success'}
                    confirmationText={selectedUser?.isActive ? `Deactivate` : 'Activate'}
                />)}
        </div>
    );
};

export { ManageUserDialog };
