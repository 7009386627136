import {
    Box,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useParams } from 'react-router';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';
import PaidIcon from '@mui/icons-material/Paid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { ClickToCopy, MainLoader, PdfViewer, TabPanel } from "../components/common";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import 'react-quill/dist/quill.snow.css';
import { deleteInvoice, getInvoice, submitInvoice, updateInvoice } from "../connectors/bff-connector";
import { useNotification } from "../context";
import { ConfirmationDialog } from "../components/dialogs";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import DeleteIcon from '@mui/icons-material/Delete';


const ViewInvoicePage = () => {
    const { invoiceCode } = useParams();
    const id = Number(invoiceCode);

    const navigate = useNavigate();
    const { showNotificationError, showNotificationSuccess } = useNotification();

    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [updating, setUpdating] = useState(false);

    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [sendConfirmationDialogOpen, setSendConfirmationDialogOpen] = useState(false);

    const [invoice, setInvoice] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);

    const goBack = () => {
        navigate(-1);
    };

    const loadInvoice = async () => {
        try {
            const res = await getInvoice({ id });
            setInvoice(res);
            setLoading(false);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
    };

    useEffect(() => {
        loadInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSend = async () => {
        setSendConfirmationDialogOpen(false);
        setSending(true);
        try {
            await submitInvoice({ id });
            showNotificationSuccess(`Invoice ${id} was sent to ${invoice.clientEmail} successfully!`);
            navigate(`/invoices`);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setSending(false);
    };

    const handleDelete = async () => {
        setDeleteConfirmationDialogOpen(false);
        setDeleting(true);
        try {
            await deleteInvoice({ id });
            showNotificationSuccess(`Invoice ${id} was deleted successfully!`);
            navigate(`/invoices`);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setDeleting(false);
    };

    const handleMarkAsPaidClick = async () => {
        setUpdating(true);
        try {
            await updateInvoice({ id, isPaid: !invoice.is_paid });
            invoice.is_paid = !invoice.is_paid; // no need to update the state since already rerendering
            showNotificationSuccess(`Invoice ${id} was marked as ${invoice.is_paid ? '' : 'un'}paid!`);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setUpdating(false);
    };

    const handleSendClick = () => {
        setSendConfirmationDialogOpen(true);
    };

    const handleDeleteClick = () => {
        setDeleteConfirmationDialogOpen(true);
    };

    const handleChangeTab = (event, newIndex) => {
        setTabIndex(newIndex);
    };


    return (
        <div>
            <div className="p-2 bg-white">
                <Box className="flex items-center">
                    <IconButton aria-label="back-button" onClick={goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 1 }}>
                        <Link to="/invoices">
                            <Typography variant="body1" className="hover:underline">Invoices</Typography>
                        </Link>
                        <Box className="flex items-center group">
                            <Link>
                                <Typography variant="subtitle1" className="group-hover:underline">{invoiceCode}</Typography>
                            </Link>
                            <div className="invisible ml-1 group-hover:visible">
                                <ClickToCopy caption="Copy link to this invoice" value={window.location.href}>
                                    <IconButton aria-label="copy-content">
                                        <LinkIcon fontSize="small" />
                                    </IconButton>
                                </ClickToCopy>
                            </div>
                        </Box>
                    </Breadcrumbs>
                </Box>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                <div className="bg-white border-solid border-t border-t-black border-opacity-20 min-h-screen">
                    <div className="w-full">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <div className="flex justify-between">
                                <Tabs value={tabIndex} onChange={handleChangeTab}>
                                    <Tab label="Summarized" />
                                    <Tab label="Detailed" />
                                </Tabs>
                                <div className="px-4 flex justify-end">
                                    <LoadingButton
                                        onClick={handleDeleteClick}
                                        fullWidth
                                        size="medium"
                                        disabled={sending}
                                        color="error"
                                        loading={deleting}
                                        variant="outlined"
                                        sx={{ textTransform: 'none', m: 1, width: 100 }}
                                        startIcon={<DeleteIcon />}
                                    >
                                        Delete
                                    </LoadingButton>

                                    <LoadingButton
                                        onClick={handleMarkAsPaidClick}
                                        fullWidth
                                        size="medium"
                                        disabled={sending}
                                        color={invoice.is_paid ? 'warning' : 'success'}
                                        loading={updating}
                                        variant="outlined"
                                        sx={{ textTransform: 'none', m: 1, width: 160 }}
                                        startIcon={<PaidIcon />}
                                    >
                                        {invoice.is_paid ? 'Mark as Unpaid' : 'Mark as Paid'}
                                    </LoadingButton>

                                    <LoadingButton
                                        onClick={handleSendClick}
                                        fullWidth
                                        loading={sending}
                                        size="medium"
                                        variant="contained"
                                        sx={{ textTransform: 'none', m: 1, width: 100 }}
                                        startIcon={<AttachEmailIcon />}
                                    >
                                        Send
                                    </LoadingButton>
                                </div>
                            </div>
                        </Box>
                        <TabPanel value={tabIndex} index={0} softHide>
                            <div className="flex flex-col items-center pt-4">
                                <PdfViewer url={invoice.url} scale={1.7} fileName={`Invoice_${invoice.id}_Summarized.pdf`} />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1} softHide>
                            <div className="flex flex-col items-center pt-4">
                                <PdfViewer url={invoice.urlDetailed} scale={1.7} fileName={`Invoice_${invoice.id}_Detailed.pdf`} />
                            </div>
                        </TabPanel>
                    </div>
                </div>)}
            {deleteConfirmationDialogOpen && (
                <ConfirmationDialog
                    header="Delete this invoice?"
                    description="Are you sure you want to delete this invoice ?"
                    handleClose={() => setDeleteConfirmationDialogOpen(false)}
                    handleSubmit={handleDelete}
                    cancelText={"Cancel"}
                    confirmationText={"Delete"}
                />)}
            {sendConfirmationDialogOpen && (
                <ConfirmationDialog
                    header="Submit this invoice?"
                    description={<span>Are you sure you want to send this invoice to <span className='font-bold text-gray-600'>{invoice.clientEmail}</span>?</span>}
                    handleClose={() => setSendConfirmationDialogOpen(false)}
                    handleSubmit={handleSend}
                    cancelText={"Cancel"}
                    confirmationText={"Send"}
                />)}
        </div>
    );
};

export { ViewInvoicePage };
