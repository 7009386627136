import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AuthProvider, NotificationProvider } from './context';
import { Amplify, Auth } from 'aws-amplify';
import { theme } from './theme';
import Routes from './Routes';

const config = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_URL,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};
Amplify.configure(config);

Auth.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotificationProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
