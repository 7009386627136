import React, { forwardRef, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ServerNotifications } from "../components/notifications";

const NOTIFICATION_VIEW_LISTENER = "notification-view";
const NOTIFICATION_PANEL_LISTENER = "notification-panel";

const NOTIFICATION_DEFAULT_TIMEOUT = 5000;

const NOTIFICATION_VIEW_TYPES = {
  DEFAULT: "default",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

// this is global for now.
const vertical = "top";
const horizontal = "right";


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ServerAlert = forwardRef(function Alert(props, ref) {
  return (<div ref={ref}>
    <ServerNotifications {...props} />
  </div>);
});

const NotificationView = ({ registerListener = () => { } }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(NOTIFICATION_VIEW_TYPES.SUCCESS);
  const [notification, setNotification] = useState({});

  useEffect(() => {
    registerListener(NOTIFICATION_VIEW_LISTENER, showNotification);
    return () => registerListener(NOTIFICATION_VIEW_LISTENER, null);
  }, [registerListener]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      // timeout, escapeKeyDown
      return;
    }

    setOpen(false);
  };

  const showNotification = (type, notification) => {
    setType(type);
    setNotification(notification);
    setOpen(false);
    setTimeout(() => setOpen(true), 100);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={notification.autoHideDuration}
      onClose={handleClose}
    >
      {type === "SERVER" ? (
        <ServerAlert key={notification.id} notification={notification} onClose={handleClose} />
      ) : (
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%", maxWidth: 360 }} >
          {notification.message}
        </Alert>
      )}
    </Snackbar >
  );
};

const notificationData = {
  listeners: {},
};

const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
  const registerListener = (name, listener) => {
    notificationData.listeners[name] = listener;
  };

  const showNotification = (type, notification) => {
    const notificationViewListener =
      notificationData.listeners[NOTIFICATION_VIEW_LISTENER];
    if (notificationViewListener) {
      notificationViewListener(type, notification);
    }
  };

  const showServerNotification = (notification) => {
    const notificationViewListener =
      notificationData.listeners[NOTIFICATION_VIEW_LISTENER];
    if (notificationViewListener) {
      notificationViewListener("SERVER", { ...notification, autoHideDuration: NOTIFICATION_DEFAULT_TIMEOUT });
    }
    const notificationPanelListener =
      notificationData.listeners[NOTIFICATION_PANEL_LISTENER];
    if (notificationPanelListener) {
      notificationPanelListener(notification);
    }
  };

  const notifyNotificationPanel = (notification, eventName) => {
    const notificationPanelListener =
      notificationData.listeners[NOTIFICATION_PANEL_LISTENER];
    if (notificationPanelListener) {
      notificationPanelListener(notification, eventName);
    }
  };

  // to stop auth hide, set autoHideDuration = null
  const showNotificationDefault = (
    message = "Notification",
    { autoHideDuration = NOTIFICATION_DEFAULT_TIMEOUT } = {}
  ) => {
    showNotification(NOTIFICATION_VIEW_TYPES.DEFAULT, {
      message,
      autoHideDuration,
    });
  };

  const showNotificationSuccess = (
    message = "Success",
    { autoHideDuration = NOTIFICATION_DEFAULT_TIMEOUT } = {}
  ) => {
    showNotification(NOTIFICATION_VIEW_TYPES.SUCCESS, {
      message,
      autoHideDuration,
    });
  };

  const showNotificationError = (
    message = "Error!",
    { autoHideDuration = NOTIFICATION_DEFAULT_TIMEOUT } = {}
  ) => {
    showNotification(NOTIFICATION_VIEW_TYPES.ERROR, {
      message,
      autoHideDuration,
    });
  };

  const showNotificationWarning = (
    message = "Warning!",
    { autoHideDuration = NOTIFICATION_DEFAULT_TIMEOUT } = {}
  ) => {
    showNotification(NOTIFICATION_VIEW_TYPES.WARNING, {
      message,
      autoHideDuration,
    });
  };

  const showNotificationInfo = (
    message = "Info",
    { autoHideDuration = NOTIFICATION_DEFAULT_TIMEOUT } = {}
  ) => {
    showNotification(NOTIFICATION_VIEW_TYPES.INFO, {
      message,
      autoHideDuration,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        registerListener,
        notifyNotificationPanel,
        showServerNotification,
        showNotificationDefault,
        showNotificationSuccess,
        showNotificationError,
        showNotificationWarning,
        showNotificationInfo,
      }}
    >
      <NotificationView registerListener={registerListener} />
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => React.useContext(NotificationContext);

export { NotificationProvider, useNotification, NotificationContext };
