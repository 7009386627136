import { VerticalBarChart } from "../common/VerticalBarChart";
import * as React from "react";


const HighestLoadedEmployees = ({ employees = [] }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Top 10 Loaded Employees',
            },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        const i = (context[0] || {}).dataIndex;
                        return `${employees[i].first_name} ${employees[i].last_name}`;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false, // Remove x-axis grid lines
                },
            },
            y: {
                grid: {
                    display: false, // Remove y-axis grid lines
                },
            },
        },
    };

    const data = {
        labels: employees.map(em => `${em.first_name}`),
        datasets: [
            {
                label: 'Hours',
                data: employees.map(em => Number(em.hours)),
                backgroundColor: '#eb6841',
            }
        ],
    };


    return (
        <div className="flex flex-col items-center justify-center block border border-gray-200 rounded-lg shadow p-2">
            <VerticalBarChart options={options} data={data} />
        </div>
    );
};

export { HighestLoadedEmployees };
