import { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';
import { EditableNumberLabel, MainLoader, UsersAutocomplete } from "../common";
import {
    getServices,
    createClient,
    getDesignations,
    updateClient,
    getClients,
} from "../../connectors/bff-connector";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ConfirmationDialog from "./ConfirmationDialog";
import { useNotification } from "../../context";

const CustomTextField = ({
    label,
    type = "text",
    required = true,
    collectUserInput,
    fieldName,
    initialValue = "",
    initialError = false,
}) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(initialError);
    const handleOnBlur = () => {
        setError(!value);
        collectUserInput(fieldName, value, !value);
    };
    const handleOnChange = (e) => {
        setValue(e.target.value);
    };
    return (
        <TextField
            value={value}
            label={label}
            variant="outlined"
            type={type}
            size="medium"
            fullWidth
            required={required}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            error={error}
        />
    );
};

// return a cloned object
const copyHourlyRates = (obj) => {
    const newObj = {};

    Object.keys(obj).forEach((key) => {
        const val = obj[key];
        newObj[key] = {};
        Object.entries(val).forEach(([k, v]) => {
            newObj[key][k] = v;
        });
    });

    return newObj;
};

const ManageClientDialog = ({
    onClose,
    updatePayload,
    isUpdate = false,
}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { showNotificationError, showNotificationSuccess } = useNotification();

    // states
    const [selectedServices, setSelectedServices] = useState([]);
    const [services, setServices] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [serviceDesignations, setServiceDesignations] = useState({});

    const [dataLoading, setDataLoading] = useState(true);
    const [addClientData, setAddClientData] = useState({});
    const [hourlyRates, setHourlyRates] = useState({});
    // at first we assume everything has errors because non is filled
    const [userInputError, setUserInputError] = useState({
        name: true,
        address: true,
        contactPerson: true,
        phone: true,
        email: true,
    });
    const [clientManager, setClientManager] = useState(null);
    const [values, setValue] = useState(null);

    const [saving, setSaving] = useState(false);
    const [getConfirmation, setGetConfirmation] = useState(false);
    const [deactivating, setDeactivating] = useState(false);

    const [showAddService, setShowAddService] = useState(false);

    const fillClientData = (clientData) => {
        if (clientData) {
            const {
                payload: { result },
            } = clientData;
            const client = result[0];

            const hourlyRates = {};
            const services = [];
            const serviceDesignations = {};

            // make the relevant data structure
            client.services?.forEach((service) => {
                const id = service.serviceId;
                const serviceName = service.serviceName;
                const designationId = service.designationId;
                const designationName = service.designationName;
                const rate = service.rate;

                const found = services.find((s) => s.id === id);

                if (found) {
                    serviceDesignations[id].push({
                        id: designationId,
                        name: designationName,
                    });
                    hourlyRates[id] = {
                        ...hourlyRates[id],
                        [designationId]: rate,
                    };
                } else {
                    services.push({
                        id,
                        name: serviceName,
                    });
                    serviceDesignations[id] = [
                        { id: designationId, name: designationName },
                    ];
                    hourlyRates[id] = {
                        [designationId]: rate,
                    };
                }
            });

            setServiceDesignations(serviceDesignations);
            setSelectedServices(services);
            setHourlyRates(copyHourlyRates(hourlyRates));
            setUserInputError({
                name: !client.name,
                address: !client.address,
                contactPerson: !client.contactPerson,
                phone: !client.phone,
                email: !client.email,
            });
            setClientManager(client.managerId);
            setValue({
                hourlyRates,
                managerId: client.managerId,
                clientId: client.id,
                ...client,
            });
        }
    };

    const fetchInitialData = useCallback(async () => {
        try {
            setDataLoading(true);
            const loadInitialData = [getServices({"active": true}), getDesignations({"active": true, "limit": 1000})];
            if (isUpdate) {
                // get client data
                loadInitialData.push(getClients({ clientId: updatePayload.clientId }));
            }
            const [services, designations, clientData] = await Promise.all(
                loadInitialData
            );
            setServices(services);
            setDesignations(designations);
            fillClientData(clientData);
        } catch (err) { }

        setDataLoading(false);
    }, [isUpdate, updatePayload?.clientId]);

    const createAClient = useCallback(
        async (payload) => {
            try {
                setSaving(true);
                const { client } = await createClient(payload);
                // add the client id coming from create so that we can update the client list
                onClose({ ...client, services: payload.services });
                showNotificationSuccess("New client created successfully!");
            } catch (err) {
                console.error(err);
                showNotificationError("Failed to create the client! Please try again.");
            }
            setSaving(false);
        },
        [onClose, showNotificationError, showNotificationSuccess]
    );

    const updateTheClient = useCallback(
        async (payload) => {
            try {
                setSaving(true);
                await updateClient(payload);
                onClose();
                showNotificationSuccess("Client updated successfully!");
            } catch (err) {
                console.error(err);
                showNotificationError("Failed to update the client! Please try again.");
            }
            setSaving(false);
        },
        [onClose, showNotificationError, showNotificationSuccess]
    );

    // effects
    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    const handleServiceSelect = (_, selectedServices) => {
        setSelectedServices((currentServices) => {
            if (currentServices.length < selectedServices.length) {
                return selectedServices;
            }
            return currentServices;
        });
        setShowAddService(false);
    };
    const handleDeleteSelectedService = (id) => {
        setSelectedServices((services) =>
            services.filter((service) => service.id !== id)
        );

        setHourlyRates((hourlyRates) => {
            delete hourlyRates[id];
            return { ...hourlyRates };
        });
    };

    const handleAddUpdateClient = () => {
        //TODO pass isUpdate flag instead of this
        if (isUpdate) {
            const updatedServices = [];
            const addedServices = [];
            const deletedServices = [];

            //first iterate over the newly added
            Object.entries(hourlyRates).forEach(([serviceId, designationRates]) => {
                const serviceRates = values.hourlyRates[serviceId];
                if (serviceRates) {
                    // now iterate through rates to find any updated services
                    Object.entries(designationRates).forEach(([designationId, rate]) => {
                        const designationRate = values.hourlyRates[serviceId][designationId];
                        if (designationRate >= 0) {
                            const newRate = hourlyRates[serviceId][designationId];
                            if (newRate !== designationRate) {
                                // updated rate
                                updatedServices.push({
                                    id: serviceId,
                                    designationId,
                                    rate: newRate,
                                });
                            }
                        } else {
                            // newly added designation
                            addedServices.push({ id: serviceId, designationId, rate });
                        }
                    });
                } else {
                    // newly added service
                    const newServiceRates = hourlyRates[serviceId];
                    Object.entries(newServiceRates).forEach(([designationId, rate]) => {
                        addedServices.push({ id: serviceId, designationId, rate });
                    });
                }
            });

            Object.entries(values.hourlyRates).forEach(
                ([serviceId, designationRates]) => {
                    const serviceRates = hourlyRates[serviceId];
                    if (serviceRates) {
                        Object.keys(designationRates).forEach((designationId) => {
                            const designationRate = hourlyRates[serviceId][designationId];
                            if (designationRate === undefined) {
                                deletedServices.push({ id: serviceId, designationId });
                            }
                        });
                    } else {
                        // deleted service
                        const deletedRates = values.hourlyRates[serviceId];
                        Object.keys(deletedRates).forEach((designationId) => {
                            deletedServices.push({ id: serviceId, designationId });
                        });
                    }
                }
            );
            const payload = {
                ...addClientData,
            };
            if (clientManager !== values.managerId) {
                payload.managerId = clientManager;
            }
            if (addedServices.length > 0) {
                payload.addedServices = addedServices;
            }
            if (deletedServices.length > 0) {
                payload.deletedServices = deletedServices;
            }
            if (updatedServices.length > 0) {
                payload.updatedServices = updatedServices;
            }

            if (Object.keys(payload).length > 0) {
                payload.clientId = values.clientId;
                updateTheClient(payload);
            } else {
                // close the window
                onClose();
            }
        } else {
            const serviceList = [];
            selectedServices.forEach(({ id }) => {
                (serviceDesignations[id] || []).forEach(({ id: designationId }) => {
                    serviceList.push({
                        id,
                        designationId: designationId,
                        rate: hourlyRates[id][designationId] || 0,
                    });
                });
            });
            const payload = {
                ...addClientData,
                services: serviceList,
                managerId: clientManager,
            };
            createAClient(payload);
        }
    };

    const collectUserInput = (fieldName, value, error) => {
        // check if the user has done any changes
        if (values?.[fieldName] !== value) {
            setAddClientData((data) => {
                data[fieldName] = value;
                return { ...data };
            });
            setUserInputError((errors) => {
                errors[fieldName] = error;
                return { ...errors };
            });
        }
    };

    const checkAddClientDisabled = () => {
        let disabled = false;
        Object.values(userInputError).forEach((error) => {
            disabled ||= error;
        });
        let serviceDesignationSelected = false;
        Object.values(serviceDesignations).forEach((designations) => {
            serviceDesignationSelected ||=
                Array.isArray(designations) && designations.length > 0;
        });
        disabled ||= !serviceDesignationSelected;
        disabled ||= !clientManager;
        return disabled;
    };

    const handleHourlyRate = (serviceId, designationId, rate) => {
        setHourlyRates((rates) => {
            if (!rates[serviceId]) {
                rates[serviceId] = {};
            }
            rates[serviceId][designationId] = rate;
            return { ...rates };
        });
    };

    const handleClientManager = (selectedManager) => {
        setClientManager(selectedManager?.id);
    };

    const handleDesignationSelect = (serviceId, selectedDesignation) => {
        setServiceDesignations((serviceDesignations) => {
            if ((serviceDesignations[serviceId] || []).length < selectedDesignation.length) {
                serviceDesignations[serviceId] = selectedDesignation;
                return { ...serviceDesignations };
            }
            return serviceDesignations;
        });

        if (!hourlyRates[serviceId]) {
            hourlyRates[serviceId] = {};
        }
        selectedDesignation.forEach(d => {
            if (!hourlyRates[serviceId][d.id]) {
                hourlyRates[serviceId][d.id] = 0;
            }
        });
        setHourlyRates({ ...hourlyRates });
        setShowServiceDesignations(serviceId, false);
    };

    const handleDeleteDesignationService = (serviceId, designationId) => {
        setServiceDesignations((serviceDesignations) => {
            const designationList = serviceDesignations[serviceId];
            const filteredDesignation = designationList.filter(
                (designation) => designation.id !== designationId
            );
            serviceDesignations[serviceId] = [...filteredDesignation];
            return { ...serviceDesignations };
        });

        setHourlyRates((hourlyRates) => {
            if (hourlyRates[serviceId]) {
                delete hourlyRates[serviceId][designationId];
            }
            return { ...hourlyRates };
        });
    };

    const handleDeactivate = () => {
        setGetConfirmation(true)
    };

    const deactivate = () => {
        setGetConfirmation(false);
        setDeactivating(true);
        onDeactivate();
    };

    const closeConfirmation = () => {
        setGetConfirmation(false)
    };

    const onDeactivate = async () => {
        try {
            await updateClient({
                clientId: values.clientId,
                active: !values.active
            });
            showNotificationSuccess(`Client ${values?.active ? `deactivate` : 'activate'}d successfully!`);
        } catch (err) {
            showNotificationError(`Failed to ${values?.active ? `deactivate` : 'activate'} the client! Please try again.`);
        }
        setDeactivating(false);
        onClose();
    };

    const setShowServiceDesignations = (serviceId, value) => {
        const found = selectedServices.find(s => s.id === serviceId);
        if (found) {
            found.showDesignations = value;
            setSelectedServices([...selectedServices]);
        }
    };

    return (
        <div>
            <Dialog
                open
                fullScreen={fullScreen}
                scroll='paper'
                fullWidth
                PaperProps={{
                    sx: {
                        minWidth: 1080,
                    },
                }}
            >
                <DialogTitle>{isUpdate ? 'Update' : 'Create'} Client</DialogTitle>
                <DialogContent dividers>
                    <div className="min-h-[520px]">
                        {dataLoading ? (<div className="pt-52"><MainLoader /></div>) : (
                            <div className="flex gap-4">
                                <div className="max-w-sm min-w-[384px] max-h-[482px] flex flex-col justify-between gap-5">
                                    <div className="text-lg font-semibold text-gray-700">Client Details</div>
                                    <div>
                                        <CustomTextField
                                            label="Client Name"
                                            collectUserInput={collectUserInput}
                                            fieldName="name"
                                            initialValue={values?.name}
                                            initialError={isUpdate ? userInputError.name : false}
                                        />
                                    </div>
                                    <div>
                                        <CustomTextField
                                            label="Client Address"
                                            collectUserInput={collectUserInput}
                                            fieldName="address"
                                            initialValue={values?.address}
                                            initialError={isUpdate ? userInputError.address : false}
                                        />
                                    </div>
                                    <div>
                                        <CustomTextField
                                            label="Contact Person Name"
                                            collectUserInput={collectUserInput}
                                            fieldName="contactPerson"
                                            initialValue={values?.contactPerson}
                                            initialError={isUpdate ? userInputError.contactPerson : false}
                                        />
                                    </div>
                                    <div>
                                        <CustomTextField
                                            label="Contact Person Phone"
                                            collectUserInput={collectUserInput}
                                            fieldName="phone"
                                            initialValue={values?.phone}
                                            initialError={isUpdate ? userInputError.phone : false}
                                        />
                                    </div>
                                    <div>
                                        <CustomTextField
                                            label="Contact Person Email"
                                            collectUserInput={collectUserInput}
                                            fieldName="email"
                                            initialValue={values?.email}
                                            initialError={isUpdate ? userInputError.email : false}
                                        />
                                    </div>
                                    <div>
                                        <UsersAutocomplete
                                            size="medium"
                                            label="Client Managed By"
                                            onChange={handleClientManager}
                                            userId={clientManager}
                                            sx={{ margin: 0 }}
                                        />
                                    </div>
                                </div>
                                <div className="grow border-l border-solid border-gray-300 pl-4">
                                    <div className="flex justify-between h-12">
                                        <div className="text-lg font-semibold text-gray-700">Service Rates</div>
                                        <div>
                                            {!showAddService && <LoadingButton
                                                onClick={() => setShowAddService(true)}
                                                startIcon={<AddIcon />}
                                                fullWidth
                                                size="medium"
                                                variant="contained"
                                                sx={{ textTransform: 'none', m: 0, width: 140 }}
                                            >
                                                Add Service
                                            </LoadingButton>}
                                            {showAddService && <Autocomplete
                                                value={selectedServices}
                                                options={services}
                                                size="small"
                                                sx={{ width: 300, margin: 0 }}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField {...params} label="Add Service" autoFocus fullWidth />
                                                    );
                                                }}
                                                isOptionEqualToValue={(option, values) => {
                                                    return option.id === values.id;
                                                }}
                                                getOptionLabel={(item) => item.name}
                                                onChange={handleServiceSelect}
                                                onBlur={() => setShowAddService(false)}
                                                open
                                                renderTags={() => null}
                                                multiple
                                                disableClearable
                                                filterSelectedOptions
                                                fullWidth
                                            />}
                                        </div>
                                    </div>
                                    <div>
                                        {selectedServices.length === 0 && <div className="text-center mx-auto w-80 pt-28 font-medium text-sm text-gray-400">
                                            <div>Add service to configure hourly rate for individual designations.</div>
                                        </div>}
                                        {selectedServices.map((service) => {
                                            return (
                                                <Paper key={service.id} variant="outlined" sx={{ padding: 2, marginY: 2 }}>
                                                    <div>
                                                        <div className="flex items-start justify-between gap-3 mb-1">
                                                            <div className="text-base font-bold text-gray-600">{service.name}</div>
                                                            <div className="flex items-center gap-4 min-w-[256px] justify-end">
                                                                <div>
                                                                    {!service.showDesignations && <LoadingButton
                                                                        onClick={() => setShowServiceDesignations(service.id, true)}
                                                                        startIcon={<AddIcon />}
                                                                        fullWidth
                                                                        size="medium"
                                                                        variant="outlined"
                                                                        sx={{ textTransform: 'none', m: 0, width: 170 }}
                                                                    >
                                                                        Add Designation
                                                                    </LoadingButton>}
                                                                    {service.showDesignations && <Autocomplete
                                                                        options={designations}
                                                                        value={serviceDesignations[service.id] || []}
                                                                        sx={{ width: 200 }}
                                                                        open
                                                                        size="small"
                                                                        onBlur={() => setShowServiceDesignations(service.id, false)}
                                                                        onChange={(_, selectedDesignations) => handleDesignationSelect(service.id, selectedDesignations)}
                                                                        isOptionEqualToValue={(option, values) => {
                                                                            return option.id === values.id;
                                                                        }}
                                                                        renderInput={(params) => {
                                                                            return (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Designations"
                                                                                    fullWidth
                                                                                    autoFocus
                                                                                />
                                                                            );
                                                                        }}
                                                                        getOptionLabel={(item) => item.name}
                                                                        renderTags={() => null}
                                                                        multiple
                                                                        disableClearable
                                                                        filterSelectedOptions
                                                                        fullWidth
                                                                    />}
                                                                </div>
                                                                <div>
                                                                    <Tooltip title="Remove service" arrow>
                                                                        <IconButton aria-label="remove-service" size="medium" color="error" onClick={() => handleDeleteSelectedService(service.id)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {serviceDesignations[service.id]?.length > 0 ? (
                                                                <div className="bg-gray-50 rounded-md p-4">
                                                                    <div className="text-sm font-medium text-gray-600">Hourly rate configurations for designations in AUD per hour.</div>
                                                                    {serviceDesignations[service.id].map(
                                                                        (serviceDesignation) => {
                                                                            return (
                                                                                <Paper key={`${service.id}-${serviceDesignation.id}`} variant="elevation" sx={{ marginY: 1 }} >
                                                                                    <div className="flex p-2 items-center justify-between">
                                                                                        <div className="font-medium text-gray-600 text-base pl-3 grow">{serviceDesignation.name}</div>
                                                                                        <div className="flex items-center justify-between">
                                                                                            <div className="min-w-[160px] pr-4">
                                                                                                <EditableNumberLabel sx={{ padding: 0, margin: 0 }} onChange={(newValue) => handleHourlyRate(service.id, serviceDesignation.id, newValue)} value={hourlyRates?.[service.id]?.[serviceDesignation.id]} min={0} max={10000} label="Rate in AUD/Hour" suffix=" AUD/Hour" />
                                                                                            </div>
                                                                                            <div><Tooltip title="Remove designation" arrow>
                                                                                                <IconButton aria-label="remove-designation" size="small" onClick={() => handleDeleteDesignationService(service.id, serviceDesignation.id)}>
                                                                                                    <ClearIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Paper>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            ) : (<div className="text-center mx-auto p-8 font-medium text-sm text-gray-400">
                                                                <div>Add designation to configure hourly rate.</div>
                                                            </div>)}
                                                        </div>
                                                    </div>
                                                </Paper>
                                            );
                                        })}

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full p-2 justify-between'>
                        <div>
                            {isUpdate && values && <LoadingButton
                                onClick={handleDeactivate}
                                type="submit"
                                fullWidth
                                loading={deactivating}
                                size="medium"
                                color={values.active ? 'error' : 'success'}
                                disabled={saving || dataLoading}
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 160 }}
                            >
                                {values.active ? `Deactivate` : 'Activate'} Client
                            </LoadingButton>}
                        </div>
                        <div>
                            <LoadingButton
                                onClick={() => onClose(null)}
                                type="submit"
                                fullWidth
                                disabled={saving || deactivating}
                                size="medium"
                                variant="outlined"
                                sx={{ textTransform: 'none', m: 1, width: 100 }}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                onClick={handleAddUpdateClient}
                                type="submit"
                                fullWidth
                                disabled={checkAddClientDisabled() || dataLoading || deactivating}
                                loading={saving}
                                size="medium"
                                variant="contained"
                                sx={{ textTransform: 'none', m: 1, width: 140 }}
                            >
                                {isUpdate ? 'Update' : 'Create'} Client
                            </LoadingButton>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>)
            {getConfirmation && (
                <ConfirmationDialog
                    header={"Are you sure?"}
                    description={`You are going to ${values?.active ? `deactivate` : 'activate'} ${values?.name}`}
                    handleClose={closeConfirmation}
                    handleSubmit={deactivate}
                    cancelText={"Cancel"}
                    color={values?.active ? 'error' : 'success'}
                    confirmationText={values?.active ? `Deactivate` : 'Activate'}
                />)}
        </div>
    );
};

export { ManageClientDialog };



