import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { AvatarExtended } from "../common";

import { NotificationMenu } from "../notifications";
import { useAuth } from "../../context";

const items = ["Profile", "Logout"];

const NavigationBar = ({ onDrawerToggle }) => {
  const { user, signOut } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuSelect = async (item) => {
    if (item === "Logout") {
      await signOut();
    }
    handleCloseUserMenu();
  };

  return (
    <AppBar
      position="fixed"
      elevation={4}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <div className="w-36">
          <img alt="Odin" src="/odin.png" />
        </div>
        <Box sx={{ flexGrow: 1, display: "flex" }}>
        </Box>
        <Box sx={{ flexGrow: 0, display: "flex", mx: 2 }}>
          <NotificationMenu />
        </Box>
        <Box sx={{ flexGrow: 0, display: "flex" }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AvatarExtended alt={`${user.firstName} ${user.lastName}`} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {items.map((item) => (
              <MenuItem key={item} onClick={() => handleMenuSelect(item)}>
                <Typography textAlign="center">{item}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { NavigationBar };
