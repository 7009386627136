import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EastIcon from '@mui/icons-material/East';
import TASK_PRIORITIES from "../../constants/taskPriorities";


const getPriorityColor = (priority) => {
    // DO NOT remove below colors - it's used in compile time
    // eslint-disable-next-line no-unused-vars
    const colors = "bg-status-green text-status-green bg-status-red text-status-red bg-status-yellow text-status-yellow bg-black text-black bg-status-blue text-status-blue bg-status-ash text-status-ash";
    return TASK_PRIORITIES.find(p => p.priority === priority)?.color || 'status-ash';
};

const getBgColor = (priority) => {
    return `bg-${getPriorityColor(priority)}`;
};

const getTextColor = (priority) => {
    return `text-${getPriorityColor(priority)}`;
};

const getPriorityCaption = (priority) => {
    return TASK_PRIORITIES.find(p => p.priority === priority)?.caption || 'Unknown';
};

const getIcon = (priority) => {
    return TASK_PRIORITIES.find(p => p.priority === priority)?.icon || '';
};

const PriorityLevel = ({ priority = '', readOnly = false, onChange = () => { } }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if (readOnly) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (newPriority) => {
        setAnchorEl(null);
        if (newPriority) {
            onChange(newPriority);
        }
    };

    const editableClasses = "hover:bg-opacity-100 hover:text-white cursor-pointer justify-between";
    return (
        <div>
            <div onClick={handleClick} className={`border-solid group text-sm ${getBgColor(priority)} ${getTextColor(priority)} bg-opacity-0 h-8 rounded-lg py-1 font-bold flex ${readOnly ? 'justify-center' : editableClasses} items-center`}>
                <div className="ml-2">{getIcon(priority)}</div>
                <div className="mr-3 ml-1">
                    {getPriorityCaption(priority)}
                </div>
                {!readOnly && <div className='invisible mr-2 group-hover:visible'>
                    <KeyboardArrowDownIcon />
                </div>}
            </div>
            {!readOnly && <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
            >
                {TASK_PRIORITIES.map(p => {
                    if (p.priority === priority) {
                        return '';
                    }
                    return (
                        <MenuItem key={p.priority} onClick={() => handleClose(p.priority)} className="group">
                            <div className='flex items-center justify-between py-1'>
                                <div className='text-base font-medium text-gray-600 group-hover:text-black'>Change to</div>
                                <div className='mx-4'><EastIcon /></div>
                                <div className={`border-solid text-sm ${getTextColor(p.priority)} bg-opacity-20 h-7 rounded-lg py-1 font-semibold flex justify-center items-center group-hover:bg-opacity-100 group-hover:font-bold`}>
                                    <div>{p.icon}</div>
                                    <div>
                                        {getPriorityCaption(p.priority)}
                                    </div>
                                </div>
                            </div>
                        </MenuItem>
                    );
                })}
            </Menu>}
        </div>
    );
};

export { PriorityLevel };
