import * as React from "react";
import {PieChart} from "../common/PieChart";

function generateRandomHexColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * 16);
        color += letters[randomIndex];
    }

    return color;
}


const LeastMissEmployees = ({employees = []}) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'left',
            },
            title: {
                display: true,
                text: 'Employee Density',
            },
        },
    };

    const labels = [];
    const dt = [];
    const colors = []

    employees.forEach(emp => {
        labels.push(emp.accessLevel);
        dt.push(emp.total);
        colors.push(generateRandomHexColor())
    })


    const data = {
        labels,
        datasets: [
            {
                label: 'Employee Count',
                data: dt,
                backgroundColor: colors,
            },
        ],
    };


    return (
        <div className="flex flex-col items-center justify-center block border border-gray-200 rounded-lg shadow">
            <div className="flex flex-col items-center justify-center max-w-md">
            <PieChart options = {options} data = {data}/>
            </div>
        </div>
    );
};

export { LeastMissEmployees };
