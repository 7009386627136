import {
    Chip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useNotification } from "../context";
import { getDesignations } from "../connectors/bff-connector";
import { MainLoader, SearchBox } from "../components/common";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, stableSort } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { ManageDesignationDialog } from "../components/dialogs";
import * as React from "react";

const ViewDesignations = () => {
    const headCells = [
        {
            id: 'name',
            numeric: false,
            label: 'Name',
        },
        {
            id: 'description',
            numeric: false,
            label: 'Description',
        },
        {
            id: 'active',
            numeric: false,
            label: 'Status',
        }
    ];

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const { showNotificationError } = useNotification();

    const onSearch = async (searchText) => {
        try {
            const res = await getDesignations({
                "sortKey": "id",
                "sortOrder": "ASC",
                "limit": 1000000,
                "offset": 0,
                "name": searchText
            });
            const designations = res || [];
            setRows(designations.map(designation => ({
                id: designation.id,
                name: designation.name,
                description: designation.description || '',
                active: designation.active ? "Active" : "Deactivated"
            })));
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setLoading(false);
    };

    const onSearchKeyPress = () => {
        setLoading(true);
    };

    const handleCreateService = () => {
        setDialogOpen(true);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (row) => {
        setSelectedRow(row);
        setEditDialogOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div className="p-4 md:p-6 bg-white">
            <Typography variant="h2">
                Designations
            </Typography>
            <div className='flex items-center justify-between my-6'>
                <div className='w-96'>
                    <SearchBox placeholder="Search Designations" onSearch={onSearch} onSearchKeyPress={onSearchKeyPress} />
                </div>
                <div className='w-48'>
                    <LoadingButton
                        onClick={handleCreateService}
                        type="submit"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        startIcon={<AddIcon />}
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                    >
                        Add Designation
                    </LoadingButton>
                </div>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                rows.length === 0 ? (
                    <div className='p-20 text-center text-gray-500'>
                        <Typography variant='subtitle1'>No results found!</Typography>
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const key = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={() => handleClick(row)}
                                                        role="checkbox"
                                                        aria-checked={false}
                                                        tabIndex={-1}
                                                        key={key}
                                                        selected={false}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={key}
                                                            scope="row"
                                                        >{row.name}</TableCell>
                                                        <TableCell align="left">{row.description}</TableCell>
                                                        <TableCell align="left">
                                                            {row.active === 'Active' ? <Chip label="Active" color="success" variant="outlined" /> : <Chip label="Deactivated" color="error" variant="outlined" />}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )
            )}
            {dialogOpen && <ManageDesignationDialog isCreate handleClose={handleDialogClose} onSearch={onSearch} />}
            {editDialogOpen && <ManageDesignationDialog handleClose={handleEditDialogClose} onSearch={onSearch} selectedRow={selectedRow} />}
        </div>
    );
};

export { ViewDesignations };
