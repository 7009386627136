import React, { useEffect, useState } from 'react';
import { MainLoader } from '../components/common';
import { Auth, Hub } from 'aws-amplify';
import * as bffConnector from "../connectors/bff-connector";

const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

const AUTH_STATUS = {
  PENDING: "PENDING",
  AUTHENTICATED: "AUTHENTICATED",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  PASSWORD_RESET_REQUIRED: "PASSWORD_RESET_REQUIRED"
};

const AuthContext = React.createContext();

const formatUserInfo = (user) => {
  if (!user?.username) {
    return null;
  }
  const attributes = user?.attributes;
  const userInfo = {
    username: user.username,
    sub: attributes?.sub || null,
    email: attributes?.email || null,
    orgId: Number((attributes && attributes['custom:orgId']) || 0),
    roleId: Number((attributes && attributes['custom:roleId']) || 0),
    userId: Number((attributes && attributes['custom:userId']) || 0),
    firstName: attributes?.given_name || '',
    lastName: attributes?.family_name || ''
  };

  return userInfo;
};

const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.PENDING);
  const [user, setUser] = useState(null);

  const checkAuthStatus = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      setUser(formatUserInfo(userInfo));
      setAuthStatus(AUTH_STATUS.AUTHENTICATED);
    } catch (e) {
      setUser(null);
      setAuthStatus(AUTH_STATUS.SESSION_EXPIRED);
    }
  };

  useEffect(() => {
    checkAuthStatus();
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        setUser(null);
        setAuthStatus(AUTH_STATUS.SESSION_EXPIRED);
        window.location.href = '/';
      }
    });
  }, []);

  const signIn = async (username, password) => {
    try {
      const userInfo = await Auth.signIn(username, password);
      if (userInfo.challengeName === NEW_PASSWORD_REQUIRED) {
        setUser(userInfo);
        setAuthStatus(AUTH_STATUS.NEW_PASSWORD_REQUIRED);
      } else {
        await bffConnector.signIn();
        setUser(formatUserInfo(userInfo));
        setAuthStatus(AUTH_STATUS.AUTHENTICATED);
      }
    } catch (e) {
      if (e?.code === "NotAuthorizedException") {
        throw e;
      } else if (e?.code === "PasswordResetRequiredException") {
        setAuthStatus(AUTH_STATUS.PASSWORD_RESET_REQUIRED);
      } else {
        console.error('error signing up..', e);
        throw new Error('Something went wrong! Please try again.');
      }
    }
  };

  const signOut = async () => {
    try {
      await bffConnector.signOut();
    } catch (e) {
      console.error("Error in sign out", e);
    }
    await Auth.signOut();
  };

  const completeNewPassword = async (newPassword) => {
    try {
      await Auth.completeNewPassword(user, newPassword);
      checkAuthStatus();
    } catch (e) {
      if (e?.code === "InvalidPasswordException") {
        throw e;
      }
      console.error("Error in complete new password", e);
      throw new Error('Something went wrong! Please try again.');
    }
  };

  const signUp = async (username, password, email) => {
    try {
      await Auth.signUp({
        username, password, attributes: { email }
      });
    } catch (err) {
      console.error('error signing up..', err);
      throw new Error('Something went wrong! Please try again.');
    }
  };

  const confirmSignUp = async (username, confirmationCode) => {
    try {
      await Auth.confirmSignUp(username, confirmationCode);
    } catch (err) {
      console.error('error signing up..', err);
      throw new Error('Something went wrong! Please try again.');
    }
  };

  const forgotPassword = async (username) => {
    try {
      await Auth.forgotPassword(username);
      setAuthStatus(AUTH_STATUS.PASSWORD_RESET_REQUIRED);
    } catch (err) {
      console.error('error submitting username to reset password...', err);
      throw new Error('Something went wrong! Please try again.');
    }
  };

  const forgotPasswordSubmit = async (username, confirmationCode, password) => {
    try {
      await Auth.forgotPasswordSubmit(username, confirmationCode, password);
      await signIn(username, password);
    } catch (e) {
      if (e?.code === "CodeMismatchException" || e?.code === "LimitExceededException") {
        throw e;
      }
      console.error('error updating password... :', e);
      throw new Error('Something went wrong! Please try again.');
    }
  }

  return (
    <AuthContext.Provider
      value={{
        AUTH_STATUS,
        signIn,
        signOut,
        confirmSignUp,
        signUp,
        completeNewPassword,
        forgotPassword,
        forgotPasswordSubmit,
        authStatus,
        user,
        isAuthenticated: authStatus === AUTH_STATUS.AUTHENTICATED
      }}
    >
      {authStatus === AUTH_STATUS.PENDING ? (
        <div className='h-screen flex items-center'>
          <MainLoader />
        </div>
      ) : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
