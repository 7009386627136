import { useState } from "react";
import { AvatarExtended } from './AvatarExtended';
import { RichTextEditor } from './RichTextEditor';
import { useAuth } from '../../context';
import { ConfirmationDialog } from '../dialogs';
import { getNotificationTime } from "../../utils";


const CommentContainer = ({ userName = '', children }) => {

  return (
    <div className='flex py-2'>
      <div className='pr-2'>
        <AvatarExtended alt={userName} />
      </div>
      <div className='pl-1 w-full'>
        {children}
      </div>
    </div>
  );
};

const CommentView = ({ comment = {}, userId, onEdit = () => { }, onDelete = () => { } }) => {

  const [getConfirmation, setGetConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const allowEdit = userId === comment.userId;

  const handleSubmit = async (content) => {
    const res = await onEdit(content, comment);
    setEditMode(false);
    return res;
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDeleteClick = () => {
    setGetConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    setDeleting(true);
    onDelete(comment);
    setGetConfirmation(false);
  };

  return (
    <div className='my-3'>
      <CommentContainer userName={comment.userName}>
        <div className='pb-1 flex items-baseline'>
          <div className='text-gray-600 font-semibold text-base'>{comment.userName}</div>
          <div className='text-gray-500 font-medium text-sm mx-3'>{getNotificationTime(comment.dateTime)}</div>
        </div>
        <RichTextEditor noBorderInReadMode placeholder="Type your comment..." minHeight={30} value={comment.comment} readOnly={!editMode || !allowEdit} openInEditMode={editMode} onSubmit={handleSubmit} mentionKey="all-users" onCancel={() => setEditMode(false)} showButtons />
        {allowEdit && <div className={`flex text-gray-500 font-semibold text-sm py-2 ${editMode ? 'invisible' : 'visible'}`}>
          <div className='pr-1 cursor-pointer hover:text-gray-400 hover:underline' onClick={handleEditClick}>Edit</div>
          <div>·</div>
          <div className='px-1 cursor-pointer hover:text-gray-400 hover:underline' onClick={handleDeleteClick}>Delete</div>
          {deleting && <div className='px-2 text-gray-400 italic'>Deleting...</div>}
        </div>}
      </CommentContainer>
      {getConfirmation && (
        <ConfirmationDialog
          header="Delete this comment?"
          description="Are you sure you want to delete this comment?"
          handleClose={() => setGetConfirmation(false)}
          handleSubmit={handleDeleteConfirm}
          cancelText={"Cancel"}
          confirmationText={"Delete"}
        />)}
    </div>
  );
};


const CommentSection = ({ comments = [], onCreateNew = () => { }, onEdit = () => { }, onDelete = () => { } }) => {

  const { user } = useAuth();
  const userName = `${user.firstName} ${user.lastName}`;
  const [key, setKey] = useState(0);

  const handleSubmit = async (content) => {
    const comment = {
      comment: content,
      userId: user.userId,
      userName,
      dateTime: new Date().toISOString()
    };
    const res = await onCreateNew(comment);
    if (res) {
      setKey(Date.now());
    }
    return res;
  };

  return (
    <div className='py-3'>
      <div className='py-2'>
        <CommentContainer userName={userName} key={key}>
          <RichTextEditor placeholder="Add a comment..." minHeight={40} readOnly={false} onSubmit={handleSubmit} mentionKey="all-users" showButtons />
        </CommentContainer>
      </div>
      <div className='py-2'>
        {comments.map(comment => (<CommentView key={comment.id} comment={comment} userId={user.userId} onEdit={onEdit} onDelete={onDelete} />))}
      </div>
    </div>
  );
};

export { CommentSection };
