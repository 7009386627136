import { Auth } from 'aws-amplify';

const getSessionId = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession?.idToken?.payload?.event_id;
};

const getAccessToken = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession?.idToken?.jwtToken;
};

export {
  getAccessToken,
  getSessionId
};
