import { useCallback } from "react";
import { useEffect } from "react";
import { subscribeToUserNotification } from "../../connectors/graphql";
import { useAuth, useNotification } from "../../context";

const SUBSCRIPTION_DATA = { subscribed: false };

const NotificationSubscriber = () => {
  const { user } = useAuth();
  const { showServerNotification } = useNotification();

  const handleReceivedNotification = useCallback((notifications) => {
    if (Array.isArray(notifications) && notifications.length > 0) {
      notifications.forEach((notification) => {
        notification.id = Number(notification.id);
        showServerNotification(notification);
      });
    } else {
      console.error("Empty notification received");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!SUBSCRIPTION_DATA.subscribed) {
      subscribeToUserNotification(user.userId, handleReceivedNotification);
      SUBSCRIPTION_DATA.subscribed = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return "";
};

export { NotificationSubscriber };
