import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useRef, useState } from "react";
import { Slider, Stack, TextField } from '@mui/material';
import { getMinutesInHoursAndMins } from '../../utils';

import Popover from '@mui/material/Popover';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const hourMarks = [
    {
        value: 0,
        label: '0hr',
    },
    {
        value: 8,
        label: '8hrs',
    },
    {
        value: 16,
        label: '16hrs',
    },
    {
        value: 24,
        label: '24hrs',
    },
    {
        value: 32,
        label: '32hrs',
    },
    {
        value: 40,
        label: '40hrs',
    },
];

const minsMarks = [
    {
        value: 0,
        label: '0min',
    },
    {
        value: 15,
        label: '15mins',
    },
    {
        value: 30,
        label: '30mins',
    },
    {
        value: 45,
        label: '45mins',
    },
    {
        value: 55,
        label: '55mins',
    }
];

const MinutesPickerInput = ({ onChange = () => { }, value = 60, onBlur = () => { }, openInitial = false, label = "Time", ...props }) => {

    const [displayValue, setDisplayValue] = useState("");
    const [minutes, setMinutes] = useState(value);
    const [mins, setMins] = useState(getMinutesInHoursAndMins(minutes).mins);
    const [hours, setHours] = useState(getMinutesInHoursAndMins(minutes).hours);

    const [anchorEl, setAnchorEl] = useState(null);
    const refTextField = useRef(null);

    const openPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        onBlur();
    };

    useEffect(() => {
        if (openInitial && refTextField?.current) {
            setAnchorEl(refTextField.current);
        }
    }, [openInitial, refTextField]);

    const open = Boolean(anchorEl);

    useEffect(() => {
        const data = getMinutesInHoursAndMins(value);
        setDisplayValue(data.readableValue);
        setMins(data.mins);
        setHours(data.hours);
    }, [value]);

    const handleFocus = (event) => {
        openPopover(event);
    };

    const hoursLabelFormat = (value) => {
        return `${value} hr${value > 1 ? 's' : ''}`;
    };

    const minsLabelFormat = (value) => {
        return `${value} min${value > 1 ? 's' : ''}`;
    };

    const handleHourChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setHours(newValue);
        }
    };

    const handleMinChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setMins(newValue);
        }
    };

    const handleSet = () => {
        const totalMinutes = (hours * 60) + mins;
        setMinutes(totalMinutes);
        const readableValue = getMinutesInHoursAndMins(totalMinutes).readableValue;
        setDisplayValue(readableValue);
        onChange({
            mins,
            hours,
            readableValue,
            totalMinutes
        });
        handleClosePopover();
    };

    return (
        <div>
            <TextField
                ref={refTextField}
                onClick={handleFocus}
                {...props}
                InputProps={{ inputProps: {} }}
                type="text"
                value={displayValue}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='my-4 mx-7 w-96'>
                    <div className='font-bold text-lg pb-4'>
                        <span className='text-gray-500'>{label} :</span> <span className='text-gray-800'>{getMinutesInHoursAndMins((hours * 60) + mins).readableValue}</span>
                    </div>
                    <div className='px-1'>
                        <div className='font-bold text-sm text-gray-500 py-2'>Set Hours</div>
                        <Slider
                            value={hours}
                            min={0}
                            step={1}
                            max={40}
                            marks={hourMarks}
                            getAriaValueText={hoursLabelFormat}
                            valueLabelFormat={hoursLabelFormat}
                            onChange={handleHourChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="hours-linear-slider"
                        />
                    </div>

                    <div className='my-6 px-1'>
                        <div className='font-bold text-sm text-gray-500 py-2'>Set Minutes</div>
                        <Slider
                            value={mins}
                            min={0}
                            step={5}
                            max={55}
                            marks={minsMarks}
                            getAriaValueText={minsLabelFormat}
                            valueLabelFormat={minsLabelFormat}
                            onChange={handleMinChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="mins-linear-slider"
                        />
                    </div>
                    <div>
                        <Stack direction="row-reverse" spacing={1}>
                            <Tooltip title="Set" arrow>
                                <IconButton aria-label="set" color='primary' size="large" onClick={handleSet}>
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" arrow>
                                <IconButton aria-label="clear" size="large" onClick={handleClosePopover}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export { MinutesPickerInput };
