import { useEffect, useState } from "react";

const getTimeNowString = (timeZone) => {
    return new Date().toLocaleString("en-US", { timeZone }).split(",")[1];
};

const TimeDisplay = ({ timeZone = 'Australia/Melbourne', prefix = 'Melbourne Time ' }) => {

    const [time, setTime] = useState(getTimeNowString(timeZone));
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getTimeNowString(timeZone));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [timeZone]);

    return `${prefix}${time}`;
};

export { TimeDisplay };


