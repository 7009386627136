import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback } from 'react';
import { getTaskUsers, getUsers } from '../../connectors/bff-connector';
import { AvatarExtended } from './AvatarExtended';
import { LinearProgress, Typography } from '@mui/material';


const UsersAutocomplete = ({ label = 'Select a User', onChange = () => { }, autoFocus = false, inputRef = null, onBlur = () => { }, sx = {}, size = 'small', userId = 0, error = false, required = false, type = "all-users", projectId = 0, serviceId = 0, filter = { by: 'name', order: 'asc' }, onlyActive = false }) => {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(userId > 0);

    const loadUsers = useCallback(async () => {
        setLoading(true);
        setUsers([{ disabled: true }]);
        try {
            let users = [];
            if (type === "all-users") {
                let res = {};
                res = await getUsers({
                    "filter": filter,
                    "size": 1000000,
                    "page": 1,
                    "search": "",
                }, 'all-users');

                users = (res.users || []);

                if (onlyActive) {
                    users = users.filter(user => user.isActive === true);
                }

            } else if (type === "task-users" && projectId > 0 && serviceId > 0) {
                const res = await getTaskUsers({ projectId, serviceId });
                users = (res || []);
            }
            setUsers(users);

            setTimeout(() => {
                const selected = users.find(u => u.id === Number(userId));
                if (selected) {
                    setSelectedUser(selected);
                } else {
                    setSelectedUser(null);
                }
            });
            setLoading(false);
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, serviceId]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const onChangeHandler = (event, newValue) => {
        setSelectedUser(newValue);
        onChange(newValue);
    };

    return (
        <Autocomplete
            size={size}
            fullWidth
            options={users}
            autoHighlight
            openOnFocus
            selectOnFocus
            value={selectedUser}
            onChange={onChangeHandler}
            getOptionDisabled={(option) => option.disabled}
            onBlur={onBlur}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.name || ''}
            sx={{ mt: 2, mb: 1, ...sx }}
            renderOption={({ key, ...props }, option) => {

                if (option.disabled) {
                    return (
                        <div key='loading'>
                            <div className='p-2 text-sm font-semibold text-gray-500 text-center'>Loading...</div>
                            <LinearProgress />
                        </div>
                    );
                }
                return (
                    <Box key={option.id} component="li" {...props}>
                        <div className='flex flex-col'>
                            <div className='flex items-center'>
                                <AvatarExtended sx={{ width: 32, height: 32, marginY: '4px', fontSize: '13px', fontWeight: 600 }} alt={option.name} />
                                <Typography ml={1} variant='subtitle2'>{option.name}</Typography>
                            </div>
                            {type === "task-users" && <div className='text-sm flex gap-2 font-medium text-gray-600 pl-10 p-1'>
                                <div className=''>
                                    <div>Allocated : </div>
                                    <div>Completed : </div>
                                </div>
                                <div className='font-semibold text-right'>
                                    <div>{option.timeAllocated} hrs</div>
                                    <div>{option.timeCompleted} hrs</div>
                                </div>
                            </div>}
                        </div>
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputRef={inputRef}
                    required={required}
                    label={loading ? <em>Loading...</em> : label}
                    error={error}
                    variant='outlined'
                    autoFocus={autoFocus}
                    inputProps={{
                        style: { fontSize: 14 },
                        ...params.inputProps,
                        autoComplete: 'some-7693289', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};

export { UsersAutocomplete };
