import { useState } from "react";

const EditableLabel = ({ value = '', className = '', readOnly = false, required = false, onChange = () => { } }) => {

    const [editMode, setEditMode] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    const [hasError, setHasError] = useState(false);

    const handleChange = (e) => {
        setEditMode(false);

        const text = (e?.target?.innerText || "").trim();
        if (required && text === "") {
            setHasError(true);
        } else {
            setCurrentValue(text);
            if (text !== value) {
                onChange(text);
            }
        }
    };

    const handleClick = () => {
        if (!readOnly) {
            setEditMode(true);
            setHasError(false);
        }
    };

    return (
        <div className='relative'>
            <div className={(!readOnly && !editMode ? 'hover:bg-black hover:bg-opacity-5' : '') + ' rounded-md relative -left-2 ' + (hasError ? 'border border-warn-main' : '')}>
                <div className={`${className} p-2`} contentEditable={!readOnly} suppressContentEditableWarning onChange={handleChange} onBlur={handleChange} onClick={handleClick}>{currentValue}</div>
            </div>
        </div>
    );
};

export { EditableLabel };