import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth, useNotification } from "../../context";
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
    createTask, getProjects, getTaskServices
} from '../../connectors/bff-connector';
import MenuItem from "@mui/material/MenuItem";
import { AttachmentUploader, MinutesPickerInput, RichTextEditor, UsersAutocomplete } from "../common";

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { getMentionsFromHtml } from '../../utils';
import TASK_PRIORITIES from '../../constants/taskPriorities';
import { useRef } from 'react';

const CreateTaskDialog = ({ handleClose = () => { } }) => {

    const { user } = useAuth();
    const [name, setName] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isNameDirty, setIsNameDirty] = useState(false);

    const [description, setDescription] = useState('');
    const [mentions, setMentions] = useState([]);

    const [timeAllocated, setTimeAllocated] = useState(480);  // this is actually in minutes

    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [isSelectedProjectIdDirty, setIsSelectedProjectIdDirty] = useState(false);

    const [selectedTaskPriorityId, setSelectedTaskPriorityId] = useState("md");

    const [selectedAssigneeUserId, setSelectedAssigneeUserId] = useState('');
    const [isSelectedAssigneeUserIdDirty, setSelectedAssigneeUserIdDirty] = useState(false);

    const [selectedOwnerUserId, setSelectedOwnerUserId] = useState(user.userId);

    const [selectedClientServiceId, setSelectedClientServiceId] = useState('');
    const [selectedServiceId, setSelectedServiceId] = useState(0);

    const [isSelectedClientServiceIdDirty, setIsSelectedClientServiceIdDirty] = useState(false);

    const [isEndDateValid, setIsEndDateValid] = useState(true);

    const [saving, setSaving] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [startDate] = React.useState(dayjs(new Date().toString()));
    const [endDate, setEndDate] = React.useState(dayjs(new Date().toString()));

    const [projects, setProjects] = useState([]);
    const [clientService, setClientService] = useState([]);

    const [attachments, setAttachments] = useState([]);
    const [uploading, setUploading] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { showNotificationError, showNotificationSuccess } = useNotification();

    const refScroll = useRef(null);


    const handleEndDateChange = (newValue) => {
        if (newValue?.isValid()) {
            setIsEndDateValid(true);
        } else {
            setIsEndDateValid(false);
        }
        setEndDate(newValue);
    };

    const handleTaskPriorityChange = event => {
        setSelectedTaskPriorityId(event.target.value);
    };

    const loadProjects = useCallback(async () => {
        try {
            const res = await getProjects({
                "limit": 10000,
                "offset": 0,
                "active": true,
                "sortOrder": "ASC",
                "sortKey": "name"
            });
            const projects = (res || []);
            setProjects(projects);
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
    }, []);

    useEffect(() => {
        loadProjects();
    }, [loadProjects]);

    const loadTaskServices = async (projectId) => {
        try {
            const res = await getTaskServices({
                projectId
            });

            const services = (res || []).map(service => ({
                id: service.client_service_id,
                serviceId: service.service_id,
                name: service.service_name
            }));

            setClientService(services);
        } catch (err) {
            //TODO  handle error
            console.error(err);
        }
    };

    const handleSubmit = async () => {

        if (!isNameValid) {
            setErrorMessage("Please type the name.");
            setIsNameDirty(true);
            return;
        }

        if (!selectedProjectId) {
            setErrorMessage("Please select a project.");
            setIsSelectedProjectIdDirty(true);
            return;
        }

        if (!selectedOwnerUserId) {
            setErrorMessage("Please select the owner.");
            return;
        }

        if (!selectedClientServiceId) {
            setErrorMessage("Please select a service");
            setIsSelectedClientServiceIdDirty(true);
            return;
        }

        if (!isEndDateValid) {
            setErrorMessage("Please select a valid deadline.");
            return;
        }

        if (!selectedAssigneeUserId) {
            setErrorMessage("Please select an assignee.");
            setSelectedAssigneeUserIdDirty(true);
            return;
        }

        const newTask = {
            name: name.trim(),
            projectId: selectedProjectId,
            owner: selectedOwnerUserId,
            clientServiceId: selectedClientServiceId,
            endDt: endDate.toDate().toISOString(),
            assignedTo: selectedAssigneeUserId,
            hoursAllocated: timeAllocated,
            priorityCode: selectedTaskPriorityId,
            description: description,
            mentions,
            attachments
        };

        setErrorMessage('');
        setSaving(true);
        try {
            const res = await createTask(newTask);
            showNotificationSuccess("New Task created successfully!");
            handleClose({}, res);
        } catch (err) {
            showNotificationError("Failed to create the task. Please try again.");
            setSaving(false);
        }
    };

    const handleNameChange = event => {
        const val = event.target.value;

        if (val?.trim()) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }
        setName(val);
    };

    const handleDescriptionChange = (description) => {
        const mentions = getMentionsFromHtml(description);
        setMentions(mentions);
        setDescription(description);
    };

    const handleTimeAllocatedChange = (allocatedTime) => {
        setTimeAllocated(allocatedTime.totalMinutes);
    };

    const handleAssigneeChange = (user) => {
        setSelectedAssigneeUserId(user?.id || 0);
        setSelectedAssigneeUserIdDirty(true);
    };

    const handleOwnerChange = (user) => {
        setSelectedOwnerUserId(user?.id || 0);
    };

    const handleProjectChange = event => {
        setIsSelectedProjectIdDirty(true);
        setSelectedProjectId(event.target.value);
        if (event.target.value) {
            loadTaskServices(event.target.value);
        }
    };

    const handleUploadStart = () => {
        setUploading(true);
        refScroll?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleUploadComplete = async (newAttachments) => {
        setAttachments(newAttachments.map(a => a.id));
        setUploading(false);
    };

    const handleClientServiceChange = event => {
        setIsSelectedClientServiceIdDirty(true);
        setSelectedClientServiceId(event.target.value);
        if (event.target.value) {
            const id = Number(event.target.value);
            const selectedClientService = clientService.find(cs => cs.id === id);
            if (selectedClientService) {
                setSelectedServiceId(selectedClientService.serviceId);
                return;
            }
        }
        setSelectedServiceId(0);
    };

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            scroll='paper'
            fullWidth
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle>Create Task</DialogTitle>
            <DialogContent dividers>
                <div className='sm:px-8'>
                    <div className='relative text-center'>
                        <Typography variant='subtitle1'
                            className='absolute text-warn-main w-full -top-3'>{errorMessage}</Typography>
                    </div>
                    <TextField
                        onChange={(e) => handleNameChange(e)}
                        onBlur={() => setIsNameDirty(true)}
                        error={isNameDirty && !isNameValid}
                        margin="normal"
                        required
                        fullWidth
                        size='small'
                        label="Task Name"
                        name="task_name"
                        value={name}
                    />
                    <div className='flex'>
                        <div className='basis-1/2 max-w-[calc(50%-12px)]'>
                            <FormControl fullWidth margin='normal' size='small' required error={isSelectedProjectIdDirty && !selectedProjectId}>
                                <InputLabel>Project</InputLabel>
                                <Select
                                    labelId="select-project"
                                    value={selectedProjectId}
                                    label="Project"
                                    onChange={handleProjectChange}
                                >
                                    {projects.length === 0 && (
                                        <MenuItem value="">
                                            <em>Loading...</em>
                                        </MenuItem>
                                    )}
                                    {projects.map(d => <MenuItem key={d.projectId} value={d.projectId}>
                                        <div className='max-w-lg text-ellipsis overflow-hidden whitespace-nowrap'>
                                            <span className="text-base font-semibold">{d.code}</span>
                                            <span className="text-sm font-normal"> - {d.name}</span>
                                        </div>
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-6'></div>
                        <div className='basis-1/2'>
                            <UsersAutocomplete label="Owner" onChange={handleOwnerChange}
                                userId={selectedOwnerUserId} required error={!selectedOwnerUserId} />
                        </div>
                    </div>
                    <div className='flex gap-6'>
                        <div className='basis-1/2'>
                            <FormControl fullWidth margin='normal' size='small' required error={isSelectedClientServiceIdDirty && !selectedClientServiceId}>
                                <InputLabel >Service</InputLabel>
                                <Select
                                    labelId="select-service"
                                    value={selectedClientServiceId}
                                    label="Client"
                                    disabled={!selectedProjectId}
                                    onChange={handleClientServiceChange}
                                >
                                    {clientService.length === 0 && (
                                        <MenuItem value="">
                                            <em>Loading...</em>
                                        </MenuItem>
                                    )}
                                    {clientService.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='basis-1/2 mt-4'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    disablePast={true}
                                    minDate={startDate}
                                    label="Deadline"
                                    inputFormat="DD/MM/YYYY"
                                    InputProps={{ size: 'small' }}
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} error={!isEndDateValid} required size="small" />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='flex gap-6'>
                        <UsersAutocomplete label="Assignee" type="task-users" projectId={selectedProjectId} serviceId={selectedServiceId} onChange={handleAssigneeChange}
                            userId={selectedAssigneeUserId} required error={isSelectedAssigneeUserIdDirty && !selectedAssigneeUserId} />
                    </div>
                    <div className='flex gap-6'>
                        <div className='basis-1/2'>
                            <MinutesPickerInput
                                onChange={handleTimeAllocatedChange}
                                size='small'
                                margin="normal"
                                required
                                fullWidth
                                label="Time Allocated"
                                value={timeAllocated}
                            />
                        </div>
                        <div className='basis-1/2'>
                            <FormControl fullWidth margin='normal' size='small'>
                                <InputLabel>Priority</InputLabel>
                                <Select
                                    labelId="select-priority"
                                    value={selectedTaskPriorityId}
                                    label="Priority"
                                    onChange={handleTaskPriorityChange}
                                >
                                    {TASK_PRIORITIES.map(d => <MenuItem key={d.priority} value={d.priority}>{d.caption}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='mt-6 mb-2'>
                        <RichTextEditor placeholder="Type your project description here..." minHeight={100} openInEditMode showButtons={false} onChange={handleDescriptionChange} mentionKey="all-users" />
                    </div>
                    <div className='mt-6 mb-14'>
                        <AttachmentUploader onUploadStart={handleUploadStart} onUploadComplete={handleUploadComplete} source="tasks" categoryCode="tsk" />
                    </div>
                </div>
                <div ref={refScroll}></div>
            </DialogContent>
            <DialogActions>
                <div className='flex w-full p-2 justify-end'>
                    <div>
                        <LoadingButton
                            onClick={handleClose}
                            type="submit"
                            fullWidth
                            size="medium"
                            variant="outlined"
                            sx={{ textTransform: 'none', m: 1, width: 100 }}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            onClick={handleSubmit}
                            type="submit"
                            fullWidth
                            loading={saving}
                            disabled={uploading}
                            size="medium"
                            variant="contained"
                            sx={{ textTransform: 'none', m: 1, width: 120 }}
                        >
                            Create
                        </LoadingButton>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}
    ;

export { CreateTaskDialog };
