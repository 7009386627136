import { ServerNotificationItem } from './ServerNotificationItem';
import { Paper } from '@mui/material';
import { useNotification } from '../../context';

const ServerNotifications = ({ notification = {}, onClose = () => { }, onClick = () => { } }) => {

    const { notifyNotificationPanel } = useNotification();

    const handleClick = () => {
        onClick(notification);
        notifyNotificationPanel(notification, "MARK_AS_READ");
        onClose();
    };

    return (
        <div className='max-w-[480px] w-[480px]'>
            <Paper elevation={3}>
                <div className='w-full'>
                    <ServerNotificationItem notification={notification} onClose={onClose} onClick={handleClick} showCloseButton />
                </div>
            </Paper>
        </div>
    );
};

export { ServerNotifications };
