import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { AvatarExtended, MainLoader, SearchBox } from '../components/common';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { getUsers } from '../connectors/bff-connector';
import { useState } from 'react';
import { useNotification } from '../context';
import { ManageUserDialog } from '../components/dialogs/ManageUserDialog';
import { EnhancedTableHead, getComparator, stableSort } from "../utils";
import { Chip } from '@mui/material';



const ViewUsers = () => {

    const headCells = [
        {
            id: 'name',
            numeric: false,
            label: 'User Name',
        },
        {
            id: 'team',
            numeric: false,
            label: 'Team',
        },
        {
            id: 'designation',
            numeric: false,
            label: 'Designation',
        },
        {
            id: 'managerName',
            numeric: false,
            label: 'Report To',
        },
        {
            id: 'isActive',
            numeric: false,
            label: 'Status',
        }
    ];

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);

    const { showNotificationError } = useNotification();

    const onSearch = async (searchText) => {
        try {
            const res = await getUsers({
                "filter": {
                    "by": "name",
                    "order": "asc"
                },
                "size": 1000000,
                "page": 1,
                "search": searchText
            });
            const users = res.users || [];
            setRows(users);
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setLoading(false);
    };

    const onSearchKeyPress = () => {
        setLoading(true);
    };

    const handleCreateUser = () => {
        setIsCreate(true);
        setDialogOpen(true);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (row) => {
        setSelectedRow(row);
        setIsCreate(false);
        setDialogOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialogClose = (event, user) => {
        if (user) {
            onSearch("");
        }
        setDialogOpen(false);
        getUsers({}, 'all-users', true);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div className="p-4 md:p-6 bg-white">
            <Typography variant="h2">
                Users
            </Typography>
            <div className='flex items-center justify-between my-6'>
                <div className='w-96'>
                    <SearchBox placeholder="Search Users" onSearch={onSearch} onSearchKeyPress={onSearchKeyPress} />
                </div>
                <div className='w-48'>
                    <LoadingButton
                        onClick={handleCreateUser}
                        type="submit"
                        fullWidth
                        size="large"
                        loadingPosition="start"
                        startIcon={<AddIcon />}
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                    >
                        Create User
                    </LoadingButton>
                </div>
            </div>
            {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                rows.length === 0 ? (
                    <div className='p-20 text-center text-gray-500'>
                        <Typography variant='subtitle1'>No results found!</Typography>
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const key = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={() => handleClick(row)}
                                                        role="checkbox"
                                                        aria-checked={false}
                                                        tabIndex={-1}
                                                        key={key}
                                                        selected={false}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={key}
                                                            scope="row"
                                                        >
                                                            <div className={'flex items-center'}>
                                                                <AvatarExtended alt={row.name} />
                                                                <div className={'ml-2'}>
                                                                    <Typography variant='subtitle1'>{row.name}</Typography>
                                                                    <Typography className='text-gray-500' variant='body2'>{row.email}</Typography>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">{row.team?.name}</TableCell>
                                                        <TableCell align="left">{row.designation}</TableCell>
                                                        <TableCell align="left">{row.managerName}</TableCell>

                                                        <TableCell align="left">
                                                            {row.isActive ? <Chip label="Active" color="success" variant="outlined" /> : <Chip label="Deactivated" color="error" variant="outlined" />}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )
            )}
            {dialogOpen && <ManageUserDialog isCreate={isCreate} handleClose={handleDialogClose} selectedUser={selectedRow} />}
        </div>
    );
};

export { ViewUsers };
