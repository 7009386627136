const typography = {
  lineHeight: 1.2,
  fontFamily: "'Plus Jakarta Sans', sans-serif",
  caption: {
    fontSize: "20px", // mobile font size
    fontWeight: 300,
    lineHeight: 1.2,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  h1: {
    fontSize: "32px", // mobile font size
    fontWeight: 700,
    lineHeight: 1.2,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  h2: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: 1.2,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  h3: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: 1.4,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: 1.4,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  body1: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.4,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  body2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.4,
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
};

export default typography;
