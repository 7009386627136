const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const getNotificationTime = (timestamp) => {
  if (!timestamp) return "";
  const notificatioTime = new Date(timestamp).getTime();
  const now = Date.now();

  const timeDiff = now - notificatioTime;

  const diffInMinutes = parseInt(timeDiff / 1000 / 60, 10);
  const diffInHours = parseInt(timeDiff / 1000 / 60 / 60);
  const diffInDays = parseInt(timeDiff / 1000 / 60 / 60 / 24);

  if (diffInDays > 7) {
    return new Intl.DateTimeFormat("en-AU", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(notificatioTime);
  }

  if (diffInDays <= 7 && diffInDays > 0) {
    return `${diffInDays} days ago`;
  }

  if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
  }
  if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
  }

  return "few seconds ago";
};

const getMinutesInHoursAndMins = (minutes = 0) => {
  let hours = 0;
  let mins = 0;

  if (minutes > 0) {
    hours = Math.floor(minutes / 60);
    mins = minutes - (hours * 60);
  }

  const hourString = `${hours > 0 ? hours + ' hr' : ''}${hours > 1 ? 's' : ''}${hours > 0 ? ' ' : ''}`;
  const minString = `${mins} min${mins > 1 ? 's' : ''}`;

  return {
    readableValue: (hours > 0 && mins === 0) ? hourString : hourString + minString,
    mins,
    hours
  };
};

const capitalizeFirstLetter = (text) => {
  text = text.toLowerCase();
  const arr = text.split(" ");
  const caps = [];
  for (let i in arr) {
    caps.push(arr[i].charAt(0).toUpperCase() + arr[i].slice(1));
  }
  return caps.join(" ");
};

const toReadableFileSize = (value) => {
  if (isNaN(value)) {
    return "";
  }
  if (value < 1024) {
    return value + " bytes";
  } else if (value < 1048576) {
    return (value / 1024).toFixed(2) + " kB";
  } else if (value < 1073741824) {
    return (value / 1048576).toFixed(2) + " MB";
  } else {
    return (value / 1073741824).toFixed(2) + " GB";
  }
};

const getDateStringDDMMYYYY = (dateTime = '', delimiter = "/") => {
  if (!dateTime) {
    return "";
  }
  let date = new Date(dateTime);
  return appendLeadingZeroes(date.getDate()) + delimiter + appendLeadingZeroes(date.getMonth() + 1) + delimiter + date.getFullYear();
};

const appendLeadingZeroes = (n) => {
  if (n <= 9) {
    return "0" + n;
  }
  return n + "";
}

const getMentionsFromHtml = (html) => {
  const dom = document.createElement('div');
  dom.innerHTML = html;
  const mentionClasses = dom.getElementsByClassName('mention') || [];
  const mentions = [];

  for (let i = 0; i < mentionClasses.length; i++) {
    const mentionClass = mentionClasses[i];
    const userId = mentionClass?.attributes['data-id']?.value;
    if (userId) {
      mentions.push(Number(userId));
    }
  }
  return mentions;
};

const getExtensionFromFileName = (fileName = '') => {
  const parts = fileName.split(".");
  if (parts.length >= 2) {
    return parts[parts.length - 1];
  }
  return "---";
};


const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const getInCurrencyFormat = (currency) => {
  return currency !== undefined ? USDollar.format(currency) : ""
}

export {
  openInNewTab,
  getNotificationTime,
  capitalizeFirstLetter,
  toReadableFileSize,
  getDateStringDDMMYYYY,
  getMentionsFromHtml,
  getExtensionFromFileName,
  getMinutesInHoursAndMins,
  getInCurrencyFormat
};
