const PROJECT_STATUS = [
    {
        status: 'PEN',
        caption: 'Pending',
        color: 'status-yellow'
    }, {
        status: 'ONG',
        caption: 'Ongoing',
        color: 'status-blue'
    },
    {
        status: 'BLK',
        caption: 'Blocked',
        color: 'status-red'
    },
    {
        status: 'DON',
        caption: 'Completed',
        color: 'status-green'
    },
    {
        status: 'CNS',
        caption: 'Cancelled',
        color: 'black'
    }
];

export default PROJECT_STATUS;