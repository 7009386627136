const MainLoader = ({ width = 64 }) => {
    return (
        <div className="w-full">
            <div style={{ width }} className="mx-auto">
                <img alt='Loading...' src='/loading-odin.gif' />
            </div>
        </div>
    );
};

export { MainLoader };
