// import { datadogLogs } from '@datadog/browser-logs';

const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'info';

const LOG_TYPES = ['info', 'warn', 'error'];
// Allowed log level index determined by the LOG_LEVEL value
const LOG_LEVEL_INDEX = LOG_TYPES.indexOf(LOG_LEVEL);

const browserLogger = () => {
  const logger = {};

  LOG_TYPES.forEach((logType, index) => {
    /**
     * Register the log type
     * @param {string} message
     * @param {Object} context
     */
    logger[logType] = (message, context) => {
      // Check for the log level before registering
      if (index >= LOG_LEVEL_INDEX) {
        try {
          // return datadogLogs.logger.log(message, context, logType);
          console.error(message, context);
        } catch (error) {
          console.error('Error occurred while logging');
        }
      }
    };
  });

  return logger;
};

const LOGGER = browserLogger();
export { LOGGER };
