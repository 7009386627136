import {
    Box,
    Typography,
    LinearProgress,
} from "@mui/material";
import { useParams } from 'react-router';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import {  AvatarExtended, MainLoader} from "../components/common";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, getComparator, stableSort, getDateStringDDMMYYYY } from "../utils";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import 'react-quill/dist/quill.snow.css';
import { useNotification } from "../context";
import {getUserById, getUserTasks} from "../connectors/bff-connector";

const ViewResourceIndividual = () => {
    const headCells = [
        {
            id: 'project',
            numeric: false,
            label: 'Project',
        },
        {
            id: 'task',
            numeric: false,
            label: 'Task',
        },
        {
            id: 'totalHrs',
            numeric: true,
            label: 'Total Hrs',
        },
        {
            id: 'completedHrs',
            numeric: true,
            label: 'Completed Hrs',
        },
        {
            id: 'remainingHrs',
            numeric: true,
            label: 'Remaining Hrs',
        },
        {
            id: 'deadLine',
            numeric: true,
            label: 'Deadlines',
        },
        {
            id: 'completion',
            numeric: true,
            label: 'Completion',
        }
    ];

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('username');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [user, setUser] = useState({name: '', designation: ''});
    //const [selectedUserId, setSelectedUserId] = useState('');

    const { showNotificationError } = useNotification();
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const { userId } = useParams();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onSearch = useCallback(async (userId) => {
        try {
            //setSelectedUserId(userId);
            const userRes = await getUserById({
                "sortKey": "id",
                "sortOrder": "ASC",
                "limit": 1000000,
                "offset": 0,
                "userId": userId
            });
            setUser({name: userRes.firstName + ' ' + userRes.lastName , designation: userRes.designation})
            const res = await getUserTasks({
                "sortKey": "id",
                "sortOrder": "ASC",
                "limit": 1000000,
                "offset": 0,
                "resourceUserId": userId
            });
            const tasks = res || [];
            setRows(tasks.map(task => ({
                id: task.id,
                project: task.projectName,
                task: task.taskName || '',
                totalHours: task.totalHours || '',
                completedHours: task.completedHourbyTask || '',
                remainingHours: task.remainingHours || '',
                deadline: task.taskDeadline || '',
                completion: task.completion || '',
            })));
        } catch (err) {
            showNotificationError("Something went wrong! Please try again.");
        }
        setLoading(false);
    },[showNotificationError]);

    useEffect(() => {    
        onSearch(userId);
    }, [userId, onSearch]);

    return (
    <div>
        <div className="p-2 space-x-4 bg-white">
            <Box className="flex items-center">
                <IconButton aria-label="back-button" onClick={goBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 1 }}>
                    <Link to="/projects">
                        <Typography variant="body1" className="hover:underline">Individual Resource Details</Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <div className={'flex items-center space-x-4'}>
                <div className="">
                    <AvatarExtended alt={user.name} sx={{ width: 72, height: 72 }}/>
                </div>
                <div>
                    <div className="text-base font-semibold text-black-900">{user.name}</div>
                    <div className="text-sm text-gray-500 text-center">{user.designation}</div>
                </div>
                <div>
                <div  className={`border-solid text-sm  text-status-green bg-status-green bg-opacity-20 w-20 h-6 rounded-lg py-1 font-bold flex justify-center  items-center`}>
                    <div className="ml-0 ">
                        {user.name && 'active'}
                    </div>                
                </div>
                </div>
            </div>               
        </div>
        <div>
        {loading ? (
                <div className='h-96 flex items-center'>
                    <MainLoader />
                </div>
            ) : (
                rows.length === 0 ? (
                    <div className='p-20 text-center text-gray-500'>
                        <Typography variant='subtitle1'>No results found!</Typography>
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Paper variant="outlined" sx={{ width: '100%', my: 2 }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const key = `table-row-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={false}
                                                        tabIndex={-1}
                                                        key={key}
                                                        selected={false}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={key}
                                                            scope="row"
                                                        >{row.project}</TableCell>
                                                        <TableCell align="right">{row.task}</TableCell>
                                                        <TableCell align="right">{row.totalHours}</TableCell>
                                                        <TableCell align="right">{row.completedHours}</TableCell>
                                                        <TableCell align="right">{row.remainingHours}</TableCell>
                                                        <TableCell align="right">{getDateStringDDMMYYYY(row.deadline)}</TableCell>
                                                        <TableCell align="right">
                                                            {row.completion? row.completion+'%': ''} 
                                                            <LinearProgress variant={"determinate"} value={Number(row.completion)} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )
            )}
        </div>

    </div>)
};

export { ViewResourceIndividual };
